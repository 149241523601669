import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import styled from '@emotion/styled';
import React, { useEffect, useState, useMemo } from 'react';
import { MemoryRouter, Redirect, Route, Switch, useHistory, useLocation } from 'react-router';

import Sender from '../management/Sender';
import { LOCAL_SETTINGS } from '../shared_components/LocalSettings';
import { FeatureAnnouncementCard, Modal, Row, Skeleton, TabPane, Tabs } from '../shared_components';
import Commands from './Commands';
import { DebugChecks, DebugContext, DebugContextSimulator, DebuggerView, DebugSDKLogs } from './Debugger';
import History from './History';
import Releases from './Releases';
import Settings from './Settings';
import { EndUserVerificationBanner } from './settings/EndUserVerificationAlerts';
import LogoutHeader from './components/LogoutHeader';
import { RecordsTable } from './context/RecordsTable';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import NudgeList from './nudges/NudgeList';
import { useAppContext } from '../Widget';
import ChecklistList from './checklists/ChecklistList';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import VisualStyleEditor from './settings/VisualStyleEditor';
import AudiencesList from './audiences/AudiencesList';
import HelpHubEditor from './helphub/HelpHubEditor';
import { CB_COLORS } from '@commandbar/design-system/components';
import { deconstructShareLink } from '@commandbar/internal/proxy-editor/share_links';

export const ScrollContainer = styled.div`
  overflow: auto;
  background: ${CB_COLORS.neutral0};
  height: 100%;
`;

export const PaddingContainer = styled.div`
  padding: 24px;
`;

interface ContainerProps {
  isStandaloneEditor?: boolean;
}

const Container = styled.div<ContainerProps>`
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: ${(props) => (props?.isStandaloneEditor ? 0 : '12px')};
`;

export const EditorRouter = () => {
  /** initialPath:
   *    string --> path to start with
   *    null --> no path
   *    undefined --> not yet determined; will wait to render until we get a response to shareInitialEditorPath
   */
  const [initialPath, setInitialPath] = React.useState<string | null | undefined>(undefined);
  const activeTab = LOCAL_SETTINGS.activeTab.getter() || null;
  const { isStandaloneEditor } = useAppContext();

  useEffect(() => {
    let canceled = false;
    (async () => {
      const initialPath = await Sender.shareInitialEditorPath()
        .then((result) => {
          if (result && result.success && result.data !== '') {
            let path = result.data;
            if (path && !path.startsWith('/')) path = `/${path}`;
            return path;
          }
          return null;
        })
        .catch(() => {
          return null;
        });

      if (!canceled) setInitialPath(initialPath);
    })();

    return () => {
      canceled = true;
    };
  }, []);

  const initialEntries = useMemo(() => {
    return editorRoutes.getEditorInitialEntries(initialPath ?? activeTab);
  }, [initialPath]);

  // if in standalone editor: wait until we figure out what initial path should be
  if (isStandaloneEditor && initialPath === undefined) return null;

  return (
    <MemoryRouter initialEntries={initialEntries} initialIndex={initialEntries.length - 1}>
      <MainRoutes />
    </MemoryRouter>
  );
};

const MainRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalShowing, setModalShowing] = useState(false);
  const newPath = React.useRef<null | string>(null);
  const history = useHistory();
  const location = useLocation();
  const { organization, isStandaloneEditor, activeCommand, unsavedChangesRef } = useAppContext();

  React.useEffect(() => {
    // share the current Editor route with the Proxy
    Sender.shareEditorRoute(location.pathname);
  }, [location.pathname]);

  React.useEffect(() => {
    // share the current Editor route with the Bar (currently used by standalone editor only)
    Sender.shareEditorRouteWithBar(`${location.pathname}${location.hash}${location.search}`);
  }, [location]);

  const handleRouterChange: ReceiverFunctionType = ({ data }) => {
    // if a different modal is shown already, revert the active navigation item and return
    if (unsavedChangesRef.current === 'backModalShown') {
      Sender.shareEditorRoute(history.location.pathname);
      return respondSuccess();
    }

    newPath.current = data.data;
    if (unsavedChangesRef.current === 'true') {
      if (!modalShowing) {
        setModalShowing(true);

        Modal.confirm({
          title: 'You have unsaved changes.',
          icon: <ExclamationCircleOutlined />,
          content: 'Leaving will discard these changes.',

          onOk() {
            if (!!newPath.current) {
              history.push(newPath.current);
            }
            unsavedChangesRef.current = 'false';
          },
          onCancel() {
            Sender.shareEditorRoute(history.location.pathname);
          },
          afterClose() {
            setModalShowing(false);
            newPath.current = null;
          },
          okText: 'Discard changes',
          cancelText: 'Keep editing',
        });
      }
    } else {
      if (!!newPath.current) {
        history.push(newPath.current);
      }
    }

    return respondSuccess();
  };

  usePortal({
    proxy: {
      onRouteChange: handleRouterChange,
    },
  });

  React.useEffect(() => {
    LOCAL_SETTINGS.activeTab.setter(location.pathname || editorRoutes.DEFAULT_EDITOR_ROUTE);
  }, [location.pathname]);

  useEffect(() => {
    Sender.getHostUrl().then((result: any) => {
      const url = new URL(result.url);
      const shareLinkMeta = deconstructShareLink(url.search);
      if (!!shareLinkMeta) {
        switch (shareLinkMeta.type) {
          case 'nudge': {
            history.replace(`${editorRoutes.NUDGE_ROUTE}/${shareLinkMeta.id}`);
            break;
          }
          case 'questlist': {
            history.replace(`${editorRoutes.CHECKLIST_ROUTE}/${shareLinkMeta.id}`);
            break;
          }
        }
      }

      // Get page name from the editor_path param in query string
      const getEditorPage = (editorPathParam: string | null) => {
        // Routes can have the format of `?editor_path=<pageName>/<id>` so we want to strip out tokens after pageName if they exist
        return editorPathParam && editorPathParam.includes('/')
          ? editorPathParam.slice(0, editorPathParam.indexOf('/'))
          : editorPathParam;
      };

      // Parse route on component's mount
      // Syntax: url?editor_path=<tab-key>
      const { searchParams } = url;
      const editorPathParam = searchParams.get('editor_path');
      const editorPage = getEditorPage(editorPathParam);

      if (
        editorRoutes.isValidEditorPage(editorPage) &&
        searchParams.toString() !== new URLSearchParams(location.search).toString()
      ) {
        history.replace({
          pathname: editorPage,
          search: searchParams.toString(),
        });
      }

      setIsLoading(false);
    });
  }, []);

  if (!organization) {
    return null;
  }

  if (isLoading) {
    return (
      <div style={{ padding: 24 }}>
        <Skeleton active={true} paragraph={{ rows: 7 }} />
      </div>
    );
  }

  return (
    <Container isStandaloneEditor={isStandaloneEditor}>
      <EndUserVerificationBanner />

      <Switch>
        <Route exact path="/">
          <Redirect to={editorRoutes.DEFAULT_EDITOR_ROUTE} />
        </Route>
        <Route exact path={`${editorRoutes.COMMANDS_ROUTE}/:commandId?`}>
          <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
            {activeCommand.state === 'none' && <LogoutHeader />}
            <Commands />
          </div>
        </Route>
        {/* if we specifically click on "Records", we don't want to show the active command */}
        <Route exact path={editorRoutes.RECORDS_ROUTE}>
          {activeCommand.state === 'none' && <LogoutHeader />}
          <RecordsTable showActiveCommand={false} />
        </Route>
        <Route path={`${editorRoutes.RECORDS_ROUTE}/:commandId?`}>
          {activeCommand.state === 'none' && <LogoutHeader />}
          <RecordsTable showActiveCommand={true} />
        </Route>
        <Route path={`${editorRoutes.NUDGE_ROUTE}/:nudgeId?/:stepId?`}>
          <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
            <LogoutHeader />
            <NudgeList />
          </div>
        </Route>
        <Route path={editorRoutes.HELPHUB_PARENT_ROUTE} component={HelpHubEditor} />
        <Route path={`${editorRoutes.CHECKLIST_ROUTE}/:checklistId?`}>
          <LogoutHeader />
          <ChecklistList />
        </Route>
        <Route exact path={editorRoutes.RELEASES_ROUTE}>
          <LogoutHeader />
          <ScrollContainer>
            <PaddingContainer>
              <Releases />
            </PaddingContainer>
          </ScrollContainer>
        </Route>
        <Route exact path={editorRoutes.AUDIENCES_ROUTE}>
          <LogoutHeader />
          <ScrollContainer>
            <PaddingContainer style={{ background: CB_COLORS.neutral0 }}>
              <AudiencesList />
            </PaddingContainer>
          </ScrollContainer>
        </Route>
        <Route path={editorRoutes.DESIGN_ROUTE}>
          <LogoutHeader />
          <ScrollContainer>
            <PaddingContainer>
              <VisualStyleEditor />
            </PaddingContainer>
          </ScrollContainer>
        </Route>
        <Route exact path={editorRoutes.SETTINGS_ROUTE}>
          <LogoutHeader />
          <ScrollContainer>
            <PaddingContainer>
              <Settings activeKeys={location.state ? location.state.activeKeys : undefined} />
            </PaddingContainer>
          </ScrollContainer>
        </Route>
        <Route
          path={'/tools/:page?'}
          render={({ match }) => {
            return (
              <>
                <LogoutHeader />
                <Tabs
                  activeKey={match.params.page ? match.url : editorRoutes.HISTORY_ROUTE}
                  onChange={(key) => {
                    history.push(key);
                  }}
                  isStandaloneEditor={isStandaloneEditor}
                  destroyInactiveTabPane={true}
                  type="card"
                  tabBarStyle={{
                    paddingTop: isStandaloneEditor ? 9 : 16,
                    paddingLeft: '16px',
                  }}
                >
                  <TabPane tab="History" key={editorRoutes.HISTORY_ROUTE}>
                    <ScrollContainer>
                      <PaddingContainer>
                        <History />
                      </PaddingContainer>
                    </ScrollContainer>
                  </TabPane>
                  <TabPane tab="Debugger" key={editorRoutes.DEBUGGER_ROUTE}>
                    <ScrollContainer>
                      <PaddingContainer>
                        <FeatureAnnouncementCard
                          identifier="debugger"
                          title={<Row align="middle">Using the debugger</Row>}
                          docsLink="https://www.commandbar.com/docs/other/debugger "
                        >
                          <span>Inspect and debug your configuration with our built-in tools.</span>
                        </FeatureAnnouncementCard>
                        <br />
                        <Switch>
                          <Route exact path={editorRoutes.DEBUGGER_ROUTE + '/' + DebuggerView.CHECKS}>
                            <DebugChecks />
                          </Route>
                          <Route exact path={editorRoutes.DEBUGGER_ROUTE + '/' + DebuggerView.CONTEXT_INSPECTOR}>
                            <DebugContext />
                          </Route>
                          <Route exact path={editorRoutes.DEBUGGER_ROUTE + '/' + DebuggerView.CONTEXT_SIMULATOR}>
                            <DebugContextSimulator />
                          </Route>
                          <Route exact path={editorRoutes.DEBUGGER_ROUTE + '/' + DebuggerView.SDK_LOGS}>
                            <DebugSDKLogs />
                          </Route>
                          <Route path={editorRoutes.DEBUGGER_ROUTE}>
                            <Redirect to={editorRoutes.DEBUGGER_ROUTE + '/' + DebuggerView.SDK_LOGS} />
                          </Route>
                        </Switch>
                      </PaddingContainer>
                    </ScrollContainer>
                  </TabPane>
                </Tabs>
              </>
            );
          }}
        />
      </Switch>
    </Container>
  );
};
