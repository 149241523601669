import { useMemo } from 'react';
import { RouteComponentProps, useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import Sender from '../management/Sender';
import { isValidEditorPage } from '@commandbar/internal/proxy-editor/editor_routes';

interface IDeepLinkProps {
  // Page to navigate to. Must be a valid APP_ROUTE
  to: RouteComponentProps['history']['push'];
  action?: 'push' | 'replace';
  // Tab to navigate to
  tab?: string;
  // User defined state to register with the navigation
  state?: Record<string, any>;
  target?: {
    identifier: string;
    type: 'popup' | 'section';
  };
  from?: {
    pathname: string;
    search: string;
  };
  // Callback to execute when the navigation is complete
  done?: Function;
}

const encodeEditorParams = (params: any) => {
  return btoa(JSON.stringify(params));
};

export const decodeEditorParams = (params: string) => {
  const q = new URLSearchParams(params).get('cb_editor_params');

  if (!q) {
    return {};
  }

  return JSON.parse(atob(q));
};

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const cbEditorParams = useMemo(() => decodeEditorParams(location.search), [location.search]);

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      deeplink: ({ from, to, tab, target, state, done, action = 'push' }: IDeepLinkProps) => {
        // If route provided is invalid, immediately return
        if (!isValidEditorPage(to)) {
          return;
        }

        history[action]({
          pathname: to,
          /**
           * To reduce clutter on URL search params, we use base64 encoding of
           * navigation variables.
           *
           * https://jsfiddle.net/soham_command/4hnceaq9/1/#
           */
          search: queryString.stringify({
            cb_editor_params: encodeEditorParams({
              from: {
                pathname: location.pathname,
                search: location.search,
                ...from,
              },
              tab,
              ...target,
            }),
          }),
          state,
        });
        done?.();
        Sender.shareEditorRoute(to);
      },
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      } as { [key: string]: string },
      cbEditorParams,
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
};

export default useRouter;
