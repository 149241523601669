import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router';
import * as nudgeSearchParams from '@commandbar/internal/proxy-editor/nudge_search_params';
import { Nudge } from '@commandbar/internal/middleware/nudge';
import {
  Button,
  Modal,
  Row,
  Table,
  Empty,
  StatusBadge,
  FeatureAnnouncementCard,
  DropdownMenu,
  message,
  Tabs,
  TabPane,
  SubHeading,
  InputNumber,
  Select,
  SimplePanel,
  PaddingContainer,
  Panel,
  Switch,
  Tooltip,
} from '../../shared_components';
import {
  BlockOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { useAppContext } from '../../Widget';
import NudgeDetail from './NudgeDetail';
import WidgetTemplate from '../components/templates/WidgetTemplate';
import * as templates from './nudge_templates';
import { template_cards } from './templates';

import nudgeGif from '../../img/nudges.gif';

import { INudgeType } from '@commandbar/internal/middleware/types';
import { NUDGE_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import useNudgeSettings from '../useEditor/useNudgeSettings';
import { CB_COLORS } from '@commandbar/design-system/components';
import { InfoCircle } from '@commandbar/design-system/icons/react';
import { UpgradeCTA } from '../components/UpgradeCTA';

export const nudgeTitleDisplay = ({ slug, steps }: INudgeType) => {
  let titleDisplay = slug || (steps.length === 1 ? steps[0].title : 'untitled');

  titleDisplay = titleDisplay.length > 40 ? `${titleDisplay.slice(0, 40)}...` : titleDisplay;

  return titleDisplay + (steps.length === 1 ? ' (1 step)' : ` (${steps.length} steps)`);
};

enum PanelKey {
  RATE_LIMITING,
}

const NudgeList = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ nudgeId?: string; stepId?: string }>();
  const { loading: appContextLoading, nudges, organization } = useAppContext();
  const [activeNudge, setActiveNudge] = React.useState<INudgeType | undefined>(undefined);
  const [showTemplate, setShowTemplate] = React.useState<boolean | undefined>(false);
  const { dispatch, isStandaloneEditor } = useAppContext();
  const [activeKeys, setActiveKeys] = React.useState<PanelKey[]>([PanelKey.RATE_LIMITING]);
  const { limit, period, actions } = useNudgeSettings();
  const [activeTabKey, setActiveTabKey] = React.useState('nudges');

  const createFromTemplate = (nudge?: INudgeType) => {
    if (!organization) {
      return;
    }
    const newNudge = !!nudge ? nudge : templates.emptyNudge;
    history.replace(`${NUDGE_ROUTE}/?nudgeId=${newNudge.id}`);
    setActiveNudge({
      ...newNudge,
      organization: organization.id.toString(),
    });
  };

  const updateActiveNudge = (nudge: INudgeType | undefined) => {
    setActiveNudge(nudge);

    if (!!nudge) {
      history.replace(`${NUDGE_ROUTE}/${nudge.id}`);
    } else {
      history.replace(NUDGE_ROUTE);
    }
  };

  useEffect(() => {
    if (appContextLoading || !organization) return;
    if (!params.nudgeId && !params.stepId && !location.search) {
      setActiveNudge(undefined);
      return;
    }
    const nudge = nudges.find((n) => n.id === Number(params.nudgeId));
    if (!nudge && !!params.nudgeId && Number(params.nudgeId) >= 0) {
      history.replace(NUDGE_ROUTE);
      return;
    }
    if (!!nudge) {
      if (activeNudge === undefined || activeNudge.id !== nudge.id) {
        updateActiveNudge(nudge);
        return;
      }
    }

    const searchParams = new URLSearchParams(location.search);
    const template = searchParams.get(nudgeSearchParams.NUDGE_TEMPLATE);
    switch (template) {
      case nudgeSearchParams.NUDGE_ANNOUNCEMENT_TEMPLATE_PARAM_VALUE: {
        return createFromTemplate(templates.announcementNudge);
      }
      case nudgeSearchParams.NUDGE_TOUR_TEMPLATE_PARAM_VALUE: {
        return createFromTemplate(templates.productTour);
      }
      case nudgeSearchParams.NUDGE_FEATURE_HIGHLIGHT_TEMPLATE_PARAM_VALUE: {
        return createFromTemplate(templates.featureHighlightNudge);
      }
      case nudgeSearchParams.NUDGE_FEEDBACK_TEMPLATE_PARAM_VALUE: {
        return createFromTemplate(templates.feedback);
      }
      case nudgeSearchParams.NUDGE_RATING_TEMPLATE_PARAM_VALUE: {
        return createFromTemplate(templates.rating);
      }
      case nudgeSearchParams.NUDGE_NPS_TEMPLATE_PARAM_VALUE: {
        return createFromTemplate(templates.nps);
      }
      default: {
        break;
      }
    }
  }, [organization, location.search, params, appContextLoading, !!activeNudge]);

  useEffect(() => {
    setActiveKeys((keys) =>
      organization.nudge_rate_limit !== null
        ? [...keys, PanelKey.RATE_LIMITING]
        : keys.filter((k) => k !== PanelKey.RATE_LIMITING),
    );
  }, [organization.nudge_rate_limit]);

  const onSave = async (nudge: INudgeType) => {
    const newNudge = await dispatch.nudges.save(nudge);
    updateActiveNudge(newNudge);
    return newNudge;
  };

  const onDelete = (nudge: INudgeType) => {
    Modal.confirm({
      icon: <WarningOutlined />,
      title: 'Are you sure to delete this nudge? This cannot be undone.',
      async onOk() {
        await dispatch.nudges.delete(nudge);
        updateActiveNudge(undefined);
      },
    });
  };

  if (appContextLoading || !organization) {
    return null;
  }

  const columns = [
    {
      title: 'Nudge',
      dataIndex: 'title',
      key: 'title',
      render: (_: any, nudge: INudgeType) => {
        return nudgeTitleDisplay(nudge);
      },
    },
    {
      title: 'Status',
      key: 'is_live',
      dataIndex: 'is_live',
      render: (_: any, { is_live = false }) =>
        is_live ? (
          <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />
        ) : (
          <StatusBadge color="orange" text="Draft" />
        ),
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (_: any, nudge: INudgeType) => {
        return (
          <DropdownMenu
            keyName="nudge-actions"
            items={[
              {
                name: 'Edit',
                icon: <EditOutlined />,
                onClick: () => updateActiveNudge(nudge),
              },
              {
                name: 'Duplicate',
                icon: <BlockOutlined />,
                onClick: async () => {
                  try {
                    const name = nudge.slug || nudge.steps[0].title || nudge.steps[0].form_factor.type || 'nudge';
                    const copyNumber = nudges.filter((n) => n.slug.startsWith(`copy-of-${name}`)).length;

                    const newNudge = {
                      ...nudge,
                      id: -1,
                      old_nudge_id: null,
                      is_live: false,
                      slug: `copy-of-${name}${copyNumber > 0 ? `-${copyNumber}` : ''}`,
                      steps: [
                        ...nudge.steps.map((step) => ({
                          ...step,
                          is_live: false,
                        })),
                      ],
                    };
                    await dispatch.nudges.save(newNudge);
                  } catch (err) {
                    message.error('Error duplicating nudge.');
                  }
                },
              },
              {
                name: 'Copy id',
                icon: <CopyOutlined />,
                onClick: () => {
                  const element = document.createElement('textarea');
                  element.value = nudge.id.toString();
                  document.body.appendChild(element);
                  element.select();
                  document.execCommand('copy');
                  document.body.removeChild(element);
                  message.info('Nudge id copied to clipboard.');
                },
              },
              {
                name: 'Delete',
                icon: <DeleteOutlined />,
                onClick: () => onDelete(nudge),
              },
            ]}
          />
        );
      },
    },
  ];

  if (activeNudge) {
    return (
      <NudgeDetail
        initialNudge={activeNudge}
        onClose={() => {
          updateActiveNudge(undefined);
          if (!Nudge.isNew(activeNudge)) setShowTemplate(undefined);
        }}
        onDelete={onDelete}
        onSave={onSave}
        allNudges={nudges}
      />
    );
  } else if (showTemplate) {
    return (
      <PaddingContainer>
        <WidgetTemplate
          templates={template_cards}
          header="New Nudge"
          onBack={() => setShowTemplate(undefined)}
          createFromTemplate={createFromTemplate}
        />
      </PaddingContainer>
    );
  } else {
    return (
      <Tabs
        activeKey={activeTabKey}
        onChange={setActiveTabKey}
        isStandaloneEditor={isStandaloneEditor}
        type="card"
        tabBarStyle={{
          paddingTop: isStandaloneEditor ? '9px' : 0,
          marginTop: -10,
          paddingLeft: '16px',
          display: 'block',
        }}
        destroyInactiveTabPane
      >
        <TabPane tab="Nudges" key="nudges">
          <PaddingContainer>
            <FeatureAnnouncementCard
              identifier="nudges"
              title={<Row align="middle">Introducing Nudges</Row>}
              img={{
                src: nudgeGif,
                alt: 'Use nudges to highlight content to your users',
              }}
              docsLink="https://www.commandbar.com/docs/nudges/overview"
            >
              <span>Guide your customers to success with targeted and timely nudges.</span>
            </FeatureAnnouncementCard>

            <UpgradeCTA product="nudges" />

            <SimplePanel>
              <Row justify="end">
                <Button onClick={() => setShowTemplate(true)} icon={<PlusCircleOutlined />} type="primary">
                  Create Nudge
                </Button>
              </Row>
              <Table
                pagination={{
                  defaultPageSize: 10,
                  hideOnSinglePage: true,
                }}
                rowClassName="editable-row"
                columns={columns}
                dataSource={nudges.map((n) => ({ ...n, key: `${n.id}-${n.old_nudge_id}` }))}
                onRow={(nudge: INudgeType, _rowIndex: any) => {
                  return {
                    onClick: (_e: React.MouseEvent) => {
                      updateActiveNudge(nudge);
                    },
                  };
                }}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        "You don't have any nudges yet. Create one by clicking the 'Create Nudge' button above."
                      }
                    />
                  ),
                }}
              />
            </SimplePanel>
          </PaddingContainer>
        </TabPane>
        <TabPane tab="Settings" key="settings">
          <PaddingContainer>
            <Panel
              activeKeys={activeKeys}
              setActiveKeys={setActiveKeys}
              panelKey={PanelKey.RATE_LIMITING}
              header={
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <span>Rate Limiting</span>
                  <Tooltip
                    placement="top"
                    content="Limit the number of nudges (product tours, surveys) your users will see in a given time period. "
                  >
                    <InfoCircle style={{ width: 14, height: 14 }} />
                  </Tooltip>
                </div>
              }
              actions={
                <Switch
                  checked={organization.nudge_rate_limit !== null}
                  onChange={(isChecked) => {
                    actions.limit.update(isChecked ? 1 : null);
                  }}
                />
              }
            >
              <div style={{ display: 'flex', gap: 8 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
                  <SubHeading>Limit</SubHeading>
                  <InputNumber
                    value={limit}
                    onChange={actions.limit.update}
                    min={1}
                    style={{
                      width: '100%',
                      height: '32px',
                      fontSize: 14,
                      border: `1px solid ${CB_COLORS.neutral300}`,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    placeholder="No limit"
                    size="small"
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
                  <SubHeading>Period</SubHeading>
                  <Select
                    value={period}
                    options={[
                      { label: 'Day', value: 'day' },
                      { label: 'Week', value: 'week' },
                      { label: 'Session', value: 'session' },
                    ]}
                    onChange={actions.period.update}
                  />
                </div>
              </div>
            </Panel>
          </PaddingContainer>
        </TabPane>
      </Tabs>
    );
  }
};

export default NudgeList;
