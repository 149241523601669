/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { nanoid } from 'nanoid';
import { StatusSwitch, Tooltip, message, Modal, Tag } from '../../../shared_components';
import {
  ITemplateOptions,
  IOrganizationType,
  IEditorCommandType,
  RequestType,
} from '@commandbar/internal/middleware/types';

import { getTriggerKey, osControlKey } from '@commandbar/internal/util/operatingSystem';

import DetailLayout from './DetailLayout';

import * as Command from '@commandbar/internal/middleware/command';

import VisualCommandEditor from './VisualCommandEditor/VisualCommandEditor';
import { getNewSortkey } from '../../components/SortableTable';
import { Button } from '../../../shared_components';
import Logger from '@commandbar/internal/util/Logger';
import Sender from '../../../management/Sender';
import { isEveryConditionRuleValid } from './ConditionRulePanels/helpers';
import { argumentTypes, IOptionType } from './arguments/argumentUtils';
import Hotkey from '@commandbar/internal/client/Hotkey';
import { deleteCommandWithWarnings } from '../utils';
import useRouter from '../../../hooks/useRouter';
import { getConditions } from '@commandbar/internal/middleware/helpers/rules';
import { isStandaloneEditorURL } from '@commandbar/internal/util/location';

import produce from 'immer';
import { AppState, useAppContext } from '../../../Widget';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';
import { ITemplate } from '@commandbar/internal/middleware/types';
import { PreviewButton } from '../../components/PreviewButton';
import { COMMANDS_ROUTE, HELPHUB_PARENT_ROUTE, RECORDS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { useUsage } from '../../../hooks/useUsage';

/*******************************************************************************/
/* Interface
/*******************************************************************************/

export interface ICommandDetailProps extends RouteComponentProps<{ commandId?: string }> {
  command: IEditorCommandType;
  appState: AppState;
  organization: IOrganizationType;
  isNumLiveCommandsExceeded: boolean;
  cbEditorParams: any;
  unsavedChangesRef: React.MutableRefObject<'true' | 'false' | 'backModalShown'>;
}

/*******************************************************************************/
/* Component
/*******************************************************************************/

export interface ICommandDetailState {
  command: IEditorCommandType;
  isDirty: boolean;
  isSaving: boolean;
  isRouterDefined: boolean;
}

const capitalize = (s: string | null | undefined): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export interface ICommandDetailDispatch {
  argument: {
    new: (nameToAdd: string, order_key: number) => void;
    updateName: (oldName: string, newName: string) => void;
    updateValue: (argName: string, newValue: any) => void;
    delete: (nameToDelete: string) => void;
    reorder: (oldIndex: number, newIndex: number) => void;
  };
  template: {
    updateType: (newType: ITemplate['type']) => void;
    updateValue: (e: string | RequestType | string[], index?: number) => void;
    updateOptions: (option: ITemplateOptions) => void;
    addClickElem: () => void;
    deleteClickElem: (valueIndexToDelete: number) => void;
  };
  recordAction: {
    showInDefaultList: () => void;
    hideInDefaultList: () => void;
  };
  save: () => void;
  updateCommand: (recipe: (draft: IEditorCommandType) => void) => void;
}

class CommandDetail extends React.Component<ICommandDetailProps, ICommandDetailState> {
  public constructor(props: ICommandDetailProps) {
    super(props);
    this.state = {
      command: props.command,
      isDirty: false,
      isSaving: false,
      isRouterDefined: false,
    };
    // Figure out default for isRouterDefined
    // Fixme: switch to useWindowInfo() when this is turned to a FC
    Sender.getHostUrl().then(({ url }) => {
      const isStandaloneEditor = isStandaloneEditorURL(url);
      if (isStandaloneEditor) {
        /** Standalone editor mocks the router, so we need to guess if the customer
         * has a router setup. Best guess is looking at other commands.
         */
        const hasOtherCommandsWithRouter = this.props.appState.commands.some(
          (c) => c.is_live && c.template.type === 'link' && c.template.operation === 'router',
        );
        this.setState({ isRouterDefined: hasOtherCommandsWithRouter });
      } else {
        Sender.shareCallbacks().then(({ callbacks }: any) => {
          if (callbacks.includes('commandbar-router')) {
            this.setState({ isRouterDefined: true });
          }
        });
      }
    });
  }

  private mounted = false;

  public updateCommand = (recipe: (draft: IEditorCommandType) => void) => {
    this.setState((state) =>
      produce(state, (draft: ICommandDetailState) => {
        draft.isDirty = true;
        recipe(draft.command);
      }),
    );
  };

  public isShownInDefaultList = (): boolean => {
    return Command.showInDefaultList(this.state.command);
  };

  public isRecordAction = (): boolean => {
    return Command.isRecordAction(this.state.command);
  };

  public componentDidUpdate = (prevProps: ICommandDetailProps, _prevState: ICommandDetailState) => {
    if (prevProps.command.id !== this.props.command.id) {
      this.setState({
        command: this.props.command,
        isDirty: false,
        isSaving: false,
      });
    }
    // this is to prevent setting to true when the confirmation on clicking back is shown
    if (!_prevState.isDirty && this.state.isDirty) {
      this.props.unsavedChangesRef.current = 'true';
    }
  };

  public componentDidMount = () => {
    this.mounted = true;
    document.addEventListener('keydown', this.handleShortcuts);
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  };

  public componentWillUnmount = () => {
    this.mounted = false;
    document.removeEventListener('keydown', this.handleShortcuts);
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  };

  public beforeUnloadListener = (event: any) => {
    const { isDirty } = this.state;

    if (isDirty) {
      // Browsers don't support returning a custom string here:
      //https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      event.preventDefault();
      // Required for chrome
      event.returnValue = '';
      // Message warning only shows after dialog is closed
      message.warning('You have unsaved command changes.');
    }
  };

  public handleShortcuts = (e: any) => {
    const triggerKey = getTriggerKey(e);

    if (triggerKey && e.key === 's') {
      e.preventDefault();
      e.stopPropagation();
      this.saveCommand();
    }
  };

  public onBack = () => {
    this.props.appState.dispatch.commands.setActive(undefined);
    this.props.history.goBack();
    this.props.unsavedChangesRef.current = 'false';
  };

  private updateOrganization = (updatedOrganization: IOrganizationType) => {
    return this.props.appState.dispatch.organization.update(updatedOrganization);
  };

  private setDefaultCommandForRecord = (option: string, defaultCommandId?: number) => {
    const updatedOrganization = {
      ...this.props.organization,
      resource_options: {
        ...this.props.organization.resource_options,
        [option]: { ...this.props.organization.resource_options[option], default_command_id: defaultCommandId },
      },
    };

    return this.updateOrganization(updatedOrganization);
  };

  private getCommandsForResource = (resourceKey?: string) => {
    return resourceKey ? this.props.appState.commands.filter((command) => command.template.object === resourceKey) : [];
  };

  public openCommand = () => {
    Sender.setTestMode(true);
    Sender.openBar(this.state.command.text);
  };

  public turnOffShowInDefaultList = () => {
    if (!this.isShownInDefaultList()) return;

    this.updateCommand((draft) => {
      let contextArg;
      for (const arg of Object.values(draft.arguments)) {
        if (
          arg.type === 'context' &&
          !!(arg?.show_in_record_action_list ?? true) &&
          !!(arg?.show_in_default_list ?? true)
        ) {
          contextArg = arg;
        }
      }

      if (contextArg) {
        // wipe fields not relevant to record action
        draft.category = null;
        draft.availability_rules = [];
        draft.recommend_rules = [];
        draft.shortcut = [];
        draft.shortcut_mac = [];
        draft.shortcut_win = [];
        draft.hotkey_mac = '';
        draft.hotkey_win = '';

        contextArg.show_in_default_list = false;
      }

      draft.show_preview = false;
    });
  };

  public turnOnShowInDefaultList = () => {
    if (this.isShownInDefaultList()) return;

    this.updateCommand((draft) => {
      let contextArg;

      for (const arg of Object.values(draft.arguments)) {
        if (
          arg.type === 'context' &&
          !!(arg?.show_in_record_action_list ?? true) &&
          !(arg?.show_in_default_list ?? true)
        ) {
          contextArg = arg;
        }
      }

      if (contextArg) {
        contextArg.show_in_default_list = true;
      }
    });
  };

  public saveCommand = async (forceSave = false) => {
    const setState = <K extends keyof ICommandDetailState>(state: Pick<ICommandDetailState, K>) => {
      if (this.mounted) {
        // NOTE: If we have just saved a brand new command, this component will be unmounted and remounted
        // with the new command, since the command's ID will change from -1 to the new command's ID.
        // We guard here to suppress the `Can't perform a React state update on an unmounted component` error.
        this.setState(state);
      }
    };

    if (!forceSave && this.props.isNumLiveCommandsExceeded) {
      if (this.state.command.is_live && !this.props.command.is_live) {
        // Ensure that we can't switch a non-live command to live if we're at the limit
        return;
      } else if (this.props.command.template.type === 'helpdoc' && this.state.command.template.type !== 'helpdoc') {
        // Ensure that we can't switch a helpdoc command to non-helpdoc if we're at the limit
        return;
      }
    }

    try {
      // Make any necessary modifications to the command before saving
      const newCommand = produce(this.state.command, (draft: IEditorCommandType) => {
        // If command is of type video, automagically append a video argument
        if (draft.template.type === 'video') {
          const videoSource = draft.template.value;

          draft.arguments['__video__'] = {
            is_private: true,
            type: 'video',
            order_key: Object.keys(draft.arguments).length
              ? Math.max(...Object.values(draft.arguments).map((a) => a.order_key), 0) + 1
              : 0,
            value: {
              source: videoSource,
              title: draft.text,
              description: draft.explanation,
            },
          };
        }

        if (Array.isArray(draft.content)) {
          draft.content = draft.content.map((content) => {
            if (typeof content === 'string') {
              return sanitizeHtml(content);
            }

            if (content.type === 'html') {
              return sanitizeHtml(content.value);
            }

            return content;
          });
        } else if (typeof draft.content === 'string') {
          draft.content = sanitizeHtml(draft.content);
        } else if (draft.content?.type === 'html') {
          draft.content.value = sanitizeHtml(draft.content.value);
        }

        if (Array.isArray(draft.detail)) {
          draft.detail = draft.detail.map((detail) => {
            if (typeof detail === 'string') {
              return detail;
            }

            if (detail.type === 'html') {
              return sanitizeHtml(detail.value);
            }

            return detail;
          });
        } else if (typeof draft.detail === 'string') {
          draft.detail = sanitizeHtml(draft.detail);
        } else if (draft.detail?.type === 'html') {
          draft.detail.value = sanitizeHtml(draft.detail.value);
        }

        for (const arg of Object.values(draft.arguments)) {
          if (arg.type === 'html') {
            arg.value.source = sanitizeHtml(arg.value.source);
          }
        }
      });

      setState({ isSaving: true });

      const res = await this.props.appState.dispatch.commands.save(newCommand);

      // Only update the history if we're creating a new command
      if (newCommand.id === -1) {
        if (res.template.type !== 'helpdoc') {
          this.props.history.replace(`${this.isRecordAction() ? RECORDS_ROUTE : COMMANDS_ROUTE}/${res.id}`);
        } else {
          this.props.history.replace(`${HELPHUB_PARENT_ROUTE}/edit/${res.id}`);
        }
      }

      const recordKey = res.template.object;

      if (!!recordKey) {
        const commandsForResource = this.getCommandsForResource(recordKey);
        const isCurrentCommandFirstForRecord = commandsForResource.length === 1;

        // If the current command is the first command for a record, make it the default
        if (isCurrentCommandFirstForRecord) {
          await this.setDefaultCommandForRecord(recordKey, res.id);
        }
      }

      setState({ isDirty: false });
      this.props.unsavedChangesRef.current = 'false';

      return res;
    } catch (err) {
      Logger.red('Save Error: ', err);
    } finally {
      setState({ isSaving: false });
    }
  };

  public handleCommandDeleteClick = async () => {
    const commandToDelete = this.props.command;

    await deleteCommandWithWarnings({
      commandToDelete,
      onCommandDelete: this.onBack,
      appState: this.props.appState,
    });
  };

  /**
   * Command type has changed
   */

  public onCommandTypeChange = (nextType: ITemplate['type']) => {
    this.updateCommand((draft) => {
      if (draft.template.type === 'video' && nextType !== 'video') {
        delete draft.arguments.__video__;
      }

      if (draft.template.type === 'helpdoc' && nextType !== 'helpdoc') {
        delete draft.arguments.__html__;
      }

      switch (nextType) {
        case 'clickBySelector':
        case 'clickByXpath':
        case 'click':
          draft.template.type = nextType;
          draft.template.value = [''];
          break;
        case 'link':
          const defaultLinkType = this.state.isRouterDefined ? 'router' : 'blank';

          draft.template.type = nextType;
          draft.template.value = '';
          draft.template.operation = defaultLinkType;
          break;
        case 'request':
          draft.template.type = nextType;
          draft.template.value = {
            url: '',
            method: 'get',
          };

          break;
        default:
          draft.template.type = nextType;
          draft.template.value = '';
          break;
      }
    });
  };

  public checkCanSaveCommand = (): boolean => {
    // TODO: Replace this with info from the useUsage hook once this component has been refactored into an FC.
    const { isNumLiveCommandsExceeded } = this.props;
    const isLive = this.props.command.is_live;
    const isValidAndDirty = (this.validateCommand() && this.state.isDirty) || this.state.command.id === -1;

    return (
      /**
       * Command is saveable in draft mode regardless of the # of live commands
       * exception: unless the user is switching the command to live mode
       */
      (isNumLiveCommandsExceeded && !isLive && isValidAndDirty) ||
      /**
       * Integrity checks for command
       */
      (!isNumLiveCommandsExceeded && isValidAndDirty) ||
      (isNumLiveCommandsExceeded && isValidAndDirty && isLive)
    );
  };

  /******************************************************************************/
  /************************** ARGUMENT CRUD OPERATIONS  **************************/
  /******************************************************************************/

  public updateArgument = (arg: string, val: any, replaceValue?: boolean) => {
    this.updateCommand((draft) => {
      const newArgs = replaceValue ? { ...val } : { ...draft.arguments[arg], ...val };
      draft.arguments[arg] = newArgs;
    });
  };

  public updateArgumentName = (oldName: string, newName: string) => {
    if (oldName !== newName) {
      this.updateCommand((draft) => {
        draft.arguments[newName] = draft.arguments[oldName];
        delete draft.arguments[oldName];
      });
    }
  };

  public updateArgumentOrder = (oldIndex: number, newIndex: number) => {
    this.updateCommand((draft) => {
      const newArgs = Object.keys(draft.arguments).reduce<Record<string, any>>((acc, cur) => {
        const item = draft.arguments[cur];
        acc[cur] = {
          ...item,
          order_key: getNewSortkey(item.order_key, oldIndex, newIndex),
        };

        return acc;
      }, {});

      draft.arguments = newArgs;
    });
  };

  public addArgument = (toAdd: string, order_key: number) => {
    const defaultPrompt = argumentTypes.find((option: IOptionType) => option.key === 'text')?.defaultPrompt;
    const defaultArgType = { type: 'provided' as any, value: 'text', order_key, label: defaultPrompt, id: nanoid() };

    this.updateCommand((draft) => {
      draft.arguments[toAdd] = defaultArgType;
    });
  };

  public deleteArgument = (toDelete: string) => {
    this.updateCommand((draft) => {
      delete draft.arguments[toDelete];
    });
  };

  /******************************************************************************/
  /************************** Template value operations **************************/
  /******************************************************************************/

  /**
   * Template item has been added (css or xpath list)
   */
  public onClickElemListAdd = () => {
    this.updateCommand((draft) => {
      if (Array.isArray(draft.template.value)) {
        draft.template.value.push('');
      }
    });
  };

  /**
   * Template item has been deleted
   */
  public onClickElemListDelete = (index: number) => {
    this.updateCommand((draft) => {
      if (Array.isArray(draft.template.value)) {
        draft.template.value.splice(index, 1);
      }
    });
  };

  /**
   * Template item has changed
   */
  public onTemplateValueChange = (e: string | RequestType | string[], index?: number) => {
    this.updateCommand((draft) => {
      if (typeof e === 'object') {
        draft.template.value = e;
      } else {
        if (index === undefined) {
          draft.template.value = e.trim();
        } else {
          if (Array.isArray(draft.template.value)) {
            // FIXME: clean up draft.templateValue
            draft.template.value.splice(index, 1, e);
          }
        }
      }
    });
  };

  /******************************************************************************/
  /*********************** Generic field change operations **********************/
  /******************************************************************************/

  public onTemplateOptionsChange = (option: ITemplateOptions) => {
    this.updateCommand((draft) => {
      Object.entries(option).forEach(([key, value]) => {
        // @ts-expect-error: FIXME types
        draft.template[key] = value;
      });
    });
  };

  public validateCommand = () => {
    const {
      text,
      arguments: args,
      hotkey_mac,
      hotkey_win,
      availability_expression,
      recommend_expression,
      always_recommend,
      category,
    } = this.state.command;
    const isEveryArgumentValid = Object.keys(args).find((arg) => arg.length === 0) === undefined;

    const isEveryArgumentTypeValid = Object.keys(args).every((arg) => {
      const { type } = args[arg];
      return ['context', 'provided', 'set', 'html', 'video'].includes(type);
    });

    const [isEveryMacShortcutValid] = Hotkey.validateEditorShortcutValues(hotkey_mac);
    const [isEveryWinShortcutValid] = Hotkey.validateEditorShortcutValues(hotkey_win);
    const isEveryShortcutValid = isEveryMacShortcutValid && isEveryWinShortcutValid;

    const isEveryAvailabilityRuleValid = isEveryConditionRuleValid(getConditions(availability_expression));
    const isAlwaysRecommend = always_recommend;
    const isEveryRecommendationRuleValid =
      isAlwaysRecommend || isEveryConditionRuleValid(getConditions(recommend_expression));
    const isEveryRuleValid = isEveryAvailabilityRuleValid && isEveryRecommendationRuleValid;

    const hasCategoryIfShownInDefault = this.isShownInDefaultList() ? category !== null : true;

    return (
      !!text &&
      isEveryArgumentValid &&
      isEveryArgumentTypeValid &&
      isEveryShortcutValid &&
      isEveryRuleValid &&
      hasCategoryIfShownInDefault
    );
  };

  public onReturnToList = () => {
    const canSave = this.checkCanSaveCommand();
    const onBack = this.onBack;

    if (canSave) {
      const unsavedChangesRef = this.props.unsavedChangesRef;
      this.props.unsavedChangesRef.current = 'backModalShown';
      Modal.confirm({
        title: 'You have unsaved changes.',
        icon: <ExclamationCircleOutlined />,
        content: 'Leaving will discard these changes.',
        onOk() {
          onBack();
        },
        onCancel() {
          unsavedChangesRef.current = 'true';
        },
        okText: 'Discard changes',
        cancelText: 'Keep editing',
      });
    } else {
      this.onBack();
    }
  };

  public disabledTooltip: () => string = () => {
    if (!!!this.state.command.text || (this.state.command.category === null && !this.isRecordAction())) {
      return 'Commands must have a Title and Category before saving.';
    }

    return '';
  };

  public defaultToAdvancedMode = (command: IEditorCommandType) => {
    if (command.template.commandType !== 'object') return true;
    if (Object.keys(command.arguments).length > 1) return true;
    if (command.hotkey_mac.length > 0 || command.hotkey_win.length > 0) return true;
    if (!!command.explanation) return true;
    return false;
  };

  public updateCommandState: ICommandDetailDispatch = {
    argument: {
      new: this.addArgument,
      updateName: this.updateArgumentName,
      updateValue: this.updateArgument,
      delete: this.deleteArgument,
      reorder: this.updateArgumentOrder,
    },
    template: {
      updateType: this.onCommandTypeChange,
      updateValue: this.onTemplateValueChange,
      updateOptions: this.onTemplateOptionsChange,
      addClickElem: this.onClickElemListAdd,
      deleteClickElem: this.onClickElemListDelete,
    },
    updateCommand: this.updateCommand,
    save: this.saveCommand,
    recordAction: {
      showInDefaultList: this.turnOnShowInDefaultList,
      hideInDefaultList: this.turnOffShowInDefaultList,
    },
  };

  public render() {
    const content = (
      <VisualCommandEditor
        appState={this.props.appState}
        command={this.state.command}
        isDirty={this.state.isDirty}
        updateCommandState={this.updateCommandState}
        isNumLiveCommandsExceeded={this.props.isNumLiveCommandsExceeded}
        organization={this.props.organization}
      />
    );

    const canSave = this.checkCanSaveCommand();

    const rightActions = [
      ...(this.props.command.third_party_source != null
        ? [
            <Tooltip
              key={'right-tooltip-third-party-source'}
              content={'Command imported via ' + capitalize(this.props.command.third_party_source)}
            >
              <Tag color={'geekblue'}> {this.props.command.third_party_source} </Tag>
            </Tooltip>,
          ]
        : []),
      <Button
        key={`right-button-${this.props.command.id}`}
        disabled={this.props.command.id < 0}
        onClick={this.handleCommandDeleteClick}
        style={{ color: 'rgba(0, 0, 0, 0.5)' }}
        icon={<DeleteOutlined />}
        ghost
      />,
      <Tooltip
        key={`right-tooltip-status-${this.props.command.id}`}
        content={
          this.props.isNumLiveCommandsExceeded ? (
            <span>You have hit your organization's maximum number of allowed live commands</span>
          ) : (
            <span>Only admins can view draft commands</span>
          )
        }
        disabled={this.state.command.is_live}
      >
        <StatusSwitch
          checked={this.state.command.is_live}
          onChange={(is_live: boolean) => {
            /**
             * Can't switch a command to live if threshold is exceeded
             */
            if (this.props.isNumLiveCommandsExceeded && !this.props.command.is_live) {
              return;
            }
            this.setState(
              (state) => {
                const command = produce(state.command, (draft) => {
                  draft.is_live = is_live;
                });

                return { command };
              },
              () => this.saveCommand(true),
            );
          }}
          onLabel="Live"
          offLabel="Draft"
          disabled={
            (this.props.isNumLiveCommandsExceeded && !this.props.command.is_live) ||
            !this.validateCommand() ||
            this.state.isSaving
          }
        />
      </Tooltip>,
      ...(this.isShownInDefaultList()
        ? [
            <PreviewButton
              onClick={() => {
                Sender.hideEditor();
                this.openCommand();
              }}
              enableClickInStudio
            />,
          ]
        : []),
      <Tooltip
        key={`right-tooltip-save-${this.props.command.id}`}
        content={this.disabledTooltip()}
        hideOnClick={true}
        placement="bottom"
        showIf={!!this.disabledTooltip()}
      >
        <Button
          key={`right-button-save-${this.props.command.id}`}
          onClick={() => this.saveCommand()}
          type="primary"
          disabled={!canSave || !!this.disabledTooltip()}
        >
          Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
        </Button>
      </Tooltip>,
    ];

    return (
      <div
        style={{
          background: '#F2F2F2',
          borderRadius: 16,
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <DetailLayout
          isRecordAction={this.isRecordAction()}
          showInDefaultList={this.isShownInDefaultList()}
          onGoBack={this.onReturnToList}
          goBackText="Back"
          actions={rightActions}
          content={content}
          title={this.state.command.text}
        />
      </div>
    );
  }
}

const CommandDetailWrapper = (
  props: Omit<ICommandDetailProps, 'appState' | 'cbEditorParams' | 'isNumLiveCommandsExceeded'>,
) => {
  const { cbEditorParams } = useRouter();
  const { exceeding } = useUsage();
  const appState = useAppContext();

  return (
    <CommandDetail
      {...props}
      appState={appState}
      cbEditorParams={cbEditorParams}
      isNumLiveCommandsExceeded={exceeding.isAtOrOverLiveCommands}
    />
  );
};

export default withRouter(CommandDetailWrapper);
