import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  Button,
  SearchInput,
  ListItem,
  Icon,
  commandDefault,
  StatusBadge,
  Table,
  Row,
  Col,
  Typography,
  FeatureAnnouncementCard,
  Tooltip,
  Tag,
  SimplePanel,
} from '../../shared_components';

import { ContextSettingsModal } from './contextSettings/ContextSettingsModal';
import { IEditorCommandTypeLite, IResourceSettingsByContextKey } from '@commandbar/internal/middleware/types';
import ActiveBadge, { KeyStatus } from './ActiveBadge';
import { ContextDataObject, useContextPartition } from './contextSettings/useContextPartition';
import { useContextSettings } from './contextSettings/useContextSettings';
import { isNumber } from 'lodash';
import * as Command from '@commandbar/internal/middleware/command';
import useLinkedCommand from './useLinkedCommand';
import { useReportEvent } from '../../shared_components/useEventReporting';
import { useAppContext } from '../../Widget';
import { PaddingContainer, ScrollContainer } from '../Router';
import { ActiveCommand } from '../commands/ActiveCommand';
import { UpgradeCTA } from '../components/UpgradeCTA';

const StyledTable = styled(Table)`
  tr {
    cursor: pointer;
  }
  .record-actions-container {
    cursor: default;
    border-bottom-width: 2px;
  }
  .record-actions-container > td {
    padding: 2px 12px 12px 32px;
  }
  tr:not(.record-actions-container) > td {
    border: none;
    background: #f5f5f5;
  }
`;

export const RecordsTable = (props: { showActiveCommand: boolean }) => {
  const params = useParams<{ commandId?: string }>();
  const [selected, setSelected] = useState<ContextDataObject | undefined>();
  const [searchTerm, setSearchTerm] = React.useState('');
  const { records } = useContextPartition();
  const { current: contextSettings } = useContextSettings();
  const { activeCommand, organization, dispatch } = useAppContext();

  React.useEffect(() => {
    const commandId = params.commandId ? parseInt(params.commandId) : undefined;
    if (!!commandId && commandId >= 0) {
      dispatch.commands.setActiveCommandById(commandId);
    } else if (commandId === undefined) {
      dispatch.commands.setActive(undefined);
    }
  }, [params.commandId]);

  const emptyTextMessage =
    'Records are searchable data sets. Add a record set by using window.CommandBar.addRecords().';

  const locale = {
    emptyText: emptyTextMessage,
  };

  const filteredRecords = useMemo(() => {
    return records.filter((recordData: ContextDataObject) =>
      recordData.key.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, records]);

  const openRecordSettings = (record: ContextDataObject) => {
    setSelected(record);
  };

  if ((activeCommand.state === 'editing' || activeCommand.state === 'loading') && props.showActiveCommand) {
    return <ActiveCommand isRecordAction={true} />;
  } else {
    return (
      <ScrollContainer>
        <PaddingContainer>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <FeatureAnnouncementCard
              identifier="bar-records"
              title={<Row align="middle">Setting up Records</Row>}
              docsLink="https://www.commandbar.com/docs/bar/data-searchable"
            >
              <span>
                Use records to make data searchable in the Bar. Connect all your data sources to build a universal
                search experience.
              </span>
            </FeatureAnnouncementCard>

            <UpgradeCTA product="commands" />

            <SimplePanel>
              <Row align="middle" style={{ marginBottom: 20 }} gutter={8}>
                <Col flex="1 1 auto">
                  <SearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </Col>
                <Tooltip
                  content={
                    <span>
                      To add a new searchable record, use the{' '}
                      <a
                        href="https://www.commandbar.com/docs/dev/data"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'blue' }}
                      >
                        addRecords SDK method
                      </a>{' '}
                      or use an{' '}
                      <a
                        href="https://app.commandbar.com/integrations/algolia"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'blue' }}
                      >
                        Algolia integration
                      </a>
                      .
                    </span>
                  }
                  placement="top"
                  interactive={true}
                >
                  <Col>
                    <Button
                      type="primary"
                      icon={<PlusCircleOutlined />}
                      disabled
                      id="new-record-button"
                      style={{ width: '36px' }}
                    ></Button>
                  </Col>
                </Tooltip>
              </Row>
              <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                <StyledTable
                  locale={locale}
                  dataSource={filteredRecords}
                  pagination={false}
                  onRow={(record: any, _rowIndex: any) => {
                    return {
                      onClick: (_e: React.MouseEvent) => {
                        openRecordSettings(record);
                      },
                    };
                  }}
                  expandable={{
                    // expand all rows with record actions by default
                    expandedRowKeys: records.map((d) => d.key),
                    expandedRowRender: (data) => (
                      <RecordActionsList data={data as ContextDataObject} contextSettings={contextSettings} />
                    ),
                    expandedRowClassName: () => 'record-actions-container',
                    expandIcon: () => null,
                    columnWidth: 0,
                  }}
                >
                  <Table.Column
                    dataIndex="key"
                    title="Records"
                    key="Title"
                    width="21%"
                    render={(key: string) => (
                      <div style={{ fontWeight: 600, wordWrap: 'break-word', wordBreak: 'break-word' }}>{key}</div>
                    )}
                  />
                  <Table.Column
                    dataIndex="active"
                    title="Active"
                    key="active"
                    render={(active: KeyStatus, record: ContextDataObject) => {
                      return (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <ActiveBadge
                            active={active}
                            inactiveTooltipContent={
                              <span>
                                No values have been provided for this record. Use{' '}
                                <Typography.Text code>window.CommandBar.addRecords</Typography.Text> to add values
                              </span>
                            }
                          />
                          {organization?.integrations?.algolia?.indexes?.hasOwnProperty(record.key) && (
                            <Tooltip
                              key={'right-tooltip-third-party-source'}
                              content={'Record created via Algolia integration'}
                            >
                              <Tag color={'geekblue'}>Algolia</Tag>
                            </Tooltip>
                          )}
                        </div>
                      );
                    }}
                  />
                  <Table.Column
                    dataIndex="settings"
                    title=""
                    key="settings"
                    render={(_settings: any, record: ContextDataObject) => (
                      <SettingOutlined onClick={() => openRecordSettings(record)} />
                    )}
                  />
                </StyledTable>
                <ContextSettingsModal onClose={() => setSelected(undefined)} data={selected} isRecord={true} />
              </div>
            </SimplePanel>
          </div>
        </PaddingContainer>
      </ScrollContainer>
    );
  }
};

const RecordActionsList = (props: { contextSettings: IResourceSettingsByContextKey; data: ContextDataObject }) => {
  const { contextSettings, data } = props;
  const { createLinkedCommand, openExistingLinkedCommand, openNewLinkedCommand } = useLinkedCommand();
  const settings = contextSettings[data.key];
  const defaultCommandId = settings?.default_command_id;
  const { reportEvent } = useReportEvent();

  const statusBadge = (command: IEditorCommandTypeLite) => {
    if (command.is_live) {
      return <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />;
    } else {
      return <StatusBadge color="orange" text="Draft" />;
    }
  };

  return (
    <div>
      <div style={{ marginTop: 5, marginBottom: 5, fontWeight: 600, marginLeft: 5, color: 'hsl(0,0%,45%)' }}>
        Actions
      </div>
      {props.data.commands.map((c) => {
        const isProgrammatic = Command.isProgrammatic(c);
        return (
          <ListItem
            key={c.id}
            text={
              <div style={{ display: 'flex', alignItems: 'center', gap: 12, justifyContent: 'space-between' }}>
                <div>{c.text}</div>
                <div>{statusBadge(c)}</div>
              </div>
            }
            disabled={isProgrammatic}
            tooltipText={
              isProgrammatic ? `This action is set via the sdk and can't be edited in the editor` : undefined
            }
            icon={{
              icon: (
                <Icon
                  style={{
                    color: c.icon_color || 'rgba(0, 0, 0, 0.85)',
                  }}
                  allowDefaultSVGColorOverride
                  useDefaultSVGColor
                  icon={c.icon || commandDefault(c)}
                />
              ),
            }}
            tag={defaultCommandId?.toString() === Command.commandUID(c) ? 'Default' : undefined}
            onClick={() => {
              openExistingLinkedCommand(c);
            }}
          />
        );
      })}
      <Button
        type="text"
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          const newLinkedCommand = createLinkedCommand(data.key);
          openNewLinkedCommand(newLinkedCommand);
          reportEvent('record action added', {
            segment: true,
            highlight: true,
            slack: true,
            payloadMessage: data.key,
          });
        }}
      >
        <PlusCircleOutlined />{' '}
        {isNumber(defaultCommandId) && !!props.data.commands.length
          ? 'Add another record action'
          : 'Add a record action to make this record searchable'}
      </Button>
    </div>
  );
};
