import React, { ReactNode } from 'react';
import { CommandDetailsHeader } from '../../components/CommandDetailsHeader';
import { UpgradeCTA } from '../../components/UpgradeCTA';

interface IProps {
  onGoBack: (() => void) | undefined;
  goBackText?: string;
  actions: ReactNode[];
  content: ReactNode;
  title: ReactNode;
  isRecordAction: boolean;
  showInDefaultList: boolean;
}

const DetailLayout = (props: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: 'white',
        gap: props.showInDefaultList ? 16 : 0,
      }}
    >
      <CommandDetailsHeader
        subTitle={props.isRecordAction ? 'Action details' : 'Command details'}
        title={props.title}
        titlePlaceholder={props.isRecordAction ? 'New action...' : 'New command...'}
        onBackPress={props.onGoBack}
        actions={props.actions}
      />

      {/* if the command is not shown in default list (ie, a record action not shown as a command), then we need to add
      extra padding here, since ordinarily there is no padding above the content for record actions */}
      <UpgradeCTA product="commands" padding={props.showInDefaultList ? '0 16px' : '16px'} />

      <div
        style={{
          display: 'flex',
          flex: 1,
        }}
      >
        {props.content}
      </div>
    </div>
  );
};

export default DetailLayout;
