import { Skeleton } from '../../shared_components';
import React from 'react';
import CommandDetail from './CommmandDetail/CommandDetail';
import DetailLayout from './CommmandDetail/DetailLayout';
import { useAppContext } from '../../Widget';

export const ActiveCommand = (props: { isRecordAction: boolean }) => {
  const { activeCommand, dispatch, organization, unsavedChangesRef } = useAppContext();
  if (activeCommand.state === 'editing') {
    return (
      <CommandDetail
        /* NOTE: `key` is needed so that the state of the Editor (individual tab dirty states, etc.) is reset when Command under edit changes */
        key={activeCommand.command.id}
        command={activeCommand.command}
        organization={organization}
        unsavedChangesRef={unsavedChangesRef}
      />
    );
  } else {
    return (
      <DetailLayout
        actions={[<Skeleton.Button active />]}
        content={<Skeleton active style={{ margin: 16 }} />}
        title={<Skeleton.Input active />}
        onGoBack={() => dispatch.commands.setActive(undefined)}
        isRecordAction={props.isRecordAction}
        showInDefaultList={true} // default to true, will be updated if not once loaded
      ></DetailLayout>
    );
  }
};
