/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { CSSProperties } from 'react';

import {
  IHistoryEventType,
  IEnvReleaseInfo,
  IReleaseStep,
  IRelease,
  ICommandCategoryType,
  ITabType,
  ICommandType,
  IOrganizationType,
  INudgeType,
  IPlaceholderType,
  IChecklist,
} from '@commandbar/internal/middleware/types';
import { Releases as ReleasesAPI } from '@commandbar/internal/middleware/releases';

import dayjs from 'dayjs';
import _ from 'lodash';
import {
  Icon,
  commandDefault,
  Header,
  Button,
  DropdownMenu,
  Empty,
  Form,
  Input,
  Typography,
  Modal,
  Spin,
  Table,
  Alert,
  Result,
  Space,
  Row,
  message,
  Timeline,
  Select as SharedSelect,
  Tag,
} from '../shared_components';
import Sender from '../management/Sender';
import { Link } from 'react-router-dom';
import {
  ArrowRightOutlined,
  DiffOutlined,
  InfoCircleOutlined,
  RollbackOutlined,
  SettingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { useAppContext } from '../Widget';
import { Select } from '../shared_components/Select';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { CB_COLORS } from '@commandbar/design-system/components';
import { useReportEvent } from '../shared_components/useEventReporting';

/*******************************************************************************/
/* Styles
/*******************************************************************************/

const style: { [key: string]: CSSProperties } = {
  diffValue: {
    backgroundColor: '#e6e6ff',
  },
  colStyle: {
    display: 'flex',
  },
  innerColStyle: {
    border: '1px solid',
    borderColor: 'rgba(0,0,0,0.45)',
    minHeight: 225,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 12,
    textAlign: 'center',
    width: '100%',
  },
  envCardContainer: {
    border: '2px solid #EEEEEE',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    height: 120,
    cursor: 'pointer',
    opacity: 0.7,
    transition: 'all 0.3s ease-out',
  },
  envCardContainerActive: {
    opacity: 1.0,
    border: `2px solid ${CB_COLORS.primary}`,
    boxShadow: 'rgba(17, 12, 46, 0.05) 0px 12px 25px 0px',
  },
  envCardHeader: {
    fontSize: 16,
    lineHeight: 1,
    fontWeight: 600,
    padding: '12px 10px',
    textAlign: 'center',
    borderBottom: '2px solid #EEEEEE',
  },
  envCardBody: {
    padding: '12px 10px',
  },
  actionsContainer: {
    minHeight: 190,
    border: '2px solid #EEEEEE',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 20px',
    padding: '15px 25px',
  },
};

/*******************************************************************************/
/* Render
/*******************************************************************************/
const Releases = () => {
  const { organization } = useAppContext();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<{ message: any } | null>(null);

  const [environments, setEnvironments] = React.useState<IEnvReleaseInfo[]>([]);
  const [latestHistoryEvent, setLatestHistoryEvent] = React.useState<IHistoryEventType | null>(null);
  const [latestRelease, setLatestRelease] = React.useState<IRelease | null>(null);

  const [activeEnvSlug, setActiveEnvSlug] = React.useState<string>('latest');

  const refreshReleaseInfo = async () => {
    try {
      setError(null);
      const result = await ReleasesAPI.readView();
      setEnvironments(result.environments);
      setLatestHistoryEvent(result.latest_history_event);
      setLatestRelease(result.latest_release);
    } catch (e) {
      setError({ message: e });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    refreshReleaseInfo();
  }, []);

  if (loading) {
    return (
      <div style={{ height: 400 }}>
        <Spin delay={250} />
      </div>
    );
  }

  if (error) {
    return <Result status="warning" title="Error loading data" subTitle={error.message} />;
  }

  if (!organization) return null;

  if (!organization.releases_enabled) {
    return <DisabledAlert />;
  }

  if (!latestHistoryEvent) {
    return (
      <Empty
        description={
          <span style={{ color: 'rgba(0,0,0,0.6)' }}>
            This tab will allow you to test your changes before releasing them to users. Create a command and come back
            here to release it.
          </span>
        }
      />
    );
  }

  const latestIsUnreleased =
    !latestRelease || latestRelease.history_event?.version_num < latestHistoryEvent.version_num;

  const activeEnv = environments.find((e) => e.environment === activeEnvSlug);
  const isFirstEnvActive = !!activeEnv && activeEnv.environment === environments[0]?.environment;
  const getPrevEnv = (currentEnv: IEnvReleaseInfo): IEnvReleaseInfo | undefined => {
    const prevEnv = environments.find((env) => env.release_step?.next_env === currentEnv.environment);
    return prevEnv;
  };

  return (
    <div>
      <Header text="Environments" />
      <Row justify={'center'}>
        <Space wrap align="center" style={{ justifyContent: 'center' }}>
          <EnvironmentCard
            key="latest"
            envName="Latest"
            lastHistoryEvent={latestHistoryEvent}
            onClick={() => setActiveEnvSlug('latest')}
            isActive={activeEnvSlug === 'latest'}
            isUnreleased={latestIsUnreleased}
          />
          {environments.map((env) => {
            return (
              <EnvironmentCard
                key={env.environment}
                envName={env.environment}
                lastHistoryEvent={env.release_step?.release?.history_event}
                onClick={() => setActiveEnvSlug(env.environment)}
                isActive={activeEnvSlug !== 'latest' && activeEnvSlug === env.environment}
              />
            );
          })}
        </Space>
      </Row>
      <br />
      <br />
      {!activeEnv ? (
        <LatestActions
          refreshReleaseInfo={refreshReleaseInfo}
          latestHistoryEvent={latestHistoryEvent}
          latestRelease={latestRelease}
        />
      ) : (
        <EnvActions
          env={activeEnv}
          prevEnv={getPrevEnv(activeEnv)}
          refreshReleaseInfo={refreshReleaseInfo}
          isFirstEnv={isFirstEnvActive}
          latestRelease={latestRelease}
        />
      )}
      <br />
      {!activeEnv ? (
        <ReleaseHistory latestReleaseVersion={latestRelease?.history_event?.version_num} />
      ) : (
        <EnvHistory
          env={activeEnv.environment}
          currentVersion={activeEnv.release?.history_event.version_num}
          refreshReleaseInfo={refreshReleaseInfo}
        />
      )}
    </div>
  );
};

const DisabledAlert = () => {
  return (
    <Alert
      style={{ marginBottom: 12 }}
      message="Using Releases for change management"
      description={
        <div>
          <p>
            Releases helps your team test the changes they make to the Bar commands and configuration before making
            those changes available to your customers.
          </p>

          <p>
            Turn on releases in the{' '}
            <Link to={editorRoutes.SETTINGS_ROUTE}>
              <SettingOutlined />
              {' Settings'}
            </Link>{' '}
            tab and then{' '}
            <a
              style={{ textDecoration: 'dotted underline' }}
              href="https://www.commandbar.com/docs/versioncontrol/releases"
              target="_blank"
              rel="noopener noreferrer"
            >
              learn how to configure your integration to use Releases.
            </a>
          </p>
        </div>
      }
      type="info"
      showIcon
    />
  );
};

const EnvironmentCard = (props: {
  envName: string;
  isActive: boolean;
  lastHistoryEvent?: IHistoryEventType;
  onClick: () => void;
  isUnreleased?: boolean;
}) => {
  const { envName, isActive, isUnreleased, lastHistoryEvent } = props;

  const getVersion = (historyEvent: IHistoryEventType): string => `v${historyEvent.version_num}`;
  const getLastUpdated = (historyEvent: IHistoryEventType): string => dayjs(historyEvent.created).format('lll');

  return (
    <div
      onClick={props.onClick}
      style={{ ...style.envCardContainer, ...(isActive ? style.envCardContainerActive : {}) }}
    >
      <div style={style.envCardHeader}>{envName}</div>
      <div style={style.envCardBody}>
        {!!lastHistoryEvent ? (
          <Space direction="vertical">
            <Typography.Text type={'secondary'}>
              Version: {getVersion(lastHistoryEvent)} {isUnreleased && '(Unreleased)'}
            </Typography.Text>
            <Typography.Text type={'secondary'}>{getLastUpdated(lastHistoryEvent)}</Typography.Text>
            {/* <Rollback env={envName} loadData={loadData} /> */}
          </Space>
        ) : (
          <Typography.Text type={'secondary'}>Nothing deployed to this environment</Typography.Text>
        )}
      </div>
    </div>
  );
};

const EnvActions = (props: {
  env: IEnvReleaseInfo;
  prevEnv: IEnvReleaseInfo | undefined;
  refreshReleaseInfo: () => Promise<void>;
  latestRelease: IRelease | null;
  isFirstEnv: boolean;
}) => {
  const { env, latestRelease, prevEnv, refreshReleaseInfo, isFirstEnv } = props;
  const { reportEvent } = useReportEvent();

  const fromSource: DiffSource = isFirstEnv
    ? { label: 'Latest', historyEventId: latestRelease?.history_event.id }
    : {
        label: prevEnv?.environment || 'None',
        historyEventId: prevEnv?.release_step?.release.history_event.id,
      };
  const fromReleaseStepId = prevEnv?.release_step?.id;

  const toSource: DiffSource = {
    label: env.environment,
    historyEventId: env.release_step?.release.history_event.id,
  };

  if (!fromSource.historyEventId) {
    return <div>{`The previous environment doesn't have a release associated with it.`}</div>;
  }

  const hasChangesToPrevEnv = fromSource.historyEventId !== toSource.historyEventId;

  const onPromote = async () => {
    if (isFirstEnv && latestRelease) {
      await ReleasesAPI.promoteReleaseToFirstEnv(latestRelease?.id);
    } else if (fromReleaseStepId) {
      await ReleasesAPI.promoteReleaseStep(fromReleaseStepId);
    } else {
      return;
    }

    await refreshReleaseInfo();
    message.success(`Promoted from ${fromSource.label}`);
    Sender.reload(['reloadCommands']);
    reportEvent('release promoted', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: `From ${fromSource.label} to ${toSource.label} (v ${fromSource.historyEventId})`,
    });
  };

  return (
    <div style={style.actionsContainer}>
      <Row justify="space-between" align="middle">
        <h3 style={{ marginBottom: 0 }}>
          Changes: <span style={{ fontWeight: 600 }}>{fromSource.label}</span>{' '}
          <ArrowRightOutlined style={{ marginLeft: 5, marginRight: 5 }} />{' '}
          <span style={{ fontWeight: 600 }}>{toSource.label}</span>
        </h3>
        <Button disabled={!hasChangesToPrevEnv} onClick={() => onPromote()}>
          {hasChangesToPrevEnv ? `Promote from ${fromSource.label}` : `Up to date with ${fromSource.label}`}
        </Button>
      </Row>
      <EnvDiffs from={fromSource} to={toSource} />
    </div>
  );
};

const LatestActions = (props: {
  latestHistoryEvent: IHistoryEventType;
  latestRelease: IRelease | null;
  refreshReleaseInfo: () => Promise<void>;
}) => {
  const { latestHistoryEvent, latestRelease, refreshReleaseInfo } = props;
  const { reportEvent } = useReportEvent();

  const [showModal, setShowModal] = React.useState(false);
  const canCreateNewRelease = latestHistoryEvent.id !== latestRelease?.history_event.id;

  const createReleaseFromLatest = async (notes: string, tags: string[]) => {
    await ReleasesAPI.createRelease(latestHistoryEvent.id, notes, tags);
    await refreshReleaseInfo();
    message.success(`Release created for v${latestHistoryEvent.version_num}`);
    Sender.reload(['reloadCommands']);

    reportEvent('release created', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: `v ${latestHistoryEvent.version_num}`,
    });
  };

  return (
    <div style={style.actionsContainer}>
      <Row justify="space-between" align="middle">
        <h3 style={{ fontWeight: 600, marginBottom: 0 }}>Unreleased changes</h3>
        <Button size="large" disabled={!canCreateNewRelease} onClick={() => setShowModal(true)} type="primary">
          Create release for v{latestHistoryEvent.version_num}
        </Button>{' '}
      </Row>

      {!!latestRelease ? (
        <div>
          <EnvDiffs
            from={{ label: 'Latest', historyEventId: latestHistoryEvent.id }}
            to={{
              label: `v${latestRelease.history_event.version_num}`,
              historyEventId: latestRelease.history_event.id,
            }}
          />
        </div>
      ) : (
        <div>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`No releases created yet.`} />
        </div>
      )}
      <CreateReleaseModal
        closeModal={() => setShowModal(false)}
        onSubmit={createReleaseFromLatest}
        visible={showModal}
      />
    </div>
  );
};

const EnvHistory = (props: { env: string; refreshReleaseInfo: () => void; currentVersion?: number }) => {
  const { currentVersion, env } = props;
  const [releaseSteps, setReleaseSteps] = React.useState<IReleaseStep[] | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const { reportEvent } = useReportEvent();

  React.useEffect(() => {
    let cancelled = false;

    async function fetchData() {
      if (!env) {
        setReleaseSteps(null);
        return;
      }
      const result = await ReleasesAPI.readReleaseStepsForEnv(env);
      if (!cancelled) setReleaseSteps(result);
    }

    fetchData();

    return () => {
      cancelled = true;
    };
  }, [env, currentVersion]);

  const currentStep = releaseSteps?.find((r) => r.current);

  const formatDate = (date: string) => dayjs(date).format('lll');
  const columns = [
    {
      title: 'Version',
      dataIndex: ['release', 'history_event', 'version_num'],
      // eslint-disable-next-line react/display-name
      render: (version: number, releaseStep: IReleaseStep) => (
        <span>
          <Space direction="horizontal">
            {`v${version}`} {releaseStep.current && <Tag>Current</Tag>}
          </Space>
        </span>
      ),
    },
    {
      title: 'Notes',
      dataIndex: ['release', 'notes'],
      // eslint-disable-next-line react/display-name
      render: (notes: string) => (
        <div>
          <Typography.Paragraph
            ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
            }}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
          >
            {notes}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: 'Promoted on',
      dataIndex: 'created',
      render: (created: string) => formatDate(created),
    },
    {
      title: 'Promoted by',
      dataIndex: 'created_by',
      render: (created_by: string | null) => created_by ?? '',
    },
    {
      title: '',
      dataIndex: 'options',
      // eslint-disable-next-line react/display-name
      render: (_o: any, releaseStep: IReleaseStep) => (
        <DropdownMenu
          keyName="actions"
          items={[
            {
              name: 'Rollback',
              icon: <RollbackOutlined />,
              disabled: currentStep?.id === releaseStep.id,
              onClick: () => {
                Modal.confirm({
                  icon: <WarningOutlined />,
                  title: `Are you sure you'd like to rollback ${props.env} to ${releaseStep.release.history_event.version_num}?`,
                  // content: <span>Performing this action will delete {selectedRows.length} existing commands.</span>,
                  onOk() {
                    onRollback(releaseStep.id);
                    reportEvent('release rolled back', {
                      segment: true,
                      highlight: true,
                      slack: true,
                      payloadMessage: `${props.env} from v ${currentVersion} to v ${releaseStep.release.history_event.version_num}`,
                    });
                  },
                });
              },
            },
            {
              name: 'View details',
              icon: <InfoCircleOutlined />,
              onClick: () => {
                showReleaseDetails(releaseStep.release, releaseStep);
              },
            },
          ]}
        />
      ),
    },
  ];

  let content;
  if (!releaseSteps) {
    content = <Spin />;
  } else if (releaseSteps.length === 0) {
    content = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No previous releases" />;
  } else {
    content = (
      <Table
        style={{ cursor: 'pointer' }}
        onRow={(releaseStep, _rowIndex) => {
          return {
            onClick: (_event) => {
              showReleaseDetails(releaseStep.release, releaseStep);
            },
          };
        }}
        rowKey={'id'}
        pagination={{
          pageSize: 8,
          hideOnSinglePage: true,
        }}
        columns={columns}
        dataSource={releaseSteps}
      />
    );
  }

  const onRollback = async (selectedReleaseStepId: number) => {
    if (selectedReleaseStepId !== null) {
      try {
        await ReleasesAPI.rollback(selectedReleaseStepId);
        await props.refreshReleaseInfo();
        Sender.reload(['reloadCommands']);
      } catch (e) {
        setError('Encountered an error, please try again');
      }
    }
  };

  return (
    <div>
      <Header text={`${props.env} release history`} />
      {content}
      {error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

const ReleaseHistory = (props: { latestReleaseVersion?: number }) => {
  const [releases, setReleases] = React.useState<IRelease[] | null>(null);
  const [diffModalVisible, setDiffModalVisible] = React.useState<boolean>(false);
  const [activeRelease, setActiveRelease] = React.useState<IRelease | undefined>(undefined);
  const [error, _setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    let cancelled = false;

    async function fetchData() {
      const result = await ReleasesAPI.listReleases();
      if (!cancelled) setReleases(result);
    }

    fetchData();

    return () => {
      cancelled = true;
    };
  }, [props.latestReleaseVersion]);

  const formatDate = (date: string) => dayjs(date).format('lll');

  const columns = [
    {
      title: 'Version',
      dataIndex: ['history_event', 'version_num'],
      // eslint-disable-next-line react/display-name
      render: (version: number) => (
        <span>
          <Space direction="horizontal">{`v${version}`}</Space>
        </span>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      // eslint-disable-next-line react/display-name
      render: (notes: string) => (
        <div>
          <Typography.Paragraph
            ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
            }}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word', marginBottom: 0 }}
          >
            {notes}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      // eslint-disable-next-line react/display-name
      render: (tags: string[]) => (
        <div>
          {tags.map((t) => (
            <Tag key={t}>{t}</Tag>
          ))}
        </div>
      ),
    },
    {
      title: 'Released on',
      dataIndex: 'created',
      render: (created: string) => formatDate(created),
    },
    {
      title: 'Released by',
      dataIndex: 'created_by',
      render: (created_by: string | null) => created_by ?? '',
    },
    {
      title: '',
      dataIndex: 'options',
      // eslint-disable-next-line react/display-name
      render: (_o: any, release: IRelease) => (
        <DropdownMenu
          keyName="actions"
          items={[
            {
              name: 'View details',
              icon: <InfoCircleOutlined />,
              onClick: () => {
                showReleaseDetails(release);
              },
            },
            {
              name: 'Compare',
              icon: <DiffOutlined />,
              onClick: () => {
                setDiffModalVisible(true);
                setActiveRelease(release);
              },
            },
          ]}
        />
      ),
    },
  ];

  let content;
  if (!releases) {
    content = <Spin />;
  } else if (releases.length === 0) {
    content = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No releases" />;
  } else {
    content = (
      <>
        <Table
          style={{ cursor: 'pointer' }}
          rowKey={'id'}
          onRow={(release) => {
            return {
              onClick: () => {
                showReleaseDetails(release);
              },
            };
          }}
          pagination={{
            pageSize: 8,
            hideOnSinglePage: true,
          }}
          columns={columns}
          dataSource={releases}
        />
        <Modal
          title={'Compare releases'}
          visible={diffModalVisible}
          onCancel={() => {
            setDiffModalVisible(false);
            setActiveRelease(undefined);
          }}
          cancelText="Close"
          okButtonProps={{ style: { display: 'none' } }}
        >
          <ReleaseDiffs releases={releases} defaultFrom={activeRelease} />
        </Modal>
      </>
    );
  }

  return (
    <div>
      <Header text={`Release history`} />
      {content}
      {error && <Typography.Text type="danger">{error}</Typography.Text>}
    </div>
  );
};

const ReleaseDiffs = (props: { releases: IRelease[]; defaultFrom?: IRelease }) => {
  const { defaultFrom, releases } = props;
  const [from, setFrom] = React.useState<IRelease | null>(() => defaultFrom || releases[0] || null);
  const [to, setTo] = React.useState<IRelease | null>(() => releases[0] || null);

  React.useEffect(() => setFrom(defaultFrom || releases[0] || null), [defaultFrom, releases]);

  if (releases.length === 0) return null;

  const convertReleaseToOption = (release: IRelease) => {
    return {
      label: `Release v${release.history_event.version_num}`,
      value: release.history_event.id,
      release: release,
    };
  };

  return (
    <div>
      <Row align="middle" justify="center">
        <div style={{ width: 125 }}>
          <Select
            autoSize={true}
            value={from ? convertReleaseToOption(from) : null}
            options={props.releases.map((r) => convertReleaseToOption(r))}
            onChange={(e) => !!e && setFrom(e.release)}
          />
        </div>
        <ArrowRightOutlined style={{ marginLeft: 20, marginRight: 20 }} />
        <div style={{ width: 125 }}>
          <Select
            autoSize={true}
            value={to ? convertReleaseToOption(to) : null}
            options={props.releases.map((r) => convertReleaseToOption(r))}
            onChange={(e) => !!e && setTo(e.release)}
          />
        </div>
      </Row>
      <br />
      <div>
        <EnvDiffs
          from={{ label: `v${from?.history_event.id}`, historyEventId: from?.history_event.id }}
          to={{ label: `v${to?.history_event.id}`, historyEventId: to?.history_event.id }}
        />
      </div>
    </div>
  );
};

// diff is :
// { [object id]: added | removed | changes }
// added: { added: true, value: <new value> }
// removed: { removed: true, value: <old value> }
// changes: [{ path, item, op, from, to }]
//

type DiffOpChanged<T> = { path: string[]; item: string; op: 'changed'; from: T; to: T };
type DiffOpAdded<T> = { path: string[]; item: string; op: 'added'; from: null; to: T };
type DiffOpRemoved<T> = { path: string[]; item: string; op: 'added'; from: T; to: null };

type Diff<T> = {
  [objectId: string | number]: {
    added?: { added: true; value: T };
    removed?: { removed: true; value: T };
    changes?: (DiffOpAdded<T> | DiffOpChanged<T> | DiffOpRemoved<T>)[];
    value: T;
  };
};
type DiffSource = {
  label: string;
  historyEventId?: number;
  releaseStepId?: number;
};

type HistoryEventDiff = {
  categories: Diff<ICommandCategoryType>;
  tabs: Diff<ITabType>;
  commands: Diff<ICommandType>;
  organization: Diff<IOrganizationType>;
  resource_options?: Diff<IOrganizationType['resource_options']>;
  nudges?: Diff<INudgeType>;
  checklists?: Diff<IChecklist>;
  placeholders?: Diff<IPlaceholderType>;
};

const EnvDiffs = ({ from, to }: { from: DiffSource; to: DiffSource }) => {
  const [diff, setDiff] = React.useState<HistoryEventDiff | null>(null);
  const [errorLoading, setErrorLoading] = React.useState<boolean>(false);

  const transformHistoryEventId = (id: number) => {
    return `history_event.${id}`;
  };

  React.useEffect(() => {
    let cancelled = false;
    async function fetchData() {
      if (!from.historyEventId || !to.historyEventId) {
        setDiff(null);
        return;
      }
      try {
        setErrorLoading(false);
        const result = await ReleasesAPI.readDiff(
          transformHistoryEventId(from.historyEventId),
          transformHistoryEventId(to.historyEventId),
        );
        if (!cancelled) setDiff(result as HistoryEventDiff);
      } catch (e) {
        if (!cancelled) setErrorLoading(true);
      }
    }

    fetchData();
    return () => {
      cancelled = true;
    };
  }, [from, to]);

  if (!from.historyEventId || !to.historyEventId) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={`Nothing deployed to ${to.label}. Click "Promote" to deploy.`}
      />
    );
  } else if (errorLoading) {
    return <Typography.Text type="danger">Error loading diff</Typography.Text>;
  } else if (!diff) {
    return (
      <Row justify="center" align="middle">
        <Spin />
      </Row>
    );
  } else if (!Object.values(diff).some((x) => Object.keys(x).length > 0)) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`No changes between ${from.label} and ${to.label}`} />
    );
  }

  return (
    <>
      <ViewDiff
        diff={diff.categories}
        entity="category"
        renderEntity={(category: any) => <Typography.Text>{category.name}</Typography.Text>}
        descriptionField="name"
      />

      {diff.tabs && (
        <ViewDiff
          diff={diff.tabs}
          entity="tab"
          renderEntity={(tab: any) => <Typography.Text>{tab.label}</Typography.Text>}
          descriptionField="label"
        />
      )}

      <ViewDiff
        diff={diff.commands}
        entity="command"
        renderEntity={(command: any) => (
          <>
            <Icon icon={command.icon || commandDefault(command)} style={{ fontSize: 12 }} />
            <Typography.Text style={{ marginLeft: '5px' }}>{command.text}</Typography.Text>
          </>
        )}
        descriptionField="title"
      />

      <ViewDiff
        diff={diff.organization}
        entity="organization"
        renderEntity={(o: any) => <Typography.Text>{o.name}</Typography.Text>}
        descriptionField="name"
      />

      {diff.resource_options && (
        <ViewDiff
          diff={diff.resource_options}
          entity="record"
          renderEntity={(_: any, contextKey: string) => <Typography.Text>{contextKey}</Typography.Text>}
          descriptionField="name"
        />
      )}

      {diff.nudges && (
        <ViewDiff
          diff={diff.nudges}
          entity="nudge"
          renderEntity={(nudge: any) => <Typography.Text>{nudge.title}</Typography.Text>}
          descriptionField="title"
        />
      )}

      {diff.checklists && (
        <ViewDiff
          diff={diff.checklists}
          entity="checklist"
          renderEntity={(checklist: any) => <Typography.Text>{checklist.title}</Typography.Text>}
          descriptionField="title"
        />
      )}

      {diff.placeholders && (
        <ViewDiff
          diff={diff.placeholders}
          entity="placeholder"
          renderEntity={(placeholder: any) => <Typography.Text>{placeholder.text}</Typography.Text>}
          descriptionField="text"
        />
      )}
    </>
  );
};

const ViewDiff = <T,>({
  diff,
  entity,
  renderEntity,
  descriptionField,
}: {
  diff: Diff<T>;
  renderEntity: any;
  entity: string;
  descriptionField: string;
}) => {
  return (
    <ul>
      {Object.entries(diff).map(([objectId, diff]) => {
        const children = [
          diff.added && (
            <React.Fragment key="added">
              Added {entity} {renderEntity(diff.value, objectId)}
            </React.Fragment>
          ),
          diff.removed && (
            <React.Fragment key="removed">
              Removed {entity} {renderEntity(diff.value, objectId)}
            </React.Fragment>
          ),
          diff.changes && diff.changes.length > 0 && (
            <React.Fragment key="changes">
              Changed {entity} {renderEntity(diff.value, objectId)}
              <Changes changes={diff.changes} descriptionField={descriptionField} />
            </React.Fragment>
          ),
        ].filter(Boolean);

        if (children.length === 0) return null;

        return <li key={objectId}>{children}</li>;
      })}
    </ul>
  );
};

const Changes = ({ changes, descriptionField }: { changes: any[]; descriptionField: string }) => {
  const [first, rest] = _.partition(changes, (change) => change.item === descriptionField);
  const _changes = [...first, ..._.sortBy(rest, 'item')];
  return (
    <ul>
      {_changes.map((change: any, idx: number) => (
        <Change change={change} key={idx} />
      ))}
    </ul>
  );
};

const Change = ({ change }: { change: any }) => {
  const path = [change.item, ...change.path].join('.');
  if (change.op === 'changed') {
    return (
      <li>
        Changed {path} from <Value item={change.item} value={change.from} /> to{' '}
        <Value item={change.item} value={change.to} />{' '}
      </li>
    );
  } else if (change.op === 'added') {
    return (
      <li>
        Added {path} <Value item={change.item} value={change.to} />
      </li>
    );
  } else if (change.op === 'removed') {
    return (
      <li>
        Removed {path} <Value item={change.item} value={change.from} strikethru />
      </li>
    );
  }

  return null;
};

const TypographyText = styled(Typography.Text)`
  code {
    white-space: pre-line;
  }
`;
const Value = ({ item, value, strikethru = false }: { item: any; value: any; strikethru?: boolean }) => {
  if (['boolean', 'object', 'undefined'].includes(typeof value) || value === '') {
    return (
      <TypographyText code delete={strikethru} style={style.diffValue}>
        {JSON.stringify(value, null, 2)}
      </TypographyText>
    );
  }

  if (item === 'icon') {
    return <Icon icon={value} style={{ fontSize: 12 }} />;
  }

  return (
    <TypographyText delete={strikethru} style={style.diffValue}>
      {value}
    </TypographyText>
  );
};

const CreateReleaseModal = (props: {
  visible: boolean;
  closeModal: () => void;
  onSubmit: (notes: string, tags: string[]) => void;
}) => {
  const { closeModal, onSubmit, visible } = props;
  const [form] = Form.useForm();

  const onCreate = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onSubmit(values.notes, values.tags);
        closeModal();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onCancel = () => {
    form.resetFields();
    closeModal();
  };

  const footer = [
    <Button key="close" onClick={onCancel}>
      Cancel
    </Button>,
    <Button key="ok" type="primary" onClick={onCreate}>
      Create
    </Button>,
  ];

  return (
    <Modal title="Create a new release" visible={visible} onCancel={onCancel} okText={'Create'} footer={footer}>
      <Form
        name="on"
        form={form}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ tags: [], notes: '' }}
        autoComplete="off"
      >
        <Form.Item label="Notes" name="notes">
          <Input.TextArea placeholder="What changes are in this release?" />
        </Form.Item>

        <Form.Item label="Tags" shouldUpdate={(prevValues, curValues) => prevValues.tags !== curValues.tags}>
          {() => {
            const tags = form.getFieldValue('tags');
            const suggestions = ['Feature', 'Fix', 'Chore', 'Style'].filter((t) => !tags.includes(t));
            return (
              <Form.Item name="tags">
                <SharedSelect
                  mode="tags"
                  value={tags}
                  placeholder="Select from below or type and press enter for a custom tag"
                  dropdownStyle={{ display: 'none' }}
                  onChange={(t) => form.setFieldsValue({ tags: t })}
                />
                <div style={{ marginTop: 10, marginLeft: 5 }}>
                  {suggestions.length > 0 && (
                    <span
                      style={{
                        opacity: 0.6,
                        marginRight: 5,
                        fontSize: 10,
                      }}
                    >
                      Suggestions:
                    </span>
                  )}
                  {suggestions.map((t) => (
                    <Tag
                      key={t}
                      style={{ cursor: 'pointer', fontSize: 10 }}
                      onClick={() => form.setFieldsValue({ tags: [...tags, t] })}
                    >
                      {t}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const showReleaseDetails = (release: IRelease, releaseStep?: IReleaseStep) => {
  const tags = release.tags.map((t) => <Tag key={t}>{t}</Tag>);
  Modal.info({
    maskClosable: true,
    okText: 'Close',
    icon: <InfoCircleOutlined />,
    title: (
      <div>
        {`v${release.history_event.version_num} details`}&nbsp;&nbsp;{tags}
      </div>
    ),
    content: <ReleaseDetails release={release} releaseStep={releaseStep} />,
  });
};

const ReleaseDetails = (props: { release: IRelease; releaseStep?: IReleaseStep }) => {
  const { release, releaseStep } = props;
  const formatDate = (date: string) => dayjs(date).format('lll');

  return (
    <div>
      {release.notes.length > 0 && (
        <div>
          <Header text="Notes" />
          <div>{release.notes}</div>
        </div>
      )}
      <br />
      <Header text="Timeline" />
      <Timeline>
        <Timeline.Item>Latest change made {formatDate(release.history_event.created)}</Timeline.Item>
        <Timeline.Item>
          Released on {formatDate(release.created)} by {release.created_by ?? '<unknown>'}
        </Timeline.Item>
        {releaseStep && (
          <Timeline.Item>
            Promoted to {releaseStep.env} on {formatDate(releaseStep.created)} by{' '}
            {releaseStep.created_by ?? '<unknown>'}
          </Timeline.Item>
        )}
      </Timeline>
    </div>
  );
};
export default Releases;
