import React from 'react';
import styled from '@emotion/styled';

import { Input, Select } from '../../shared_components';
import RuleExpressionEditor from '../commands/CommmandDetail/ConditionRulePanels/components/RuleExpressionEditor';
import * as Command from '@commandbar/internal/middleware/command';
import { EventAutoComplete } from '../commands/CommmandDetail/EventAutoComplete';
import { CB_COLORS, Tooltip } from '@commandbar/design-system/components';
import { ReactComponent as CaretUp } from '../../img/caret_up.svg';
import { ReactComponent as CaretDown } from '../../img/caret_down.svg';

import {
  ExpressionCondition,
  getConditions,
  INamedRule,
  RuleExpression,
} from '@commandbar/internal/middleware/helpers/rules';
import type { IChecklist, INudgeType, IAudienceType } from '@commandbar/internal/middleware/types';
import {
  ClickRecorderContainer,
  Header,
  InputContainer,
  MediaBlockStyledInput,
  PaddingContainerSM,
  StyledCollapse,
  StyledHeader,
  StyledLabel,
  StyledPanel,
  Subheader,
} from '../../shared_components/form';
import { TypographyCode } from '../nudges/styled';
import { AvailabilityDependency } from '../commands/CommmandDetail/ConditionRulePanels/components/AvailabilityDependency';
import { useAppContext } from '../../Widget';
import { ClickRecorder, isSelectorFragile, SELECTOR_WARNING_TEXT } from '../commands/CommmandDetail/ActionDetailPanel';
import { WarningOutlined } from '@ant-design/icons';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

const TargetingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BorderedContainer = styled.div`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${CB_COLORS.neutral0};
  border-radius: 4px;
`;

const StyledEventAutoComplete = styled.div`
  width: 100%;
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;

  & .ant-select-selector {
    border: none !important;
  }

  & .ant-select {
    height: 32px !important;
  }

  & .ant-select-selector {
    height: 32px !important;
  }

  & input {
    height: 32px !important;
  }
`;

const StyledSelect = styled(Select)`
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;

  & .ant-select-selector {
    border: none !important;
  }

  & .ant-select-selection-placeholder {
    position: relative;
    top: 1px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
`;

enum Panel {
  AUDIENCE,
  PAGE_TARGETING,
  TRIGGER,
}

type TriggerableEntity = INudgeType | IChecklist;

function isNudge(triggerable: TriggerableEntity): triggerable is INudgeType {
  return (triggerable as INudgeType).frequency_limit !== undefined;
}

type Props<T> = {
  dirty: T;
  onChange: (changes: Partial<T>) => void;
  onResetPreview: (changes: Partial<T>) => void;
};

type AudienceSelectOption = { special: 'all' } | { special: 'custom' } | { rule_id: string };
export const AudienceSelect = ({
  audience,
  rules,

  disabled = false,

  onChange,
}: {
  audience: IAudienceType;
  rules: INamedRule[];

  disabled?: boolean;

  onChange: (audience: IAudienceType) => void;
}) => {
  const audienceSelectValue = JSON.stringify(
    (() => {
      if (audience.type === 'all_users') return { special: 'all' };
      if (audience.type === 'rule_expression') return { special: 'custom' };
      return { rule_id: audience.rule_reference.rule_id };
    })(),
  );

  const audiences = [...rules.filter((rule) => rule.is_audience)].sort((a, b) => a.name.localeCompare(b.name));
  return (
    <>
      <StyledSelect
        disabled={disabled}
        suffixIcon={<CaretDown />}
        showSearch
        optionLabelProp="label"
        filterOption={(input, option) => {
          if (!!option?.label) {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }
          return false;
        }}
        value={audienceSelectValue}
        onChange={(newValue) => {
          const option: AudienceSelectOption = JSON.parse(newValue as string);

          if ('special' in option) {
            if (option.special === 'all') {
              return onChange({ type: 'all_users' });
            } else if (option.special === 'custom') {
              return onChange({ type: 'rule_expression', expression: { type: 'AND', exprs: [] } });
            }
          } else {
            onChange({
              type: 'named_rule_reference',
              rule_reference: {
                type: 'named_rule',
                rule_id: option.rule_id,
              },
            });
          }
        }}
        dropdownClassName="commandbar-select-dropdown"
        style={{ maxWidth: '100%', width: '100%' }}
        dropdownMatchSelectWidth={false}
      >
        <Select.Option label="All Users" value={JSON.stringify({ special: 'all' })}>
          All Users
        </Select.Option>
        {audiences.length > 0 && (
          <Select.OptGroup label={'Audiences'}>
            {audiences.map((rule) => (
              <Select.Option label={rule.name} key={rule.id} value={JSON.stringify({ rule_id: rule.id })}>
                {rule.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}

        <Select.Option label={'Custom'} value={JSON.stringify({ special: 'custom' })}>
          Custom...
        </Select.Option>
      </StyledSelect>
      {audience.type === 'rule_expression' && (
        <InputContainer style={{ marginTop: 8 }}>
          <StyledLabel>Conditions</StyledLabel>
          <BorderedContainer>
            <RuleExpressionEditor
              disabled={disabled}
              onChange={(_expr) => {
                onChange({ type: 'rule_expression', expression: _expr });
              }}
              expr={audience.expression}
              omitNamedRuleType
              categories={['Activity', 'Custom', 'Integrations', 'Interactions', 'Default']}
            />
          </BorderedContainer>
        </InputContainer>
      )}
    </>
  );
};

const Targeting = <T extends TriggerableEntity>({ dirty, onChange, onResetPreview }: Props<T>) => {
  const { rules, commands, nudges, checklists, organization } = useAppContext();
  const [activeKeys, setActiveKeys] = React.useState<Array<Panel>>([Panel.AUDIENCE]);

  const hasPinStep = isNudge(dirty) && dirty.steps.some(({ form_factor }) => form_factor.type === 'pin');

  const verboseAudience = (audience: T['audience']) => {
    switch (audience?.type) {
      case 'all_users':
        return 'All users';
      case 'rule_expression':
        return 'Custom audience';
      case 'named_rule_reference':
        return rules.find((rule) => rule.id === audience.rule_reference.rule_id)?.name || 'Custom rule';
      default:
        break;
    }

    return '';
  };

  const verboseTrigger = (trigger: T['trigger']) => {
    switch (trigger.type) {
      case 'when_conditions_pass':
        return 'Immediately';
      case 'when_element_appears':
        return `${trigger.meta.selector} appears`;
      case 'on_command_execution':
        const command = commands.find((command) => String(command.id) === trigger.meta.command);
        return `On command executed: ${command?.text || 'Not set'}`;
      case 'on_event':
        return `On event tracked: ${trigger.meta.event || 'Not set'}`;
      case 'when_page_reached':
        return 'When a user visits a specific page';
      case 'when_share_link_viewed':
        return 'When Share Link is viewed';
      default:
        break;
    }

    return '';
  };

  const verboseLimit = (trigger: INudgeType['frequency_limit']) => {
    switch (trigger) {
      case 'no_limit':
        return '& without limit';
      case 'once_per_session':
        return '& once per session';
      case 'once_per_user':
        return '& once per user';
      case 'until_interaction':
        return '& until completed or dismissed';
      default:
        break;
    }

    return '';
  };

  const getFirstCondition = (expr: RuleExpression): ExpressionCondition => getConditions(expr)?.[0];

  const verbosePageTargeting = (show_expression: T['show_expression']) => {
    const condition = getFirstCondition(show_expression);

    if (!condition || (show_expression.type === 'LITERAL' && show_expression.value === true)) {
      if (!hasPinStep) return 'All pages';
      return 'On all pages with pin targets';
    }

    if (condition.type === 'url') {
      return `URL ${condition.operator} ${condition.value}`;
    } else if (condition.type === 'element') {
      let value = condition.value;
      if (
        condition.value &&
        (condition.operator === 'selectorOnPage' || condition.operator === 'selectorNotOnPage') &&
        condition.value.length > 50
      ) {
        value = condition.value?.substring(0, 50) + '...';
      }
      return `DOM Element ${condition.operator} ${value}`;
    }

    if (hasPinStep) return 'Custom page targeting with required pin targets';
    return 'Custom page targeting';
  };

  const pageTargetingSelectValue = dirty.show_expression.type === 'LITERAL' && !hasPinStep ? 'all_pages' : 'custom';
  const showWhenLimit = dirty.trigger.type !== 'when_share_link_viewed';

  const elementTriggerFlag = LocalStorage.get('can_set_element_trigger', false);
  const canSetElementTrigger = organization.id === 'ac965263' || !!elementTriggerFlag;

  return (
    <TargetingContainer>
      <StyledCollapse
        onChange={() => {
          if (activeKeys.includes(Panel.AUDIENCE)) {
            setActiveKeys(activeKeys.filter((key) => key !== Panel.AUDIENCE));
          } else {
            setActiveKeys([...activeKeys, Panel.AUDIENCE]);
          }
        }}
        activeKey={activeKeys}
        expandIcon={() => null}
      >
        <StyledPanel
          key={Panel.AUDIENCE}
          header={
            <StyledHeader>
              <Header>
                <span>Who</span>
                {activeKeys.includes(Panel.AUDIENCE) ? (
                  <CaretUp style={{ color: CB_COLORS.neutral600 }} />
                ) : (
                  <CaretDown style={{ color: CB_COLORS.neutral600 }} />
                )}
              </Header>
              {!activeKeys.includes(Panel.AUDIENCE) && <Subheader>{verboseAudience(dirty.audience)}</Subheader>}
            </StyledHeader>
          }
        >
          <PaddingContainerSM>
            {!!dirty.audience ? (
              <>
                <InputContainer>
                  <StyledLabel>Audience</StyledLabel>
                  <AudienceSelect
                    rules={rules}
                    audience={dirty.audience}
                    onChange={(audience) => {
                      onChange({ ...dirty, audience });
                      onResetPreview({ ...dirty, audience });
                    }}
                  />
                </InputContainer>
              </>
            ) : (
              <InputContainer>
                {/* legacy -- remove when all triggerables have 'audience' defined */}
                <StyledLabel>Conditions</StyledLabel>
                <BorderedContainer>
                  {hasPinStep &&
                    dirty.steps
                      .filter(({ form_factor }) => form_factor.type === 'pin')
                      .map(({ form_factor }, i) =>
                        // check again to remove type error. TS doesn't know filter above removes the other types
                        form_factor.type === 'pin' ? (
                          <AvailabilityDependency
                            key={i}
                            type="element"
                            field={form_factor.anchor}
                            operator="exists on the page"
                            inputStyle={{ height: '24px' }}
                            message={
                              <span>
                                Element {<TypographyCode code>{form_factor.anchor}</TypographyCode>} must be present on
                                the DOM for the command to be available.
                              </span>
                            }
                          />
                        ) : null,
                      )}
                  <RuleExpressionEditor
                    onChange={(expr) => onChange({ ...dirty, show_expression: expr })}
                    expr={dirty.show_expression}
                  />
                </BorderedContainer>
              </InputContainer>
            )}
          </PaddingContainerSM>
        </StyledPanel>
      </StyledCollapse>
      {!!dirty.audience && (
        <StyledCollapse
          onChange={() => {
            if (activeKeys.includes(Panel.PAGE_TARGETING)) {
              setActiveKeys(activeKeys.filter((key) => key !== Panel.PAGE_TARGETING));
            } else {
              setActiveKeys([...activeKeys, Panel.PAGE_TARGETING]);
            }
          }}
          activeKey={activeKeys}
          expandIcon={() => null}
        >
          <StyledPanel
            key={Panel.PAGE_TARGETING}
            header={
              <StyledHeader>
                <Header>
                  <span>Where</span>
                  {activeKeys.includes(Panel.PAGE_TARGETING) ? (
                    <CaretUp style={{ color: CB_COLORS.neutral600 }} />
                  ) : (
                    <CaretDown style={{ color: CB_COLORS.neutral600 }} />
                  )}
                </Header>
                {!activeKeys.includes(Panel.PAGE_TARGETING) && (
                  <Subheader>{verbosePageTargeting(dirty.show_expression)}</Subheader>
                )}
              </StyledHeader>
            }
          >
            <PaddingContainerSM>
              <InputContainer>
                {/* legacy -- remove when all triggerables have 'audience' defined */}
                <StyledLabel>Show</StyledLabel>
                <StyledSelect
                  value={pageTargetingSelectValue}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    if (typeof e === 'string') {
                      if (e === 'custom') {
                        const changes = {
                          ...dirty,
                          show_expression: {
                            type: 'AND',
                            exprs: [{ type: 'CONDITION', condition: { type: 'url', operator: 'includes' } }],
                          },
                        };
                        onChange(changes);
                        onResetPreview(changes);
                      } else if (e === 'all_pages') {
                        const changes = { ...dirty, show_expression: { type: 'LITERAL', value: true } };
                        onChange(changes);
                        onResetPreview(changes);
                      }
                    }
                  }}
                  suffixIcon={<CaretDown />}
                >
                  <Select.Option
                    disabled={hasPinStep}
                    title={hasPinStep ? 'Pin steps require their targets to be present on the page' : 'On all pages'}
                    value={'all_pages'}
                  >
                    On all pages
                  </Select.Option>
                  <Select.Option value={'custom'}>If...</Select.Option>
                </StyledSelect>
                {pageTargetingSelectValue === 'custom' && (
                  <BorderedContainer>
                    <RuleExpressionEditor
                      onChange={(expr) => onChange({ ...dirty, show_expression: expr })}
                      expr={dirty.show_expression}
                      categories={['Page']}
                      omitNamedRuleType
                    />
                    {hasPinStep &&
                      dirty.steps
                        .filter(({ form_factor }) => form_factor.type === 'pin')
                        .map(({ form_factor }, i) =>
                          form_factor.type === 'pin' ? (
                            <AvailabilityDependency
                              key={i}
                              type="element"
                              field={form_factor.anchor}
                              operator="exists on the page"
                              inputStyle={{ height: '24px' }}
                              message={<span>Nudge will be shown if the selected target is present on the page.</span>}
                            />
                          ) : null,
                        )}
                  </BorderedContainer>
                )}
              </InputContainer>
            </PaddingContainerSM>
          </StyledPanel>
        </StyledCollapse>
      )}
      <StyledCollapse
        onChange={() => {
          if (activeKeys.includes(Panel.TRIGGER)) {
            setActiveKeys(activeKeys.filter((key) => key !== Panel.TRIGGER));
          } else {
            setActiveKeys([...activeKeys, Panel.TRIGGER]);
          }
        }}
        activeKey={activeKeys}
        expandIcon={() => null}
      >
        <StyledPanel
          key={Panel.TRIGGER}
          header={
            <StyledHeader>
              <Header>
                <span>When</span>
                {activeKeys.includes(Panel.TRIGGER) ? (
                  <CaretUp style={{ color: CB_COLORS.neutral600 }} />
                ) : (
                  <CaretDown style={{ color: CB_COLORS.neutral600 }} />
                )}
              </Header>

              {!activeKeys.includes(Panel.TRIGGER) && (
                <Subheader>
                  {verboseTrigger(dirty.trigger)}{' '}
                  {isNudge(dirty) &&
                    dirty.trigger.type !== 'when_share_link_viewed' &&
                    verboseLimit(dirty.frequency_limit)}
                </Subheader>
              )}
            </StyledHeader>
          }
        >
          <PaddingContainerSM>
            <InputContainer>
              <StyledLabel>Show</StyledLabel>
              <StyledSelect
                value={dirty.trigger.type}
                style={{ width: '100%' }}
                onChange={(e) => {
                  if (e === 'on_command_execution') {
                    const changes = { ...dirty, trigger: { type: e, meta: { command: '' } } };
                    onChange(changes);
                    onResetPreview(changes);
                  } else if (e === 'when_conditions_pass') {
                    const changes = { ...dirty, trigger: { type: e } };
                    onChange(changes);
                    onResetPreview(changes);
                  } else if (e === 'when_element_appears') {
                    let changes: typeof dirty;
                    if (isNudge(dirty)) {
                      changes = {
                        ...dirty,
                        frequency_limit:
                          dirty.frequency_limit === 'no_limit' ? 'until_interaction' : dirty.frequency_limit,
                        trigger: { type: e, meta: { selector: '' } },
                      };
                    } else {
                      changes = { ...dirty, trigger: { type: e, meta: { selector: '' } } };
                    }

                    onChange(changes);
                    onResetPreview(changes);
                  } else if (e === 'on_event') {
                    const changes = { ...dirty, trigger: { type: e, meta: { event: '' } } };
                    onChange(changes);
                    onResetPreview(changes);
                  } else if (e === 'when_page_reached') {
                    const changes = { ...dirty, trigger: { type: e, meta: { url: '' } } };
                    onChange(changes);
                    onResetPreview(changes);
                  } else if (e === 'when_share_link_viewed') {
                    const changes = { ...dirty, trigger: { type: e, meta: { url: '' } } };
                    onChange(changes);
                    onResetPreview(changes);
                  } else {
                    onResetPreview(dirty);
                  }
                }}
                suffixIcon={<CaretDown />}
                placeholder="Select a trigger"
              >
                <Select.Option value={'when_conditions_pass'}>Immediately</Select.Option>
                {canSetElementTrigger && (
                  <Select.Option value={'when_element_appears'}>When element appears...</Select.Option>
                )}
                <Select.Option value={'on_command_execution'}>On command executed…</Select.Option>
                <Select.Option value={'on_event'}>On event tracked…</Select.Option>
                <Select.Option value={'when_share_link_viewed'}>When Share Link is viewed</Select.Option>
                {dirty.trigger.type === 'when_page_reached' && (
                  <Select.Option value={'when_page_reached'}>When a user visits a specific page</Select.Option>
                )}
              </StyledSelect>
            </InputContainer>
            {dirty.trigger.type === 'on_command_execution' && (
              <InputContainer>
                <StyledLabel>Command</StyledLabel>
                <StyledSelect
                  value={
                    commands.find(
                      (c) =>
                        dirty.trigger.type === 'on_command_execution' && c.id.toString() === dirty.trigger.meta.command,
                    )
                      ? dirty.trigger.meta.command
                      : undefined
                  }
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    if (typeof e === 'string') {
                      const changes = { ...dirty, trigger: { type: 'on_command_execution', meta: { command: e } } };
                      onChange(changes);
                      onResetPreview(changes);
                    }
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    !!option?.children &&
                    (option.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  suffixIcon={<CaretDown />}
                  placeholder="Select a command..."
                >
                  {commands.map((c, i) => (
                    <Select.Option key={i} value={Command.commandUID(c)}>
                      {c.text}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </InputContainer>
            )}
            {dirty.trigger.type === 'when_element_appears' && (
              <InputContainer>
                <StyledLabel>Element</StyledLabel>
                <ClickRecorderContainer>
                  <Input
                    style={{ border: '1px solid #A2A2A9' }}
                    placeholder="CSS selector or XPath"
                    onKeyDown={(e) => e.stopPropagation()}
                    value={dirty.trigger.meta.selector}
                    onChange={(e) => {
                      const changes = {
                        ...dirty,
                        trigger: { type: 'when_element_appears', meta: { selector: e.target.value } },
                      };
                      onChange(changes);
                      onResetPreview(changes);
                    }}
                    suffix={
                      <ClickRecorder
                        onValueChange={([e]) => {
                          const changes = {
                            ...dirty,
                            trigger: { type: 'when_element_appears', meta: { selector: e } },
                          };
                          onChange(changes);
                          onResetPreview(changes);
                        }}
                        value={dirty.trigger.meta.selector || ''}
                        singleStep
                      />
                    }
                  />
                  {isSelectorFragile(dirty.trigger.meta.selector) && (
                    <Tooltip content={SELECTOR_WARNING_TEXT}>
                      <WarningOutlined style={{ color: 'rgb(216, 150, 20)', marginLeft: '8px' }} />
                    </Tooltip>
                  )}
                </ClickRecorderContainer>
              </InputContainer>
            )}
            {dirty.trigger.type === 'on_event' && (
              <InputContainer>
                <StyledLabel>Event name</StyledLabel>
                <StyledEventAutoComplete>
                  <EventAutoComplete
                    onChange={(e) => {
                      const changes = { ...dirty, trigger: { type: 'on_event', meta: { event: e } } };
                      onChange(changes);
                      onResetPreview(changes);
                    }}
                    initialEventName={dirty.trigger.meta.event || ''}
                    checklists={checklists}
                    nudges={nudges}
                  />
                </StyledEventAutoComplete>
              </InputContainer>
            )}
            {dirty.trigger.type === 'when_page_reached' && (
              <InputContainer>
                <StyledLabel htmlFor="trigger_page_url">Page URL Contains</StyledLabel>
                <MediaBlockStyledInput
                  name="trigger_page_url"
                  value={dirty.trigger.meta.url}
                  onChange={(e) => {
                    if (!!e) {
                      const changes = {
                        ...dirty,
                        trigger: { type: 'when_page_reached', meta: { url: e.target.value } },
                      };
                      onChange(changes);
                      onResetPreview(changes);
                    }
                  }}
                  placeholder="e.g. /users/123/detail"
                />
              </InputContainer>
            )}
            {isNudge(dirty) && showWhenLimit && (
              <InputContainer>
                <StyledLabel>Limit</StyledLabel>
                <StyledSelect
                  value={dirty.frequency_limit}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    if (typeof e === 'string') {
                      onChange({ ...dirty, frequency_limit: e as (typeof dirty)['frequency_limit'] });
                      onResetPreview({ ...dirty, frequency_limit: e as (typeof dirty)['frequency_limit'] });
                    }
                  }}
                  suffixIcon={<CaretDown />}
                >
                  <Select.Option value={'once_per_user'}>Once per user</Select.Option>
                  <Select.Option value={'once_per_session'}>Once per session</Select.Option>
                  {dirty.trigger.type !== 'when_element_appears' && (
                    <Select.Option value={'no_limit'}>No limit</Select.Option>
                  )}
                  <Select.Option value={'until_interaction'}>Until completed or dismissed</Select.Option>
                </StyledSelect>
              </InputContainer>
            )}
          </PaddingContainerSM>
        </StyledPanel>
      </StyledCollapse>
    </TargetingContainer>
  );
};

export default Targeting;
