import * as t from 'io-ts';

import { createObject, readObject, deleteObject, updateObject, listObject, read } from './generics';

export const HelpDocsIntegrationV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.string,
      type: t.string,
      schedule: t.string,
      meta: t.union([t.record(t.string, t.any), t.null]),
    }),
    t.partial({
      title: t.string,
      icon: t.string,
    }),
  ],
  'HelpDocsIntegrationV',
);

const SyncStatusV = t.type({
  running: t.boolean,
  numFetched: t.number,
  numProcessed: t.number,
});

export class HelpDocsIntegration {
  public static create = createObject(HelpDocsIntegrationV, HelpDocsIntegrationV, 'integrations/helpdocs');
  public static read = readObject(HelpDocsIntegrationV, 'integrations/helpdocs');
  public static list = listObject(HelpDocsIntegrationV, 'integrations/helpdocs');
  public static update = updateObject(HelpDocsIntegrationV, HelpDocsIntegrationV, 'integrations/helpdocs');
  public static delete = deleteObject(HelpDocsIntegrationV, 'integrations/helpdocs');

  /** Get the status of the latest currently running sync for an integration */
  public static readSyncStatus = (integrationId: string) =>
    read(
      SyncStatusV,
      `integrations/sync/running`,
    )({
      integration_id: integrationId,
    });
}
