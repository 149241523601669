import React from 'react';
import { FolderPlus, Trash04 } from '@commandbar/design-system/icons/react';
import { IEditorRecommendationSet, IRuleExpression } from '@commandbar/internal/middleware/types';
import { CB_COLORS } from '@commandbar/design-system/components';

import {
  ButtonNew,
  FeatureAnnouncementCard,
  message,
  Modal,
  Panel,
  Select,
  Skeleton,
  SortableList,
  Spin,
  SubHeading,
  Input,
} from '../../shared_components';
import { DashedBorderWrapper } from '../../shared_components/form';
import { useAppContext } from '../../Widget';
import RuleExpressionEditor from '../commands/CommmandDetail/ConditionRulePanels/components/RuleExpressionEditor';
import { AudienceSelect } from '../components/Targeting';
import { CommandSelect } from '../nudges/NudgeContentForm/CommandSelect';
import { StyledSelect } from '../nudges/styled';
import { CaretDown, Container, DragHandle } from './shared';
import { WarningOutlined } from '@ant-design/icons';
import useRecommendationSets from '../useEditor/useRecommendationSets';
import Sender from '../../management/Sender';
import { useIsEditorOpen } from 'editor/src/hooks';

enum PanelKey {
  CONTENT = 'content',
  TARGETING = 'targeting',
}

const Recommendation = ({
  commandId,
  onChange,
  del,
  draggable = true,
}: {
  commandId: number | null;
  onChange: (commandId: number) => void;
  del?: () => void;
  draggable?: boolean;
}) => (
  <div
    style={{
      height: '48px',
      borderRadius: 4,
      gap: 8,
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '8px 0px',
      padding: '8px',
      backgroundColor: CB_COLORS.neutral0,
    }}
  >
    {draggable ? <DragHandle /> : <div style={{ width: 16 }} />}
    <CommandSelect value={commandId} onChange={onChange} placeholder="Select content..." />

    {del ? (
      <button
        style={{
          border: '1px solid ' + CB_COLORS.neutral300,
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          backgroundColor: 'white',
          width: 40,
          height: '100%',
        }}
        onClick={del}
      >
        <Trash04 color={CB_COLORS.neutral600} width={16} height={16} />
      </button>
    ) : (
      <div style={{ width: 40 }} />
    )}
  </div>
);

const RuleExpression = ({
  expr,
  disabled = false,
  onChange,
}: {
  expr: IRuleExpression;
  disabled?: boolean;
  onChange: (expr: IRuleExpression) => void;
}) => {
  const targetingType = expr.type === 'LITERAL' && expr.value === true ? 'all_pages' : 'custom';

  return (
    <>
      <StyledSelect<'all_pages' | 'custom'>
        disabled={disabled}
        value={targetingType}
        style={{ width: '100%' }}
        onChange={(e) => {
          if (e === 'all_pages') {
            onChange({ type: 'LITERAL', value: true });
          } else {
            onChange({ type: 'CONDITION', condition: { type: 'url', operator: 'includes' } });
          }
        }}
        suffixIcon={<CaretDown />}
      >
        <Select.Option title={'On all pages'} value={'all_pages'}>
          On all pages
        </Select.Option>
        <Select.Option value={'custom'}>If...</Select.Option>
      </StyledSelect>

      {targetingType === 'custom' && (
        <RuleExpressionEditor
          disabled={disabled}
          onChange={onChange}
          expr={expr}
          categories={['Page']}
          omitNamedRuleType
        />
      )}
    </>
  );
};

const RecommendationSetEditor = ({
  recommendationSet,
  onChange,
}: {
  recommendationSet: IEditorRecommendationSet;
  onChange: (s: IEditorRecommendationSet) => void;
}) => {
  const [activeKeys, setActiveKeys] = React.useState<PanelKey[]>([PanelKey.CONTENT]);
  const { rules } = useAppContext();

  React.useEffect(() => {
    if (recommendationSet.name === '') {
      onChange({
        ...recommendationSet,
        name: 'Recommendation Set',
      });
    }
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      Label
      <Input
        value={recommendationSet.name}
        onChange={(e) => {
          onChange({
            ...recommendationSet,
            name: e.target.value,
          });
        }}
      />
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.CONTENT} header="Content">
        <>
          <SortableList
            style={{ margin: '-8px 0' }}
            nodes={[
              ...recommendationSet.command_ids.map((commandId, index) => {
                return (
                  <Recommendation
                    commandId={commandId}
                    key={index}
                    del={() => {
                      const command_ids = [...recommendationSet.command_ids];
                      command_ids.splice(index, 1);
                      onChange({
                        ...recommendationSet,
                        command_ids,
                      });
                    }}
                    onChange={(newCommand) => {
                      const command_ids = [...recommendationSet.command_ids];
                      command_ids.splice(index, 1, newCommand);
                      onChange({
                        ...recommendationSet,
                        command_ids,
                      });
                    }}
                  />
                );
              }),
              <Recommendation
                draggable={false}
                commandId={null}
                key={'new_command'}
                onChange={(newCommand) => {
                  onChange({
                    ...recommendationSet,
                    command_ids: [...recommendationSet.command_ids, newCommand],
                  });
                }}
              />,
            ]}
            onSort={(oldIndex, newIndex) => {
              const { command_ids } = recommendationSet;
              const command = command_ids[oldIndex];
              const newCommandIds = [...command_ids.slice(0, oldIndex), ...command_ids.slice(oldIndex + 1)];
              newCommandIds.splice(newIndex, 0, command);

              onChange({
                ...recommendationSet,
                command_ids: newCommandIds,
              });
            }}
            useDragHandle
          />
          {/* <div>
            <AddButton
              style={{ margin: '0px 0px 0px auto' }}
              onClick={() => {
                setShowNewCommand(true);
              }}
            >
              Add item
            </AddButton>
          </div> */}
        </>
      </Panel>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.TARGETING} header="Targeting">
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
          <SubHeading>Who</SubHeading>

          <AudienceSelect
            disabled={recommendationSet.default /* default recommendation set always targets All Users / All Pages */}
            audience={recommendationSet.audience}
            rules={rules}
            onChange={(audience) => {
              onChange({ ...recommendationSet, audience });
            }}
          />
        </div>
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
          <SubHeading>Where</SubHeading>

          <RuleExpression
            disabled={recommendationSet.default /* default recommendation set always targets All Users / All Pages */}
            expr={recommendationSet.show_expression}
            onChange={(expr) => {
              onChange({ ...recommendationSet, show_expression: expr });
            }}
          />
        </div>
      </Panel>
    </div>
  );
};

const AddNewSet = ({ addNew }: { addNew: () => Promise<IEditorRecommendationSet> }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <ButtonNew
      onClick={async () => {
        if (loading) return;
        setLoading(true);
        try {
          await addNew();
        } catch {
          message.error('Error creating new Recommendation Set');
        } finally {
          setLoading(false);
        }
      }}
    >
      {loading ? (
        <Spin size="small" style={{ width: 16, height: 16 }} />
      ) : (
        <FolderPlus width={16} height={16} color={CB_COLORS.neutral1000} />
      )}
      <span>Add a Set</span>
    </ButtonNew>
  );
};

const HelpHubRecommendations = () => {
  const [activeKeys, setActiveKeys] = React.useState<number[]>([]);

  const { defaultRecommendationSet, recommendationSets, actions } = useRecommendationSets();
  const { isStandaloneEditor } = useAppContext();
  const isEditorOpen = useIsEditorOpen();

  React.useEffect(() => {
    if (isEditorOpen || isStandaloneEditor) {
      Sender.openHelpHub();
    }
  }, [isEditorOpen]);

  React.useEffect(() => {
    return () => {
      Sender.stopRecommendationSetPreview();
    };
  }, []);

  React.useEffect(() => {
    // HACK: if there is no default recommendation set, create one
    if (defaultRecommendationSet && defaultRecommendationSet.id < 0) {
      actions.defaultRecommendationSet.update(defaultRecommendationSet);
    }
  }, [defaultRecommendationSet]);

  // We want to preview the default recommendation set when the page loads but we don't know the id until the default
  // recommendation set is returned from useRecommendationSets.
  // This effect will open the default rec set panel once when the default recommendation set is set.
  React.useEffect(() => {
    if (activeKeys.length > 0) return;

    setActiveKeys(defaultRecommendationSet?.id ? [defaultRecommendationSet.id] : []);
  }, [defaultRecommendationSet?.id]);

  React.useEffect(() => {
    Sender.previewRecommendationSet(activeKeys[0]);
  }, [activeKeys]);

  const addNewRecommendationSet = async () => {
    const newSet = await actions.recommendationSets.addNew();
    setActiveKeys([newSet.id]);
    return newSet;
  };

  return (
    <Container>
      <FeatureAnnouncementCard
        identifier="recommendation_sets"
        title="Recommendation Sets"
        docsLink="https://www.commandbar.com/docs/helphub/overview"
      >
        <span>Suggest recommended help content (docs, videos, and links) to your users.</span>
      </FeatureAnnouncementCard>
      <Panel
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        panelKey={defaultRecommendationSet?.id ?? 1}
        header={defaultRecommendationSet?.name}
        subpanel
        accordion
      >
        {defaultRecommendationSet ? (
          <RecommendationSetEditor
            onChange={actions.defaultRecommendationSet.update}
            recommendationSet={defaultRecommendationSet}
          />
        ) : (
          <Skeleton active />
        )}
      </Panel>
      {recommendationSets &&
        recommendationSets.map((recommendationSet) => (
          <Panel
            key={recommendationSet.id}
            activeKeys={activeKeys}
            setActiveKeys={setActiveKeys}
            panelKey={recommendationSet.id}
            header={recommendationSet.name}
            subpanel
            actions={
              <Trash04
                onClick={async () => {
                  Modal.confirm({
                    icon: <WarningOutlined />,
                    title: `Are you sure you'd like to delete '${recommendationSet.name}'?`,
                    async onOk() {
                      try {
                        await actions.recommendationSets.del(recommendationSet.id);
                      } catch {
                        message.error('Error deleting Recommendation Set');
                      }
                    },
                  });
                }}
                width={16}
                height={16}
                color={CB_COLORS.neutral500}
              />
            }
            accordion
          >
            <RecommendationSetEditor
              onChange={actions.recommendationSets.update}
              recommendationSet={recommendationSet}
            />
          </Panel>
        ))}

      <DashedBorderWrapper>
        <AddNewSet addNew={addNewRecommendationSet} />
      </DashedBorderWrapper>
    </Container>
  );
};

export default HelpHubRecommendations;
