/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */
import React, { useRef } from 'react';

import * as Command from '@commandbar/internal/middleware/command';
import { Rule } from '@commandbar/internal/middleware/rule';

import { CommandCategory } from '@commandbar/internal/middleware/commandCategory';

import type {
  IBatchOperation,
  IChecklist,
  ICommandCategoryType,
  IEditorCommandType,
  IEditorCommandTypeLite,
  INudgeType,
  IOrganizationSettingsType,
  IOrganizationType,
  IUserType,
} from '@commandbar/internal/middleware/types';
import { INamedRule } from '@commandbar/internal/middleware/helpers/rules';

import { compareObjs } from '@commandbar/internal/middleware/utils';
import { getNewSortkey } from '../components/SortableTable';

import Sender from '../../management/Sender';
import { useReportEvent } from '../../shared_components/useEventReporting';
import { get } from '@commandbar/internal/middleware/network';
import * as Organization from '@commandbar/internal/middleware/organization';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import { Nudge } from '@commandbar/internal/middleware/nudge';
import { message } from '../../shared_components';
import { Checklist } from '@commandbar/internal/middleware/checklist';
import { useUsage } from '../../hooks/useUsage';
import { useReloadable } from '../../hooks/useReloadable';

// HACK -- until we refactor state management a bit
// allows code outside this file to set the hasUnreleasedEdits state
export let setHasUnreleasedEdits = (_state: boolean) => {
  return;
};

const useEditor = (user: IUserType) => {
  /******************************************************************/
  /* Internal
  /******************************************************************/
  const [organization, setOrganization] = React.useState<IOrganizationType | undefined>(undefined);
  const [organizationSettings, setOrganizationSettings] = React.useState<IOrganizationSettingsType | undefined>(
    undefined,
  );
  const [categories, setCategories] = React.useState<ICommandCategoryType[]>([]);
  const [commands, setCommands] = React.useState<IEditorCommandTypeLite[]>([]);
  const [answers, setAnswers] = React.useState<IEditorCommandType[]>([]);
  const [nudges, setNudges] = React.useState<INudgeType[]>([]);
  const [checklists, setChecklists] = React.useState<IChecklist[]>([]);
  const [rules, setRules] = React.useState<INamedRule[]>([]);
  const [activeCommand, _setActiveCommand] = React.useState<
    { state: 'loading'; commandId: number } | { state: 'editing'; command: IEditorCommandType } | { state: 'none' }
  >({ state: 'none' });
  const [hasUnreleasedEdits, _setHasUnreleasedEdits] = React.useState<boolean>(false);
  const { reportEvent } = useReportEvent();
  const { refetch: refetchUsage } = useUsage();
  setHasUnreleasedEdits = _setHasUnreleasedEdits;

  const orgId = organization && String(organization.id);

  React.useEffect(() => {
    Sender.shareOrganization(organization || null);
  }, [orgId]);

  /******************************************************************/

  // Bulk update command sort keys within a category
  const onCommandReorder = async (
    oldIndexOfMovedObj: number,
    newIndexOfMovedObj: number,
    categoryID: number | null,
  ) => {
    const category = categories.find((category) => category.id === categoryID);
    // Get the commands within this category
    const sortedCommands = commands.filter((command) => command.category === categoryID).sort(compareObjs);
    const unchangedCommands = commands.filter((command) => command.category !== categoryID);

    const sortedCommandsWithUpdatedSortKey = sortedCommands.map((command, currentIndex) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
      return { ...command, sort_key: newSortKey };
    });

    const tempNewCommands = [...unchangedCommands, ...sortedCommandsWithUpdatedSortKey];

    // const promises = sortedCommands.map((command: IEditorCommandType, currentIndex: number) => {
    //   const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
    //   const newCommand = { ...command, sort_key: newSortKey };
    //   tempNewCommands.push(newCommand);

    //   // Only make an api call if the category has changed
    //   if (newCommand.sort_key === command.sort_key) return Promise.resolve(command);
    //   else return Command.update(newCommand);
    // });
    // Temporary set new categories so front end is not jumpy
    setCommands(tempNewCommands);
    const batch: IBatchOperation[] = [];

    sortedCommands.forEach((command, currentIndex) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
      if (newSortKey !== command.sort_key) {
        batch.push({ op: 'update', id: command.id, data: { sort_key: newSortKey } });
      }
    });

    let note = 'Changing sort order of commands';
    if (category) {
      note += ` in category ${category.name}`;
    }
    const result = await Command.batch({ batch, note });
    const resultsByCommandId: { [id: number]: IEditorCommandType } = {};
    result.batch.forEach((command) => {
      resultsByCommandId[command.id] = command;
    });

    // Once new objs are received, reset
    const newCommands = sortedCommands.map((command) => resultsByCommandId[command.id] || command);
    setCommands([...unchangedCommands, ...newCommands]);

    Sender.reload(['reloadCommands']);
  };

  // Bulk update category sort keys
  const updateCategorySortKeys = async (sortKeyMapping: { [id: number]: number }) => {
    const batch: IBatchOperation[] = [];

    categories.forEach((category) => {
      if (sortKeyMapping.hasOwnProperty(category.id) && sortKeyMapping[category.id] !== category.sort_key) {
        batch.push({ op: 'update', id: category.id, data: { sort_key: sortKeyMapping[category.id] } });
      }
    });

    const note = 'Changing sort order of categories';
    const result = await CommandCategory.batch({ batch, note });
    const resultsByCategoryId: { [id: number]: ICommandCategoryType } = {};
    result.batch.forEach((category) => {
      resultsByCategoryId[category.id] = category;
    });

    const newCategories = categories.map((category) => resultsByCategoryId[category.id] || category);
    setCategories(newCategories);

    Sender.reload(['reloadCommands']);
  };

  // Bulk update category sort keys
  const onCategoryReorder = async (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const sortedCurrentCategories = categories.sort(compareObjs);

    const sortedCategoriesWithUpdatedSortKey = sortedCurrentCategories.map((category, currentIndex) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
      return { ...category, sort_key: newSortKey };
    });

    setCategories(sortedCategoriesWithUpdatedSortKey);
    const batch: IBatchOperation[] = [];

    sortedCurrentCategories.forEach((category, currentIndex) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
      if (newSortKey !== category.sort_key) {
        batch.push({ op: 'update', id: category.id, data: { sort_key: newSortKey } });
      }
    });

    const note = 'Changing sort order of categories';
    const result = await CommandCategory.batch({ batch, note });
    const resultsByCategoryId: { [id: number]: ICommandCategoryType } = {};
    result.batch.forEach((category) => {
      resultsByCategoryId[category.id] = category;
    });

    const newCategories = sortedCurrentCategories.map((category) => resultsByCategoryId[category.id] || category);
    setCategories(newCategories);
  };

  const saveCategory = async (newObj: ICommandCategoryType, opts = { notify: true }) => {
    if (newObj.id < 0) {
      try {
        const newCategory = await CommandCategory.create(newObj);
        setCategories((oldCategories) => [...oldCategories, newCategory]);
        reportEvent('category created', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${newCategory.name} (ID: ${newCategory.id})`,
        });
        message.success('New category created.');

        Sender.reload(['reloadCommands']);

        return newCategory;
      } catch (err) {
        if (String(err).includes('unique')) {
          message.error('Could not create category because another category has the same name.');
        } else {
          message.error('Could not create category.');
        }
      }
    } else {
      try {
        const onSuccess = opts.notify ? () => message.success(<div>Category updated.</div>) : undefined;
        const newCategory = await CommandCategory.update(newObj, onSuccess);
        setCategories((oldCategories) =>
          oldCategories.map((category: ICommandCategoryType) =>
            category.id === newCategory.id ? newCategory : category,
          ),
        );
        await Sender.reload(['reloadCommands']);
        reportEvent('category edited', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${newCategory.name} (ID: ${newCategory.id})`,
        });

        return newCategory;
      } catch (err) {
        if (String(err).includes('unique')) {
          message.error('Could not update category because another category has the same name.');
        } else if (String(err).includes('no more than 255 characters')) {
          message.error(
            'Category name, slash filter keyword and major category name must not be longer than 255 characters each.',
          );
        } else {
          message.error('Could not update category.');
        }
      }
    }
  };

  const deleteCategory = async (categoryID: number) => {
    const category = categories.find((category) => category.id === categoryID);
    if (!category) {
      return;
    }

    const commandsToDelete = commands.filter((command) => command.category === categoryID);
    const batch: IBatchOperation[] = [];

    commandsToDelete.forEach((command) => {
      batch.push({ op: 'delete', id: command.id });
    });

    const note = `Delete commands from category ${category.name}`;

    if (batch.length > 0) {
      await Command.batch({ batch, note }).then(() => {
        setCommands(commands.filter((command) => command.category !== categoryID));
        message.success(`Commands from category ${category.name} deleted`);

        Sender.reload(['reloadCommands']);
      });
    }

    return await CommandCategory.delete(categoryID)
      .then(() => {
        setCategories((oldCategories: ICommandCategoryType[]) => [
          ...oldCategories.filter((el) => el.id !== categoryID),
        ]);
        message.success(`Category ${category.name} deleted`);

        reportEvent('category deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${category.name} (ID: ${category.id}))`,
        });

        Sender.reload(['reloadCommands']);
      })
      .catch((e) => {
        message.error(`Deleting ${category.name} failed. ${e}`);
      });
  };

  const deleteCommands = async (commandIds: number[]) => {
    const commandsToDelete = commands.filter((command) => commandIds.includes(command.id));
    const batch: IBatchOperation[] = [];

    commandsToDelete.forEach((command) => {
      batch.push({ op: 'delete', id: command.id });
    });

    const note = `Delete commands [${commandsToDelete.map(({ text }) => text).join(', ')}]`;

    if (batch.length > 0) {
      try {
        await Command.batch({ batch, note });
        setCommands(commands.filter((command) => !commandIds.includes(command.id)));
        message.success(`Commands deleted`);
        Sender.reload(['reloadCommands']);
      } catch (_) {
        message.error(`Error deleting commands`);
      }
    }
  };

  const saveCommand = async (
    newObj: IEditorCommandType,
    opts = { notify: true, throttle: true, setToActiveIfNew: true },
  ) => {
    const isAnswer = newObj.template.type === 'helpdoc' && newObj.template.doc_type === 'answer';

    const toReload: ('reloadCommands' | 'reloadHelpHub')[] = ['reloadCommands'];

    if (newObj.template.type === 'helpdoc') {
      toReload.push('reloadHelpHub');
    }

    const isRecordAction = Command.isRecordAction(newObj);
    const isShownInDefaultComamndsList = Command.showInDefaultList(newObj);

    if (newObj.id < 0) {
      return await Command.create(
        newObj,
        opts.notify
          ? () =>
              message.success(
                <div
                  onClick={() => {
                    if (!isRecordAction || isShownInDefaultComamndsList) {
                      Sender.setTestMode(true);
                      Sender.openBar(newObj.text);
                    }
                  }}
                  style={{ cursor: `${!isRecordAction || isShownInDefaultComamndsList ? 'pointer' : 'unset'}` }}
                >
                  {isRecordAction ? 'Record action' : 'Command'} created.
                  {!isRecordAction || isShownInDefaultComamndsList ? ' Click here to view in the Bar.' : ''}
                </div>,
              )
          : undefined,
        (err: string) => message.error(err),
      ).then(async (newCommand) => {
        // Refresh the total number of live commands
        refetchUsage();
        Sender.reload(toReload);

        if (opts.setToActiveIfNew && !isAnswer) {
          setActiveCommand({ state: 'editing', command: newCommand });
        }

        if (isAnswer) {
          setAnswers((oldAnswers) => [newCommand, ...oldAnswers]);
        } else {
          setCommands((oldCommands) => [newCommand, ...oldCommands]);
        }

        reportEvent(`${isAnswer ? 'answer' : 'command'} created`, {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: newCommand.text,
          eventProps: {
            commandId: newCommand.id,
            commandType: newCommand.template.type,
          },
        });

        return newCommand;
      });
    } else {
      const handleNewCommand = async (newCommand: IEditorCommandType) => {
        // Refresh the total number of live commands
        refetchUsage();
        Sender.reload(toReload);

        if (isAnswer) {
          setAnswers((oldAnswers) => oldAnswers.map((answer) => (answer.id === newCommand.id ? newCommand : answer)));
        } else {
          setCommands((oldCommands) =>
            oldCommands.map((command) => (command.id === newCommand.id ? newCommand : command)),
          );
        }

        reportEvent(`${isAnswer ? 'answer' : 'command'} edited`, {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: newCommand.text,
          eventProps: {
            commandId: newCommand.id,
            commandType: newCommand.template.type,
          },
        });

        return newCommand;
      };

      const onSuccess = opts.notify
        ? () =>
            message.success(
              <div
                onClick={() => {
                  if (!isRecordAction || isShownInDefaultComamndsList) {
                    Sender.setTestMode(true);
                    Sender.openBar(newObj.text);
                  }
                }}
                style={{ cursor: `${!isRecordAction || isShownInDefaultComamndsList ? 'pointer' : 'unset'}` }}
              >
                {isRecordAction ? 'Record action' : 'Command'} updated.
                {!isRecordAction || isShownInDefaultComamndsList ? ' Click here to view in the Bar.' : ''}
              </div>,
            )
        : undefined;

      if (opts.throttle) {
        return await Command.update(newObj, onSuccess, message.error).then(handleNewCommand);
      } else {
        return await Command.updateWithoutThrottle(newObj, onSuccess, message.error).then(handleNewCommand);
      }
    }
  };
  const savePartialCommand = async (command: Pick<IEditorCommandType, 'id'> & Partial<IEditorCommandType>) => {
    Command.updatePartial(command)
      .then((newCommand) => {
        const toReload: ('reloadCommands' | 'reloadHelpHub')[] = ['reloadCommands'];
        if (newCommand.template.type === 'helpdoc') {
          toReload.push('reloadHelpHub');
        }

        Sender.reload(toReload);
        setCommands((oldCommands) =>
          oldCommands.map((command) => (command.id === newCommand.id ? newCommand : command)),
        );
        reportEvent('command edited', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: newCommand.text,
          eventProps: {
            commandId: newCommand.id,
            commandType: newCommand.template.type,
          },
        });
      })
      .catch(() => message.error('Something went wrong'));
  };

  const deleteCommand = async (command: IEditorCommandTypeLite) => {
    const isAnswer = command.template.type === 'helpdoc' && command.template.doc_type === 'answer';

    await Command.del(
      command.id,
      undefined,
      () => message.success(`${isAnswer ? 'Answer' : 'Command'} deleted`),
      (err: string) => message.error(err),
    );

    if (isAnswer) {
      setAnswers((oldAnswers) => [...oldAnswers.filter((el) => el.id !== command.id)]);
    } else {
      setCommands((oldCommands) => [...oldCommands.filter((el) => el.id !== command.id)]);
    }

    // Refresh the total number of live commands
    refetchUsage();
    Sender.reload(['reloadCommands']);

    reportEvent(`${isAnswer ? 'answer' : 'command'} deleted`, {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: command.text,
      eventProps: {
        commandId: command.id,
        commandType: command.template.type,
      },
    });
  };

  const addRule = async (rule: INamedRule, fromExistingCondition: boolean) => {
    const displayName = rule.is_audience ? 'Audience' : 'Rule';
    const result = fromExistingCondition
      ? await Rule.createFromExistingCondition(rule, () => message.success(`${displayName} created!`), message.error)
      : await Rule.create(rule, () => message.success(`${displayName} created!`), message.error);

    await Sender.reload(['reloadCommands', 'reloadChecklists', 'reloadNudges']);
    await reloadCommands();
    await reloadNudges();
    await reloadChecklists();

    setRules((rules) => [result, ...rules]);
    reportEvent('rule created', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: `${rule.name} (ID: ${result.id}, Audience?: ${rule.is_audience}))`,
    });
  };

  const changeRule = (id: string | number) => async (rule: INamedRule) => {
    const displayName = rule.is_audience ? 'Audience' : 'Rule';
    const idx = rules.findIndex((rule) => rule.id === id);
    if (idx < 0) throw new Error(`Rule with id ${id} not found`);

    await Rule.update(rule, () => message.success(`${displayName} updated!`), message.error);

    await Sender.reload(['reloadCommands', 'reloadChecklists', 'reloadNudges']);
    await reloadCommands();
    await reloadNudges();
    await reloadChecklists();

    setRules((rules) => [...rules.slice(0, idx), rule, ...rules.slice(idx + 1)]);
    reportEvent('rule edited', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        name: rule.name,
      },
    });
  };

  const removeRule = (id: string | number) => async () => {
    const idx = rules.findIndex((rule) => rule.id === id);
    if (idx < 0) throw new Error(`Rule with id ${id} not found`);

    const deletedRule = rules[idx];

    const displayName = deletedRule.is_audience ? 'Audience' : 'Rule';
    await Rule.delete(rules[idx].id, undefined, () => message.success(`${displayName} deleted`), message.error);

    await Sender.reload(['reloadCommands', 'reloadChecklists', 'reloadNudges']);
    await reloadCommands();
    await reloadNudges();
    await reloadChecklists();

    setRules((rules) => [...rules.slice(0, idx), ...rules.slice(idx + 1)]);

    reportEvent('audience deleted', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        name: deletedRule.name,
      },
    });
  };

  /** Nudges */

  const saveNudge = async (nudge: INudgeType) => {
    if (Nudge.isNew(nudge)) {
      const newNudge = await Nudge.create(nudge, () => message.success('Nudge created!'), message.error);
      setNudges((oldList) => [...oldList, newNudge]);

      // Recalculate the number of live nudges
      refetchUsage();
      Sender.reload(['reloadNudges']);

      const payloadMessage = newNudge?.slug ? `${newNudge.slug} (ID: ${newNudge.id})` : `Untitled (ID: ${newNudge.id})`;
      reportEvent('nudge created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          id: newNudge.id,
          item_count: newNudge.steps.length,
          audience: newNudge.audience?.type,
          trigger: newNudge.trigger,
        },
      });

      return newNudge;
    } else {
      const updatedNudge = await Nudge.update(nudge, () => message.success('Nudge updated!'), message.error);
      setNudges((oldList) => oldList.map((v: INudgeType) => (v.id === updatedNudge.id ? updatedNudge : v)));

      // Recalculate the number of live nudges
      refetchUsage();
      Sender.reload(['reloadNudges']);

      const payloadMessage = updatedNudge?.slug
        ? `${updatedNudge.slug} (ID: ${updatedNudge.id})`
        : `Untitled (ID: ${updatedNudge.id})`;
      reportEvent('nudge edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          id: updatedNudge.id,
          item_count: updatedNudge.steps.length,
          audience: updatedNudge.audience?.type,
          trigger: updatedNudge.trigger,
        },
      });

      return updatedNudge;
    }
  };

  const deleteNudge = async (nudge: INudgeType) => {
    const postDelete = () => {
      const deletedNudge = nudges.find((v) => v.id === nudge.id);
      const payloadMessage = deletedNudge?.slug
        ? `${deletedNudge.slug} (ID: ${deletedNudge.id})`
        : `Untitled (ID: ${deletedNudge?.id})`;
      setNudges((oldList) => oldList.filter((v) => v.id !== nudge.id));

      reportEvent('nudge deleted', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          id: deletedNudge?.id,
          item_count: deletedNudge?.steps.length,
          audience: deletedNudge?.audience?.type,
          trigger: deletedNudge?.trigger,
        },
      });
    };

    return await Nudge.delete(nudge.id, undefined, () => message.success('Nudge deleted'), message.error)
      .then(postDelete)
      .then(async () => {
        // Recalculate the number of live nudges
        refetchUsage();
        Sender.reload(['reloadNudges']);
      });
  };

  const saveChecklist = async (checklist: IChecklist) => {
    if (checklist.id < 0) {
      const newChecklist = await Checklist.create(
        checklist,
        () => message.success('Checklist created!'),
        message.error,
      );

      setChecklists((oldList) => [...oldList, newChecklist]);

      // Recalculate the number of live checklists
      refetchUsage();
      Sender.reload(['reloadChecklists']);

      const payloadMessage = newChecklist.title
        ? `${newChecklist.title} (ID: ${newChecklist.id})`
        : `${newChecklist.description} (ID: ${newChecklist.id})`;

      reportEvent('questlist created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          item_count: checklist.items.length,
          name: payloadMessage,
        },
      });

      return newChecklist;
    } else {
      const updatedChecklist = await Checklist.update(
        checklist,
        () => message.success('Checklist updated!'),
        message.error,
      );

      const payloadMessage = checklist.title
        ? `${checklist.title} (ID: ${checklist.id})`
        : `${checklist.description} (ID: ${checklist.id})`;
      setChecklists((oldList) => oldList.map((v: IChecklist) => (v.id === updatedChecklist.id ? updatedChecklist : v)));

      // Recalculate the number of live checklists
      refetchUsage();
      Sender.reload(['reloadChecklists']);

      reportEvent('questlist edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          item_count: checklist.items.length,
          name: payloadMessage,
        },
      });

      return updatedChecklist;
    }
  };

  const deleteChecklist = async (toDeleteID: number) => {
    return await Checklist.delete(toDeleteID, undefined, () => message.success('Checklist deleted'), message.error)
      .then(() => setChecklists((oldList) => oldList.filter((v) => v.id !== toDeleteID)))
      .then(async () => {
        // Recalculate the number of live checklists
        refetchUsage();
        Sender.reload(['reloadChecklists']);

        const deletedChecklist = checklists.find((v) => v.id === toDeleteID);
        const payloadMessage = deletedChecklist?.title
          ? `${deletedChecklist.title} (ID: ${toDeleteID})`
          : `${deletedChecklist?.description} (ID: ${toDeleteID})`;

        reportEvent('questlist deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: payloadMessage,
          eventProps: {
            name: payloadMessage,
          },
        });
      });
  };

  /** Reloadable */

  const [isLoadingOrganization, reloadOrganization] = useReloadable<IOrganizationType>(
    async () => {
      const { data } = await get(`organizations/${user.organization}/`);
      return data;
    },
    setOrganization,
    [user],
  );

  const updateOrganization = async (updatedOrganization: IOrganizationType, updateRemote = true) => {
    let _organization = updatedOrganization;

    if (!!updateRemote) {
      _organization = await Organization.update(updatedOrganization);
    }
    setOrganization(_organization);
    Sender.reload(['reloadOrganization']);
    return _organization;
  };
  const [isLoadingOrganizationSettings] = useReloadable<IOrganizationSettingsType>(
    async () => {
      return await OrganizationSettings.read();
    },
    setOrganizationSettings,
    [user],
  );

  const updateOrganizationSetting = async (settings: Partial<IOrganizationSettingsType>) => {
    setOrganization((oldOrg) => {
      if (!oldOrg) return oldOrg;
      const newOrg: IOrganizationType = { ...oldOrg, ...settings };
      return newOrg;
    });

    try {
      const newSettings = await OrganizationSettings.update(settings);
      setOrganization((oldOrg) => (oldOrg ? { ...oldOrg, ...newSettings } : oldOrg));
      Sender.reload(['reloadOrganization']);
      message.success('Settings updated.');

      return newSettings;
    } catch (e) {
      // try to reload organization -- our changes may not have been applied
      await reloadOrganization();
      throw e;
    }
  };

  const [isLoadingRules, reloadRules] = useReloadable(
    async () => {
      if (!orgId) return [];

      return await Organization.listRules(orgId);
    },
    setRules,
    [orgId],
  );

  const [isLoadingCommands, reloadCommands] = useReloadable(
    async () => {
      if (!orgId) return [];

      const commands = await Organization.listCommands(orgId);
      return commands.filter(
        (command) => command.template.type !== 'helpdoc' || command.template.doc_type !== 'answer',
      );
    },
    setCommands,
    [orgId],
  );

  const [isLoadingAnswers, reloadAnswers] = useReloadable(
    async () => {
      if (!orgId) return [];

      return await Organization.listAnswers(orgId);
    },
    setAnswers,
    [orgId],
  );

  const [isLoadingCategories, reloadCategories] = useReloadable(
    async () => {
      if (!orgId) return [];

      return await Organization.listCommandCategories(orgId);
    },
    setCategories,
    [orgId],
  );

  const [isLoadingNudges, reloadNudges] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await Nudge.list();
    },
    setNudges,
    [orgId],
  );

  const [isLoadingChecklists, reloadChecklists] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await Checklist.list();
    },
    setChecklists,
    [orgId],
  );

  // We store the latest command id to prevent a race when canceling multiple commands in quick succession.
  // This prevents a past command from running if a more recent command has completed first and set
  // commandCanceled to false.
  const latestCommandId = useRef<number>(0);
  const commandCanceled = useRef<boolean>(false);

  const cancelLoadingCommand = () => {
    if (activeCommand.state === 'loading') {
      commandCanceled.current = true;
      _setActiveCommand({ state: 'none' });
    }
  };

  const setActiveCommand = (...args: Parameters<typeof _setActiveCommand>) => {
    // if there is a command currently loading, cancel it
    cancelLoadingCommand();

    _setActiveCommand(...args);
  };

  const setActiveCommandById = async (commandId: number) => {
    if (activeCommand.state === 'loading' && commandId === activeCommand.commandId) return;

    // if there is a command currently loading, cancel it
    cancelLoadingCommand();

    latestCommandId.current = commandId;

    try {
      const command = await Command.get(commandId.toString());

      // only run the most recent command, as long as it has not been canceled
      if (commandId === latestCommandId.current && !commandCanceled.current) {
        commandCanceled.current = false;
        _setActiveCommand({ state: 'editing', command });
      }
    } catch (error) {
      console.log(`error loading command(id=${commandId}): ${error}`);
      if (!commandCanceled.current) {
        commandCanceled.current = false;
        _setActiveCommand({ state: 'none' });
      }
    }
  };

  if (!organization) return null;

  return {
    state: {
      organization,
      organizationSettings,
      commands,
      categories,
      rules,
      activeCommand,
      hasUnreleasedEdits,
      nudges,
      checklists,
      answers,
      loading:
        isLoadingOrganization ||
        isLoadingOrganizationSettings ||
        isLoadingRules ||
        isLoadingCommands ||
        isLoadingCategories ||
        isLoadingNudges ||
        isLoadingChecklists ||
        isLoadingAnswers,
    },
    dispatch: {
      reloadAll: () => {
        reloadOrganization();
        reloadCommands();
        reloadRules();
        reloadCategories();
      },
      organization: {
        reload: reloadOrganization,
        update: updateOrganization,
        updateSetting: updateOrganizationSetting,
      },
      commands: {
        reload: reloadCommands,
        setList: setCommands,
        setActiveCommandById,
        setActive: (command: IEditorCommandType | undefined) => {
          if (command) setActiveCommand({ state: 'editing', command });
          else setActiveCommand({ state: 'none' });
        },
        reorder: onCommandReorder,
        save: saveCommand,
        savePartial: savePartialCommand,
        delete: deleteCommand,
        bulkDelete: deleteCommands,
      },
      rules: {
        reload: reloadRules,
        setList: setRules,
        addRule,
        removeRule,
        changeRule,
      },
      categories: {
        reload: reloadCategories,
        setList: setCategories,
        reorder: onCategoryReorder,
        updateSortKeys: updateCategorySortKeys,
        save: saveCategory,
        delete: deleteCategory,
      },
      nudges: {
        save: saveNudge,
        delete: deleteNudge,
        reload: reloadNudges,
      },
      answers: {
        reload: reloadAnswers,
      },
      checklists: {
        save: saveChecklist,
        delete: deleteChecklist,
        reload: reloadChecklists,
      },
    },
  };
};

export default useEditor;

export type ICommandTableState = Exclude<ReturnType<typeof useEditor>, null>['state'];
export type ICommandTableDispatch = Exclude<ReturnType<typeof useEditor>, null>['dispatch'];

export const freshCommand = (
  organization: IOrganizationType,
  categoryID: number | undefined,
  hasRouter?: boolean,
  icon?: string | null,
  image?: string | null,
): IEditorCommandType =>
  Command.decodeEditorCommand({
    id: -1,
    organization: organization.id.toString(),
    category: categoryID || null,
    icon: icon || null,
    image: image || null,
    text: '',
    template: { type: 'link', value: '', operation: hasRouter ? 'router' : 'blank', commandType: 'independent' },
  });
