import React from 'react';
import styled from '@emotion/styled';

import { Trash04, InfoCircle } from '@commandbar/design-system/icons/react';
import { CB_COLORS, Tag } from '@commandbar/design-system/components';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import { IAdditionalResource } from '@commandbar/internal/middleware/types';

import { useAppContext } from '../../Widget';

import {
  IconPicker,
  Input,
  Panel,
  Select,
  Skeleton,
  SortableList,
  SubHeading,
  Switch,
  Tooltip,
} from '../../shared_components';
import { HelpHubLauncherSettings } from '../components/HelpHubLauncherSettings';
import { AddButton } from '../components/styled';
import { getColorsFromCommands } from '../utils/ColorUtils';

import useSupportCTA from '../useEditor/useAdditionalResources';
import useChatSettings from '../useEditor/useChatSettings';
import { ActionEditor } from '../nudges/NudgeContentForm/ActionEditor';
import { Container, DragHandle, StyledTextArea } from './shared';
import { AISettings } from '../components/AISettings/AISettings';
import Sender from '../../management/Sender';
import { useIsEditorOpen } from 'editor/src/hooks';

enum PanelKey {
  GENERAL = 'general',
  CHAT = 'chat',
  LAUNCHER = 'launcher',
  ADDITIONAL_RESOURCES = 'additional_resources',
}

const AddLink = ({ addNew }: { addNew: () => Promise<void> }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <AddButton
      style={{ margin: '0px 0px 0px auto' }}
      disabled={loading}
      onClick={async () => {
        try {
          setLoading(true);
          await addNew();
        } finally {
          setLoading(false);
        }
      }}
    >
      Add Link
    </AddButton>
  );
};

const DeleteButton = ({ del }: { del: () => Promise<void> }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <button
      disabled={loading}
      style={{
        border: '1px solid ' + CB_COLORS.neutral300,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: loading ? CB_COLORS.neutral300 : 'white',
        opacity: loading ? 0.5 : 1,
        flex: '0 0 40px',
        height: '100%',
      }}
      onClick={async () => {
        try {
          setLoading(true);
          await del();
        } finally {
          setLoading(false);
        }
      }}
    >
      <Trash04 color={CB_COLORS.neutral600} width={16} height={16} />
    </button>
  );
};

const AdditionalResource = ({
  additionalResource,
  update,
  del,
}: {
  additionalResource: Partial<IAdditionalResource>;
  update: (recipe: (draft: IAdditionalResource) => void) => void;
  del: () => Promise<void>;
}) => {
  const { commands } = useAppContext();
  const { icon = null, icon_color = null, label } = additionalResource;

  return (
    <div
      style={{
        height: '48px',
        gap: 8,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '8px 0px',
        padding: '8px',
        backgroundColor: CB_COLORS.neutral0,
      }}
    >
      <DragHandle />
      <IconPicker
        icon={icon}
        color={icon_color}
        colors={getColorsFromCommands(commands)}
        onIconSelect={(icon) =>
          update((draft) => {
            draft.icon = icon;
          })
        }
        onColorSelect={(icon_color) =>
          update((draft) => {
            draft.icon_color = icon_color;
          })
        }
      />
      <Input
        value={label}
        onChange={(e) =>
          update((draft) => {
            draft.label = e.target.value;
          })
        }
        style={{ height: '100%', flex: '1 1 0%', fontSize: 14 }}
        placeholder="Label"
        size="small"
      />
      <div style={{ flex: 1, minWidth: 0 }}>
        <ActionEditor
          lightBorder
          label={false}
          button={{
            action: additionalResource.action,
            label: '__unused__',
          }}
          onBlockChange={(block) => {
            update((draft) => {
              if (block.meta?.action) {
                if (block.meta.action.type === 'no_action') return;
                draft.action = block.meta?.action;
              }
            });
          }}
        />
      </div>
      <DeleteButton del={del} />
    </div>
  );
};

const HelpHubSettings = () => {
  const {
    organization,
    dispatch: {
      organization: { updateSetting },
    },
    isStandaloneEditor,
  } = useAppContext();
  const isEditorOpen = useIsEditorOpen();

  const [activeKeys, setActiveKeys] = React.useState<PanelKey[]>([]);
  const { supportCTA, additionalResources, actions: supportActions } = useSupportCTA();
  const {
    fallbackAction,
    fallbackMessage,
    welcomeMessage,
    suggestedSearches,
    promptModifier,
    actions: chatActions,
  } = useChatSettings();

  const synTagRender = (cust_props: CustomTagProps) => {
    const { label, onClose } = cust_props;
    return (
      <Tag closable={true} onClose={onClose} style={{ whiteSpace: 'normal' }}>
        {label}
      </Tag>
    );
  };

  React.useEffect(() => {
    if (isStandaloneEditor || isEditorOpen) {
      Sender.openHelpHub();
    }
  }, [isEditorOpen]);

  return (
    <Container>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.GENERAL} header="General">
        <Title>
          Enable HelpHub{' '}
          <Switch
            checked={!!organization?.helphub_enabled}
            onChange={(value) => {
              updateSetting({ helphub_enabled: value });
            }}
          />
        </Title>
        <Title>
          Show Help Docs in Bar{' '}
          <Switch
            checked={!!organization?.in_bar_doc_search}
            onChange={(value) => {
              updateSetting({ in_bar_doc_search: value });
            }}
          />
        </Title>
        <Title>
          Show Featured Item Cards{' '}
          <Switch
            checked={!!organization?.featured_item_cards}
            onChange={(value) => {
              updateSetting({ featured_item_cards: value });
            }}
          />
        </Title>
        <Title>
          <span>
            Make docs private{' '}
            <a
              style={{ textDecoration: 'underline', fontSize: '12px' }}
              href="https://app.commandbar.com/identity-verification"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more here
            </a>{' '}
          </span>
          <Switch
            checked={!!organization?.force_end_user_identity_verification_for_helphub}
            onChange={(value) => {
              updateSetting({ force_end_user_identity_verification_for_helphub: value });
            }}
          />
        </Title>

        <AISettings organization={organization} updateSetting={updateSetting} />
      </Panel>

      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.CHAT} header="Chat">
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
          <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '4px' }}>System Prompt Modifier</div>
            <Tooltip content='Additional information to provide to the chat prompt. Here, you can specify things like "Respond in Spanish" or "Respond in a cheerful way".'>
              <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
            </Tooltip>
          </SubHeading>
          <StyledTextArea
            rows={2}
            name="prompt"
            value={promptModifier}
            onChange={(e) => chatActions.promptModifier.update(e.target.value)}
            placeholder=""
            style={{
              border: `1px solid ${CB_COLORS.neutral300}`,
            }}
          />
          <SubHeading>Welcome Message</SubHeading>
          <StyledTextArea
            rows={2}
            name="prompt"
            value={welcomeMessage}
            onChange={(e) => chatActions.welcomeMessage.update(e.target.value)}
            placeholder="Hey there! What can we help with?"
            style={{
              border: `1px solid ${CB_COLORS.neutral300}`,
            }}
          />

          <SubHeading>Suggested Searches</SubHeading>
          <StyledSelect
            key="suggested-searches-select"
            mode="tags"
            tagRender={synTagRender}
            value={suggestedSearches?.map((s) => ({ value: s, label: s }))}
            placeholder="Type a question and press enter"
            onChange={(tags: any) => chatActions.suggestedSearches.update(tags)}
            dropdownStyle={{ display: 'none' }}
          />

          <SubHeading>Fallback Message</SubHeading>
          <StyledTextArea
            rows={2}
            name="prompt"
            value={fallbackMessage}
            onChange={(e) => chatActions.fallbackMessage.update(e.target.value)}
            placeholder="I'm sorry, but I can't find an answer in the documentation for your question."
            style={{
              border: `1px solid ${CB_COLORS.neutral300}`,
            }}
          />

          <SubHeading>Fallback CTA</SubHeading>
          {fallbackAction ? (
            <Input
              value={fallbackAction.cta ?? ''}
              onChange={(e) =>
                chatActions.fallbackAction.update((draft) => {
                  draft.cta = e.target.value;
                })
              }
              style={{ height: '32px', fontSize: 14, border: `1px solid ${CB_COLORS.neutral300}` }}
              placeholder="Label"
              size="small"
            />
          ) : (
            <Skeleton.Input active />
          )}

          {fallbackAction ? (
            <ActionEditor
              lightBorder
              label={false}
              button={{
                action: fallbackAction.action,
                label: '__unused__',
              }}
              commandFilter={(command) => {
                if (command.template.type === 'helpdoc') {
                  // HACK: don't allow helpdoc commands to be used in the fallback CTA
                  // until we fix https://linear.app/commandbar/issue/ENG-3626/allow-helphub-additional-resources-to-link-to-hotloaded-help-docs
                  return false;
                }

                return true;
              }}
              onBlockChange={(block) => {
                chatActions.fallbackAction.update((draft) => {
                  if (block.meta?.action) {
                    if (block.meta.action.type === 'no_action') return;
                    draft.action = block.meta?.action;
                  }
                });
              }}
            />
          ) : (
            <Skeleton.Input active />
          )}
        </div>
      </Panel>

      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.LAUNCHER} header="Launcher">
        <HelpHubLauncherSettings />
      </Panel>

      <Panel
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        panelKey={PanelKey.ADDITIONAL_RESOURCES}
        header="Additional Resources"
      >
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
          <SubHeading>Links</SubHeading>
          {additionalResources ? (
            <>
              <SortableList
                style={{ margin: '-8px 0' }}
                nodes={additionalResources.map((item) => {
                  return (
                    <AdditionalResource
                      additionalResource={item}
                      del={async () => {
                        await supportActions.additionalResources.delete(item.id);
                      }}
                      update={(recipe) => {
                        supportActions.additionalResources.update(item, recipe);
                      }}
                      key={item.id}
                    />
                  );
                })}
                onSort={(oldIndex, newIndex) => {
                  supportActions.additionalResources.move(oldIndex, newIndex);
                }}
                useDragHandle
              />
              <div>
                <AddLink addNew={supportActions.additionalResources.addNew} />
              </div>
            </>
          ) : (
            <Skeleton active />
          )}
        </div>
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
          <SubHeading>Support CTA</SubHeading>

          {supportCTA ? (
            <Input
              value={supportCTA.label}
              onChange={(e) =>
                supportActions.supportCTA.update((draft) => {
                  draft.label = e.target.value;
                })
              }
              style={{ height: '32px', fontSize: 14 }}
              placeholder="Label"
              size="small"
            />
          ) : (
            <Skeleton.Input active />
          )}

          {supportCTA ? (
            <ActionEditor
              lightBorder
              label={false}
              button={{
                action: supportCTA.action,
                label: '__unused__',
              }}
              onBlockChange={(block) => {
                supportActions.supportCTA.update((draft) => {
                  if (block.meta?.action) {
                    if (block.meta.action.type === 'no_action') return;
                    draft.action = block.meta?.action;
                  }
                });
              }}
            />
          ) : (
            <Skeleton.Input active />
          )}
        </div>
      </Panel>
    </Container>
  );
};

export default HelpHubSettings;

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    height: 75px;
    display: block !important;
    overflow: auto;
    padding: 6px;
  }
  .ant-select-selection-placeholder {
    top: 16px !important;
  }
`;

const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 8px 0px;
`;
