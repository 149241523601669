/********************************************************************************/
/* Imports
/*******************************************************************************/

import * as t from 'io-ts';

import {
  createObject,
  updateObject,
  deleteObject,
  decodeToPromise,
  decodeThrowing,
  GenericBatchRequest,
  listObject,
  readObject,
  decodeToPromiseExact,
} from './generics';

import { omit } from './utils';

import { ArgumentMapV, ArgumentTypeV } from './helpers/argument';
import { TemplateV } from './helpers/commandTemplate';
import { CommandFromClientV } from './CommandFromClientV';
import {
  AvailabilityRuleV,
  EditorAvailabilityRuleV,
  EditorRecommendationRuleV,
  RecommendationRuleV,
  RuleExpressionV,
} from './helpers/rules';
import { GeneratedTagsV } from './helpers/tags';
import { DetailPreviewObjectV } from './detailPreview';
import { ConfettiConfigV } from './confetti';
import * as axiosInstance from './network';
import { ICommandType, ICommandTypeLite, IEditorCommandType, IEditorCommandTypeLite } from './types';
import { LabeledActionV } from './helpers/actions';
import { COMMANDS_ROUTE } from '../proxy-editor/editor_routes';
export { CommandFromClientV } from './CommandFromClientV';

const CommandBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.union([t.number, t.string]),
      text: t.string,
      template: TemplateV,
    }),
    t.partial({
      disabledReason: t.string,
      source: t.string,
      name: t.string,
      last_available: t.union([t.string, t.null]),
      modified: t.string,
      isAsync: t.boolean,
    }),
  ],
  'CommandBase',
);

const CommandAdditionalV = t.type(
  {
    arguments: ArgumentMapV,
    tags: t.array(t.string),

    // DEPRECATED
    availability_rules: t.array(AvailabilityRuleV),
    recommend_rules: t.array(RecommendationRuleV),

    availability_expression: t.union([RuleExpressionV, t.null]),
    recommend_expression: t.union([RuleExpressionV, t.null]),
    always_recommend: t.boolean,

    confirm: t.string,
    shortcut: t.array(t.string),
    explanation: t.string,
    heading: t.string,
    is_live: t.boolean,
    category: t.union([t.number, t.null]),
    sort_key: t.union([t.number, t.null]),
    icon: t.union([t.string, t.null]),
    icon_color: t.union([t.string, t.null]),
    image_color: t.union([t.string, t.null]),
    image: t.union([t.string, t.null]),
    celebrate: t.union([t.boolean, ConfettiConfigV, t.null]),
    recommend_sort_key: t.union([t.number, t.null]),
    shortcut_mac: t.array(t.string),
    shortcut_win: t.array(t.string),
    hotkey_mac: t.string,
    hotkey_win: t.string,
    detail: t.union([t.null, t.string, DetailPreviewObjectV, t.array(t.union([t.string, DetailPreviewObjectV]))]),
    content: t.union([t.null, t.string, DetailPreviewObjectV, t.array(t.union([t.string, DetailPreviewObjectV]))]),
    show_preview: t.boolean,
    next_steps: t.array(t.union([t.string, t.number, LabeledActionV])),
    extra: t.union([t.string, t.null]),
    thumbnail: t.union([
      t.null,
      t.type({
        src: t.string,
        file_name: t.string,
        size: t.string,
      }),
    ]),
    copilot_suggest: t.boolean,
    copilot_cta_label: t.string,
    copilot_description: t.string,
  },
  'CommandAdditional',
);

const EditorCommandAdditionalV = t.type(
  {
    arguments: ArgumentMapV,
    tags: t.array(t.string),

    availability_rules: t.union([t.array(EditorAvailabilityRuleV), t.array(AvailabilityRuleV)]),
    recommend_rules: t.union([t.array(EditorRecommendationRuleV), t.array(RecommendationRuleV)]),

    availability_expression: RuleExpressionV,
    recommend_expression: RuleExpressionV,
    always_recommend: t.boolean,

    confirm: t.string,
    shortcut: t.array(t.string),
    explanation: t.string,
    heading: t.string,
    is_live: t.boolean,
    category: t.union([t.number, t.null]),
    sort_key: t.union([t.number, t.null]),
    icon: t.union([t.string, t.null]),
    icon_color: t.union([t.string, t.null]),
    image_color: t.union([t.string, t.null]),
    image: t.union([t.string, t.null]),
    celebrate: t.union([t.boolean, ConfettiConfigV, t.null]),
    recommend_sort_key: t.union([t.number, t.null]),
    shortcut_mac: t.array(t.string),
    shortcut_win: t.array(t.string),
    hotkey_mac: t.string,
    hotkey_win: t.string,
    detail: t.union([t.null, t.string, DetailPreviewObjectV, t.array(t.union([t.string, DetailPreviewObjectV]))]),
    content: t.union([t.null, t.string, DetailPreviewObjectV, t.array(t.union([t.string, DetailPreviewObjectV]))]),
    show_preview: t.boolean,
    thumbnail: t.union([
      t.null,
      t.type({
        src: t.string,
        file_name: t.string,
        size: t.string,
      }),
    ]),
    next_steps: t.array(t.union([t.string, t.number, LabeledActionV])),
    generated_tags: GeneratedTagsV,
    copilot_suggest: t.boolean,
    copilot_cta_label: t.string,
    copilot_description: t.string,
  },
  'EditorCommandAdditional',
);

const defaults: t.TypeOf<typeof CommandAdditionalV> = {
  arguments: {},
  tags: [],
  recommend_rules: [],
  availability_rules: [],

  // if the new *_expression fields are not set, use the old *_rules ones
  availability_expression: null,
  recommend_expression: null,
  always_recommend: false,

  confirm: '',
  shortcut: [],
  explanation: '',
  heading: '',
  is_live: false,
  category: null,
  sort_key: null,
  icon: null,
  icon_color: null,
  image_color: null,
  image: null,
  celebrate: null,
  recommend_sort_key: null,
  shortcut_mac: [],
  shortcut_win: [],
  hotkey_mac: '',
  hotkey_win: '',
  detail: null,
  content: null,
  show_preview: false,
  thumbnail: null,
  next_steps: [],
  extra: null,
  copilot_suggest: false,
  copilot_cta_label: '',
  copilot_description: '',
};

const editorDefaults: t.TypeOf<typeof EditorCommandAdditionalV> = {
  ...defaults,

  availability_expression: { type: 'LITERAL', value: true },
  recommend_expression: { type: 'LITERAL', value: false },

  always_recommend: false,
  generated_tags: {},
};

const HelpSyncCommandAdditionalV = t.partial(
  {
    third_party_source: t.union([t.string, t.null]),
    third_party_id: t.union([t.string, t.null]),
    training_only: t.boolean,
    integration: t.union([
      t.type({
        id: t.number,
        helphub_view_article_button_hidden: t.union([t.boolean, t.undefined]),
      }),
      t.null,
    ]),
  },
  'CommandHelpSyncAdditional',
);

export const helpSyncDefaults: t.TypeOf<typeof HelpSyncCommandAdditionalV> = {
  third_party_source: '',
  third_party_id: '',
  training_only: false,
  integration: null,
};

const _CommandV = t.intersection([CommandBaseV, CommandAdditionalV, HelpSyncCommandAdditionalV], 'Command');
export const CommandV = new t.Type(
  _CommandV.name,
  _CommandV.is,
  (i: any, c) => _CommandV.validate({ ...defaults, ...i }, c),
  _CommandV.encode,
);

export const EditorCommandV = t.intersection(
  [CommandBaseV, EditorCommandAdditionalV, HelpSyncCommandAdditionalV],
  'EditorCommand',
);

export const CommandLiteV = t.intersection(
  [CommandBaseV, omit(CommandAdditionalV, ['content', 'detail', 'explanation']), HelpSyncCommandAdditionalV],
  'CommandLite',
);

export const EditorCommandLiteV = t.intersection(
  [CommandBaseV, omit(EditorCommandAdditionalV, ['content', 'detail', 'explanation']), HelpSyncCommandAdditionalV],
  'EditorCommandLite',
);

export const BatchOperationV = t.union([
  t.type({
    op: t.literal('update'),
    id: t.number,
    data: t.unknown,
  }),
  t.type({
    op: t.literal('create'),
    data: t.unknown,
  }),
  t.type({
    op: t.literal('delete'),
    id: t.number,
  }),
]);

export const BatchEditorCommandResponseV = t.type({
  batch: t.array(EditorCommandV),
});

export const create = createObject(EditorCommandV, EditorCommandV, COMMANDS_ROUTE);
export const update = updateObject(EditorCommandV, EditorCommandV, COMMANDS_ROUTE);
export const del = deleteObject(EditorCommandV, COMMANDS_ROUTE);
export const get = readObject(EditorCommandV, COMMANDS_ROUTE);
export const updatePartial = async (command: Pick<IEditorCommandType, 'id'> & Partial<IEditorCommandType>) => {
  const result = await axiosInstance.patch(`${COMMANDS_ROUTE}/${command.id}/`, command);

  return await decodeToPromiseExact(EditorCommandV, result.data);
};

export const batch = async (request: t.TypeOf<typeof GenericBatchRequest>) => {
  const result = await axiosInstance.post(`${COMMANDS_ROUTE}/batch/`, request);

  return await decodeToPromise(BatchEditorCommandResponseV, result.data);
};

export const validateFromClient = (data: any) => decodeToPromise(CommandFromClientV, data);

export const decode = (data: any): ICommandType => decodeThrowing(CommandV, data);

export const decodeEditorCommand = (data: t.TypeOf<typeof CommandBaseV> & { [key: string]: any }) => {
  const command: IEditorCommandType = { ...editorDefaults, ...data };

  return decodeThrowing(EditorCommandV, command);
};

// Passing in 'integration' as a query param bypasses API throttle
export const createWithoutThrottle = createObject(EditorCommandV, EditorCommandV, COMMANDS_ROUTE, {
  integration: 'true',
});
export const updateWithoutThrottle = updateObject(EditorCommandV, EditorCommandV, COMMANDS_ROUTE, {
  integration: 'true',
});
export const deleteWithoutThrottle = deleteObject(EditorCommandV, COMMANDS_ROUTE);

export const commandUID = (cmd: ICommandTypeLite | IEditorCommandTypeLite): string => {
  if (cmd.id >= 1) return `${cmd.id}`;
  return cmd.name ?? '';
};

export const isProgrammatic = (cmd: ICommandTypeLite | IEditorCommandTypeLite): boolean => {
  return cmd.source === 'programmatic' || cmd.id < 1;
};

export const isRecordAction = (cmd: ICommandTypeLite | IEditorCommandTypeLite, recordKey?: string): boolean => {
  const isRecordActionForArg = (arg: t.TypeOf<typeof ArgumentTypeV> | undefined) => {
    // Default to true if undefined
    return !!arg && arg.type === 'context' && !!(arg.show_in_record_action_list ?? true);
  };

  return !!Object.keys(cmd.arguments).find((k) => {
    if (!!recordKey) {
      return isRecordActionForArg(cmd.arguments[k]) && cmd.arguments[k]?.value === recordKey;
    } else {
      return isRecordActionForArg(cmd.arguments[k]);
    }
  });
};

export const isHelpSyncCommand = (cmd: ICommandTypeLite | IEditorCommandTypeLite): boolean => {
  return !!cmd.third_party_id;
};

export const showInDefaultList = (cmd: ICommandTypeLite | IEditorCommandTypeLite): boolean => {
  return (
    !isRecordAction(cmd) ||
    !!Object.keys(cmd.arguments).find((k) => {
      const arg = cmd.arguments[k];
      // Default to true if undefined
      return (
        arg.type === 'context' && !!(arg.show_in_record_action_list ?? true) && !!(arg.show_in_default_list ?? true)
      );
    })
  );
};

export const HelpSyncCommandV = t.intersection(
  [CommandBaseV, EditorCommandAdditionalV, HelpSyncCommandAdditionalV],
  'HelpSyncCommand',
);

export const listHelpCenter = listObject(HelpSyncCommandV, 'commands/helpdocs');
export const updateHelpSyncCommandWithoutThrottle = updateObject(HelpSyncCommandV, HelpSyncCommandV, 'commands', {
  integration: 'true',
});
