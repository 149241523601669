import React, { useCallback } from 'react';
import { Route, RouteComponentProps, Switch, useHistory, useParams } from 'react-router';

import { useAppContext } from '../../Widget';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { TabPane, Tabs } from '../../shared_components';

import { PaddingContainer, ScrollContainer } from '../Router';
import HelpHub from './HelpHub';
import { InstantAnswers } from '../instant-answers';
import HelpHubRecommendations from './HelpHubRecommendations';
import HelpHubSettings from './HelpHubSettings';
import Sender from '../../management/Sender';
import LogoutHeader from '../components/LogoutHeader';
import { ActiveCommand } from '../commands/ActiveCommand';
import { useIsEditorOpen } from 'editor/src/hooks';

export const HelpHubCommandDetail = () => {
  const params = useParams<{ commandId: string }>();
  const { dispatch, activeCommand, isStandaloneEditor } = useAppContext();
  const isEditorOpen = useIsEditorOpen();

  React.useEffect(() => {
    const commandId = parseInt(params.commandId);

    if (!!commandId && commandId >= 0) {
      dispatch.commands.setActiveCommandById(commandId);
    } else if (commandId === undefined) {
      dispatch.commands.setActive(undefined);
    }
  }, [params.commandId]);

  const openHelpHub = useCallback(
    (articleId: number | null) => {
      if (isStandaloneEditor || isEditorOpen) {
        Sender.openHelpHub({ articleId });
      }
    },
    [isEditorOpen],
  );

  React.useEffect(() => {
    if (activeCommand.state === 'editing') {
      openHelpHub(activeCommand.command.id);
    }
  }, [activeCommand.state, openHelpHub]);

  React.useEffect(() => {
    return () => {
      openHelpHub(null);
    };
  }, []);

  return <ActiveCommand isRecordAction={false} />;
};

type HelpHubEditorProps = RouteComponentProps<{ commandId: string }>;

const HelpHubEditor: React.FC<HelpHubEditorProps> = ({ match }) => {
  const history = useHistory();
  const { isStandaloneEditor, dispatch } = useAppContext();

  React.useEffect(() => {
    return () => {
      if (!isStandaloneEditor) {
        Sender.closeHelpHub();
      }

      dispatch.commands.setActive(undefined);
    };
  }, []);

  return (
    <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
      <Switch>
        <Route path={`${match.path}/edit/:commandId`}>
          <HelpHubCommandDetail />
        </Route>
        <Route exact path={`${match.path}/:page?`}>
          {({ match }) => {
            return (
              <>
                <LogoutHeader />
                <Tabs
                  activeKey={match?.params?.page ? match.url : editorRoutes.HELPHUB_ROUTE}
                  onChange={(key) => {
                    history.push(key);
                  }}
                  isStandaloneEditor={isStandaloneEditor}
                  destroyInactiveTabPane={true}
                  type="card"
                  tabBarStyle={{
                    paddingTop: isStandaloneEditor ? '9px' : 0,
                    marginTop: -10,
                    paddingLeft: '16px',
                    display: 'block',
                  }}
                >
                  <TabPane tab="Docs" key={editorRoutes.HELPHUB_ROUTE}>
                    <ScrollContainer>
                      <PaddingContainer>
                        <HelpHub />
                      </PaddingContainer>
                    </ScrollContainer>
                  </TabPane>
                  <TabPane tab="Answers" key={editorRoutes.INSTANT_ANSWERS_ROUTE}>
                    <ScrollContainer>
                      <PaddingContainer>
                        <InstantAnswers />
                      </PaddingContainer>
                    </ScrollContainer>
                  </TabPane>

                  <TabPane tab="Recommendations" key={editorRoutes.HELPHUB_RECOMMENDATIONS_ROUTE}>
                    <ScrollContainer>
                      <PaddingContainer>
                        <HelpHubRecommendations />
                      </PaddingContainer>
                    </ScrollContainer>
                  </TabPane>

                  <TabPane tab="Settings" key={editorRoutes.HELPHUB_SETTINGS_ROUTE}>
                    <ScrollContainer>
                      <PaddingContainer>
                        <HelpHubSettings />
                      </PaddingContainer>
                    </ScrollContainer>
                  </TabPane>
                </Tabs>
              </>
            );
          }}
        </Route>
      </Switch>
    </div>
  );
};

export default HelpHubEditor;
