/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';

import { useParams } from 'react-router';
import CommandList from './commands/CommandsTab/CommandList';
import Recommendations from './commands/RecommendationTab/Recommendations';
import Categories from './commands/CategoriesTab/Categories';
import { useAppContext } from '../Widget';
import {
  Tabs,
  TabPane,
  Alert,
  FeatureAnnouncementCard,
  Row,
  SimplePanel,
  PaddingContainer,
} from '../shared_components';
import * as Command from '@commandbar/internal/middleware/command';
import Rules from './commands/RulesTab/Rules';
import { useTabbedLayout } from '../hooks/useTabbedLayout';
import { IRuleExpression } from '@commandbar/internal/middleware/types';
import { ArgumentChoicesTable } from './context/ArgumentChoicesTable';
import Sender from '../management/Sender';
import { UpgradeCTA } from './components/UpgradeCTA';
import { ActiveCommand } from './commands/ActiveCommand';

export enum CommandsPageTab {
  ARGUMENTS = 'arguments',
  CATEGORIES = 'commands-categories',
  COMMANDS = 'commands-commands',
  RECOMMENDATIONS = 'commands-recommendations',
  RULES_CATEGORIES = 'rules-categories',
}

const Commands = () => {
  const params = useParams<{ commandId?: string }>();
  const [activeTab, setActiveTab] = useTabbedLayout(CommandsPageTab.COMMANDS, Object.values(CommandsPageTab));
  const { activeCommand, organization, organizationSettings, dispatch, commands, isStandaloneEditor } = useAppContext();

  const [hasAnalyticsRules, setHasAnalyticsRules] = React.useState(false);
  const [isAnalyticsDisabled, setIsAnalyticsDisabled] = React.useState(false);

  const end_user_analytics_keys = [
    'executions',
    'shortcuts',
    'last_seen',
    'first_seen',
    'sessions',
    'opens',
    'deadends',
  ];
  const detectAnalyticsRules = (expr: IRuleExpression): void => {
    switch (expr.type) {
      case 'AND':
      case 'OR': {
        expr.exprs.map((e) => detectAnalyticsRules(e));
        return;
      }
      case 'LITERAL': {
        return;
      }
      case 'CONDITION': {
        if (end_user_analytics_keys.some((e) => e === expr.condition.type)) {
          setHasAnalyticsRules(true);
        }
        return;
      }
    }
  };

  React.useEffect(() => {
    const commandId = params.commandId ? parseInt(params.commandId) : undefined;
    if (!!commandId && commandId >= 0) {
      dispatch.commands.setActiveCommandById(commandId);
    } else if (commandId === undefined) {
      dispatch.commands.setActive(undefined);
    }
  }, [params.commandId]);

  React.useEffect(() => {
    setHasAnalyticsRules(false);
    commands.every((command) => {
      const availability_expression = command.availability_expression;
      const recommend_expression = command.recommend_expression;
      detectAnalyticsRules(availability_expression);
      detectAnalyticsRules(recommend_expression);
      return true;
    });
  }, [commands]);

  Sender.isUserVerified().then((isEndUserVerified) => {
    setIsAnalyticsDisabled(
      !!organizationSettings?.silent_mode ||
        (!isStandaloneEditor && !isEndUserVerified && !!organization?.force_end_user_identity_verification),
    );
  });

  const onTabChange = (newKey: string) => {
    setActiveTab(newKey);
    dispatch.commands.setActive(undefined);
  };

  if (activeCommand.state === 'editing' || activeCommand.state === 'loading') {
    return <ActiveCommand isRecordAction={false} />;
  } else {
    return (
      <Tabs
        isStandaloneEditor={isStandaloneEditor}
        activeKey={activeTab}
        onChange={onTabChange}
        destroyInactiveTabPane={true}
        type="card"
        tabBarStyle={{
          paddingTop: isStandaloneEditor ? '9px' : 0,
          marginTop: -10,
          paddingLeft: '16px',
          display: activeCommand.state !== 'none' ? 'none' : 'inherit',
        }}
      >
        <TabPane tab={'Commands'} key={CommandsPageTab.COMMANDS} id={CommandsPageTab.COMMANDS}>
          <PaddingContainer>
            <FeatureAnnouncementCard
              identifier="bar-commands"
              title={<Row align="middle">Creating commands</Row>}
              docsLink="https://www.commandbar.com/docs/commands/overview"
            >
              <span>Enable your users to navigate around your app and perform actions by creating commands.</span>
            </FeatureAnnouncementCard>

            <UpgradeCTA product="commands" />

            <SimplePanel>
              {hasAnalyticsRules && isAnalyticsDisabled && (
                <Alert
                  message="Some of your commands are using conditions that are based on end-user analytics data. This data is not available on silent mode. Furthermore,  when Force identity verification is enabled, you must provide the correct HMAC when booting CommandBar. If this setting is on but an HMAC is not provided during boot, or the provided HMAC is not correct, analytics data will not be loaded."
                  type="warning"
                  showIcon
                  closable
                  style={{ marginBottom: 10 }}
                />
              )}

              <CommandList commands={commands.filter((c) => Command.showInDefaultList(c))} />
            </SimplePanel>
          </PaddingContainer>
        </TabPane>
        <TabPane tab={<span>Recommendations</span>} key={CommandsPageTab.RECOMMENDATIONS}>
          <PaddingContainer>
            <Recommendations />
          </PaddingContainer>
        </TabPane>
        <TabPane tab={<span>Categories</span>} key={CommandsPageTab.CATEGORIES}>
          <PaddingContainer>
            <Categories />
          </PaddingContainer>
        </TabPane>
        <TabPane tab={<span>Rules</span>} key={CommandsPageTab.RULES_CATEGORIES}>
          <PaddingContainer>
            <Rules />
          </PaddingContainer>
        </TabPane>
        <TabPane tab="Arguments" key={CommandsPageTab.ARGUMENTS}>
          <PaddingContainer>
            <ArgumentChoicesTable />
          </PaddingContainer>
        </TabPane>
      </Tabs>
    );
  }
};

export default Commands;
