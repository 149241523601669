import {
  ICondition,
  IEditorAvailabilityRule,
  IRuleOperator as OperatorType,
  operators,
} from '@commandbar/internal/middleware/helpers/rules';

export type { IAvailabilityRule } from '@commandbar/internal/middleware/helpers/rules';
export type { IRecommendationRule } from '@commandbar/internal/middleware/helpers/rules';
export type { IRule as ConditionRule } from '@commandbar/internal/middleware/helpers/rules';
export type { IRuleOperator as OperatorType } from '@commandbar/internal/middleware/helpers/rules';
export type { IAvailabilityRuleType as AvailabilityRuleType } from '@commandbar/internal/middleware/helpers/rules';
export type { IRecommendationRuleType as RecommendationRuleType } from '@commandbar/internal/middleware/helpers/rules';

export const ruleTypes: Array<{ value: IEditorAvailabilityRule['type']; label: string }> = [
  {
    value: 'url',
    label: 'Current URL Path',
  },
  {
    value: 'context',
    label: 'Metadata',
  },
  {
    value: 'element',
    label: 'DOM element',
  },
  {
    value: 'named_rule',
    label: 'Rule',
  },
  {
    value: 'executions',
    label: 'Commands Executed',
  },
  {
    value: 'shortcuts',
    label: 'Shortcuts Executed',
  },
  {
    value: 'opens',
    label: 'Opens',
  },
  {
    value: 'deadends',
    label: 'Deadends',
  },
  {
    value: 'sessions',
    label: 'Sessions',
  },
  {
    value: 'first_seen',
    label: 'First Seen',
  },
  {
    value: 'last_seen',
    label: 'Last Seen',
  },
];

export const operatorTypes = operators;

export enum AVAILABILITY_TYPE {
  Standard,
  Custom,
}

export const defaultConditionType = 'first_seen';

export const getDefaultConditionType = (category?: string) => {
  switch (category) {
    case 'Page':
      return 'url';
    case 'Custom':
      return 'context';
    case 'Activity':
      return 'first_seen';
    default:
      return defaultConditionType;
  }
};

export const defaultOperator = (ruleType: ICondition['type']): OperatorType => {
  switch (ruleType) {
    case 'device_type':
    case 'nudge_interaction':
    case 'questlist_interaction':
    case 'context':
      return 'is';
    case 'browser':
    case 'language':
    case 'os':
    case 'url':
      return 'includes';
    case 'element':
      return 'idOnPage';
    case 'executions':
      return 'isGreaterThan';
    case 'shortcuts':
      return 'isGreaterThan';
    case 'opens':
      return 'isGreaterThan';
    case 'deadends':
      return 'isGreaterThan';
    case 'sessions':
      return 'isGreaterThan';
    case 'first_seen':
      return 'isGreaterThan';
    case 'last_seen':
      return 'isGreaterThan';
    case 'heap':
      return 'isTrue';
  }
};

export interface IAvailabilityDependency {
  type: string;
  field: string;
  operator: string;
  message: JSX.Element;
}
