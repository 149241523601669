import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { IEditorCommandType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

import { useAppContext } from '../../Widget';
import { freshCommand } from '../useEditor';
import { useRouter } from '../../hooks';
import useWindowInfo from '../../hooks/useWindowInfo';
import { isRecordAction } from '@commandbar/internal/middleware/command';

const useLinkedCommand = () => {
  const { deeplink } = useRouter();
  const { hasRouter } = useWindowInfo();

  const { organization, dispatch } = useAppContext();

  const openExistingLinkedCommand = (command: IEditorCommandTypeLite) => {
    const route = isRecordAction(command) ? editorRoutes.RECORDS_ROUTE : editorRoutes.COMMANDS_ROUTE;
    deeplink({
      to: `${route}/${command.id}`,
      done: () => dispatch.commands.setActiveCommandById(command.id),
    });
  };

  const openNewLinkedCommand = (command: IEditorCommandType) => {
    const route = isRecordAction(command) ? editorRoutes.RECORDS_ROUTE : editorRoutes.COMMANDS_ROUTE;
    deeplink({
      to: `${route}/${command.id}`,
      done: () => dispatch.commands.setActive(command),
    });
  };

  const createLinkedCommand = (contextKey: string): IEditorCommandType => ({
    ...freshCommand(organization, undefined),
    arguments: {
      record: {
        type: 'context',
        value: contextKey,
        order_key: 1,
        label: 'Select from the list below',
        show_in_record_action_list: true,
        show_in_default_list: false,
      },
    },
    template: {
      type: 'link',
      value: '',
      operation: hasRouter ? 'router' : 'blank',
      commandType: 'object',
      object: contextKey,
    },
  });

  return { createLinkedCommand, openExistingLinkedCommand, openNewLinkedCommand };
};

export default useLinkedCommand;
