import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getConditions } from '@commandbar/internal/middleware/helpers/rules';
import { IChecklist, IChecklistItem } from '@commandbar/internal/middleware/types';
import styled from '@emotion/styled';
import _ from 'lodash';
import React from 'react';
import {
  Button,
  Collapse,
  Divider,
  Dropdown,
  FormRow,
  Input,
  Modal,
  SortableList,
  Space,
  StatusSwitch,
  SubHeading,
  Switch,
  Tooltip,
} from '../../shared_components';
import { CB_COLORS } from '@commandbar/design-system/components';
import { useAppContext } from '../../Widget';
import { isEveryConditionRuleValid } from '../commands/CommmandDetail/ConditionRulePanels/helpers';
import { SquareButton } from '../components/CommandDetailsHeader/styled';
import FormFactorStepDivider from '../components/FormFactorStepDivider';
import { SortableHandle } from 'react-sortable-hoc';
import GoalEditor from './GoalEditor';
import ActionEditor from './ActionEditor';
import {
  AddButton,
  DragIcon,
  PanelHeader,
  Prompt,
  DetailLink,
  DetailTabs,
  DetailTabPane,
  DetailTabPaneInner,
  OverlaySettingsSection,
  OverlaySettingsContainer,
  MenuIconContainer,
  OverlaySettingsOption,
  OverlayDisplayValue,
  OverlayCheckIconContainer,
  OverlaySettingsVerticalSection,
} from '../components/styled';
import Sender from '../../management/Sender';
import { useReportEvent } from '../../shared_components/useEventReporting';
import useWindowInfo from '../../hooks/useWindowInfo';
import { Container, DeleteButton, HeaderCol, HeaderRow } from '../../shared_components/form';
import { Trash04, Settings03, Check, Grid03, ArrowUpRight, InfoCircle } from '@commandbar/design-system/icons/react';
import Targeting from '../components/Targeting';
import { PreviewButton } from '../components/PreviewButton';
import { ShareLinkButton } from '../components/ShareLinkButton';
import { ReactComponent as CaretUp } from '../../img/caret_up.svg';
import { ReactComponent as CaretDown } from '../../img/caret_down.svg';
import useIsStandaloneEditor from '../../hooks/useIsStandaloneEditor';
import { UpgradeCTA } from '../components/UpgradeCTA';
import { useUsage } from '../../hooks/useUsage';
import { StyledTextArea } from '../helphub/shared';

const PaddingContainerSM = styled.div`
  padding: 8px 12px;
`;

const PageTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin: 2px 5px;
  color: #3d465d;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const Subheader2 = styled.h2`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: 8px;
  color: ${CB_COLORS.blue1000};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${CB_COLORS.neutral500};

  &:hover {
    color: ${CB_COLORS.neutral1000};
  }
`;

const Subheader3 = styled.h3`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${CB_COLORS.neutral700};
`;

const StyledHeader = styled.div`
  padding: 5px 15px;
`;

const { Panel } = Collapse;

const BackgroundPanel = styled(Panel)`
  & .ant-collapse-content {
    background-color: #f2f2f2;
  }
`;

const BorderedPanel = styled(Panel)`
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  overflow: hidden;
  transition: box-shadow 0.3s;

  &.ant-collapse-item-active {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  }

  &.ant-collapse-item-active .ant-collapse-header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  }

  & .ant-collapse-content {
    padding: 16px;
  }
`;

const PaddedCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    padding: 16px !important;
  }
`;

const DropdownStyled = styled(Dropdown)`
  position: absolute;
  top: 6px;
  right: 16px;
  z-index: 10;
`;

const DragHandle = SortableHandle(() => <DragIcon />);

interface ChecklistDetailProps {
  initialChecklist: IChecklist;
  setInitial: (checklist: IChecklist) => void;
  onClose: () => void;
  allChecklists: IChecklist[];
  onDelete: (id: number) => void;
}

const ChecklistDetail = (props: ChecklistDetailProps) => {
  const { onClose, initialChecklist } = props;

  const { dispatch, unsavedChangesRef, organizationSettings } = useAppContext();

  const [dirty, setDirty] = React.useState<IChecklist>({
    ...props.initialChecklist,
    _editorState: { timestamp: Date.now() },
  });
  const dirtyRef = React.useRef<IChecklist>(dirty);
  const [isSaving, setIsSaving] = React.useState(false);

  const { hasRouter } = useWindowInfo();

  const { reportEvent } = useReportEvent();
  const { exceeding } = useUsage();

  const isStandaloneEditor = useIsStandaloneEditor();
  const isNewChecklist = props.initialChecklist.id < 0;

  const possibleErrors: Array<{ condition: boolean; message: string }> = [
    {
      condition: !dirty.title && !dirty.description,
      message: 'Title and description must not both be empty.',
    },
    {
      condition: dirty.trigger.type === 'on_command_execution' && !dirty.trigger.meta.command,
      message: 'Trigger: Command must be set.',
    },
    {
      condition: dirty.trigger.type === 'on_event' && !dirty.trigger.meta.event,
      message: 'Trigger: Event name must be set.',
    },
    {
      condition: dirty.trigger.type === 'when_element_appears' && !dirty.trigger.meta.selector,
      message: 'Trigger: Element must be set.',
    },
    {
      condition: !isEveryConditionRuleValid(getConditions(dirty.show_expression)),
      message: 'Trigger: Some conditions are invalid.',
    },
    {
      condition: !isEveryConditionRuleValid(
        dirty.audience?.type === 'rule_expression' ? getConditions(dirty.audience.expression) : [],
      ),
      message: 'Custom Audience has invalid conditions.',
    },
  ];

  for (let i = 0; i < dirty.items.length; i++) {
    const item = dirty.items[i];

    possibleErrors.push(
      ...[
        {
          condition: !item.title && !item.description,
          message: `Item ${i + 1}: Title and description must not both be empty.`,
        },
        {
          condition: item.goal.type === 'command_executed' && !item.goal.meta.command,
          message: `Item ${i + 1} goal: Command must be set.`,
        },
        {
          condition: item.goal.type === 'element_clicked' && !item.goal.value,
          message: `Item ${i + 1} goal: Element to be clicked must be set.`,
        },
        {
          condition: item.goal.type === 'conditions_met' && getConditions(item.goal.expression).length === 0,
          message: `Item ${i + 1} goal: Conditions must be set.`,
        },
        {
          condition: item.goal.type === 'page_visited' && !item.goal.value,
          message: `Item ${i + 1} goal: Page location part must be set.`,
        },
        {
          condition: item.action.type === 'link' && !item.action.value,
          message: `Item ${i + 1} action: Url must be set.`,
        },
        {
          condition: item.action.type === 'click' && !item.action.value.length,
          message: `Item ${i + 1} action: Element to click must be set.`,
        },
        {
          condition: item.action.type === 'execute_command' && !item.action.meta.command,
          message: `Item ${i + 1} action: Command must be set.`,
        },
        {
          condition: item.action.type === 'nudge' && item.action.value < 0,
          message: `Item ${i + 1} action: Nudge must be set.`,
        },
        {
          condition:
            item.goal.type === 'conditions_met' && !isEveryConditionRuleValid(getConditions(item.goal.expression)),
          message: `Item ${i + 1} goal: Some conditions are invalid.`,
        },
        {
          condition: item.action.type === 'link' && item.action.operation === 'router' && !hasRouter,
          message: `Item ${i + 1} action: Link operation is set to "router", but there is no router function defined.`,
        },
      ].filter(({ condition }) => condition),
    );
  }

  const errors = possibleErrors.filter(({ condition }) => condition);

  const hasError = () => {
    return errors.length > 0;
  };
  const [activeKey, setActiveKey] = React.useState('1');
  const [activeItemKey, setActiveItemKey] = React.useState<number | undefined>(undefined);

  const onChange = async (c: IChecklist) => {
    c._editorState = { ...c._editorState, timestamp: Date.now() };
    dirtyRef.current = c;
    setDirty(c);
    unsavedChangesRef.current = 'true';
  };

  const onTargetingChange = async (changes: Partial<IChecklist>) => {
    const newChecklistState = { ...dirtyRef.current, ...changes };

    onChange(newChecklistState);
  };

  const onResetPreview = (changes: Partial<IChecklist>) => {
    const newChecklistState = { ...dirtyRef.current, ...changes };
    Sender.previewChecklist({ ...newChecklistState, _editorState: { timestamp: Date.now() } }, true);
  };

  const onItemChange = async <T extends keyof IChecklistItem>(field: T, value: IChecklistItem[T], index: number) => {
    const newVal = {
      ...dirtyRef.current,
      items: [...dirtyRef.current.items],
      _editorState: { initial_active_item: index },
    };
    newVal.items[index] = { ...newVal.items[index], [field]: value };
    reportEvent('questlist item edited', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        action: newVal.items[index].action,
        goal: newVal.items[index].goal,
      },
    });
    onChange(newVal);
  };

  const onBack = () => {
    if (unsavedChangesRef.current === 'true') {
      unsavedChangesRef.current = 'backModalShown';

      Modal.confirm({
        title: 'You have unsaved changes.',
        icon: <ExclamationCircleOutlined />,
        content: 'Leaving will discard these changes.',
        onOk() {
          unsavedChangesRef.current = 'false';
          onClose();
        },
        onCancel() {
          unsavedChangesRef.current = 'true';
        },
        okText: 'Discard changes',
        cancelText: 'Keep editing',
      });
    } else {
      unsavedChangesRef.current = 'false';
      onClose();
    }
  };

  const onSave = async () => {
    if (!hasError()) {
      setIsSaving(true);
      await dispatch.checklists.save(dirty).then((c) => props.setInitial(c));
    }
    setIsSaving(false);
    unsavedChangesRef.current = 'false';
  };

  React.useEffect(() => {
    Sender.previewChecklist(
      { ...dirty, _editorState: { ...dirty._editorState, expanded: dirty.open_by_default } },
      false,
    );
  }, [dirty]);

  React.useEffect(() => {
    return () => {
      Sender.stopChecklistPreview();
    };
  }, []);

  React.useEffect(() => {
    dirtyRef.current = props.initialChecklist;
    setDirty(dirtyRef.current);
  }, [props.initialChecklist]);

  const onSort = (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const newItems = [...dirty.items];
    const removed = newItems.splice(oldIndexOfMovedObj, 1)[0];

    newItems.splice(newIndexOfMovedObj, 0, removed);

    const newVal = {
      ...dirty,
      items: newItems,
    };

    onChange(newVal);
  };

  const addNewItem = () => {
    const newItems = [...dirty.items];
    newItems.push({
      id: -1,
      title: 'New item',
      description: '',
      action: { type: 'link', value: '', operation: 'self' },
      goal: { type: 'cta_clicked' },
      cta: 'Go',
      celebrate: false,
      skippable: false,
    });

    const payloadMessage = initialChecklist?.title
      ? `${initialChecklist.title} (ID: ${initialChecklist.id})`
      : `${initialChecklist.description} (ID: ${initialChecklist.id}`;
    reportEvent('questlist item created', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: payloadMessage,
      eventProps: {
        name: payloadMessage,
        action: 'link',
        goal: 'cta_clicked',
      },
    });

    onChange({
      ...dirty,
      items: newItems,
    });

    setActiveItemKey(newItems.length - 1);
  };

  const errorList = (_errors: Array<{ message: string }>) => (
    <ul
      style={{
        listStyleType: 'none',
        padding: 0,
      }}
    >
      {_errors.map(({ message }) => (
        <li key={message}>{message}</li>
      ))}
    </ul>
  );

  return (
    <Container>
      <HeaderRow justify="space-between" wrap={false} align="middle">
        <HeaderCol>
          <SquareButton style={{ width: '42px' }} onClick={onBack}>
            <ArrowLeftOutlined />
          </SquareButton>

          <PageTitle>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <PageTitle>{props.initialChecklist?.id !== -1 ? 'Edit questlist' : 'Create questlist'}</PageTitle>
            </div>
          </PageTitle>
        </HeaderCol>

        <Space>
          {dirty.id !== -1 && (
            <DeleteButton
              ghost
              onClick={() => props.onDelete(props.initialChecklist.id)}
              icon={<Trash04 width={16} />}
            />
          )}

          <Tooltip
            key={`right-tooltip-status-${dirty.id}`}
            content={
              exceeding.isAtOrOverLiveQuestlists ? (
                <span>You have hit your organization's maximum number of allowed live questlists</span>
              ) : (
                errorList(errors)
              )
            }
            showIf={!!hasError() || exceeding.isAtOrOverLiveQuestlists}
            placement="top"
          >
            <StatusSwitch
              checked={!!dirty.is_live}
              onChange={async (e: boolean) => {
                setIsSaving(true);

                await dispatch.checklists.save({ ...dirtyRef.current, is_live: e }).then((c) => props.setInitial(c));
                unsavedChangesRef.current = 'false';
                setIsSaving(false);

                if (e) {
                  reportEvent('questlist published', {
                    segment: true,
                    highlight: true,
                    slack: true,
                    payloadMessage: dirty.title,
                    eventProps: {
                      name: dirty.title,
                      item_count: dirty.items.length,
                    },
                  });
                }
              }}
              onLabel="Live"
              offLabel="Draft"
              disabled={!!hasError() || isSaving || (exceeding.isAtOrOverLiveQuestlists && !dirty.is_live)}
            />
          </Tooltip>

          <ShareLinkButton meta={{ type: 'questlist', details: dirty, save: onSave }} />
          <PreviewButton
            onClick={() => {
              Sender.hideEditor();
              Sender.previewChecklist({ ...dirty, _editorState: { timestamp: Date.now() } }, true);
            }}
          />

          <Tooltip showIf={!!hasError()} content={errorList(errors)} placement="left">
            <div>
              <Button
                type="primary"
                onClick={onSave}
                disabled={!!hasError() || (_.isEqual(props.initialChecklist, dirty) && !isNewChecklist) || isSaving}
              >
                Save
              </Button>
            </div>
          </Tooltip>
        </Space>
      </HeaderRow>

      <UpgradeCTA product="questlists" padding="0 16px" />

      <FlexColumn>
        <DropdownStyled
          trigger={['click']}
          placement="bottomLeft"
          overlay={
            <OverlaySettingsContainer width="288px">
              <OverlaySettingsSection
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Skippable
                <Switch
                  checked={dirty.skippable}
                  onChange={(checked) =>
                    onChange({
                      ...dirtyRef.current,
                      skippable: checked,
                    })
                  }
                />
              </OverlaySettingsSection>
              <OverlaySettingsSection
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Open by default
                <Switch
                  checked={dirty.open_by_default}
                  onChange={(checked) =>
                    onChange({
                      ...dirtyRef.current,
                      open_by_default: checked,
                    })
                  }
                />
              </OverlaySettingsSection>
              <OverlaySettingsSection
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Celebrate on completion 🎉
                <Switch
                  checked={dirty.celebrate}
                  onChange={(checked) =>
                    onChange({
                      ...dirtyRef.current,
                      celebrate: checked,
                    })
                  }
                />
              </OverlaySettingsSection>

              <Divider style={{ margin: '8px 0px' }} />

              <OverlaySettingsVerticalSection>
                <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '4px' }}>Start Page URL</div>
                  <Tooltip content="Users will be taken to this URL before the experience begins">
                    <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                  </Tooltip>
                </SubHeading>
                <Input
                  value={dirty.share_page_url}
                  onChange={(e) =>
                    onChange({
                      ...dirtyRef.current,
                      share_page_url: e.target.value,
                    })
                  }
                  placeholder={'Page URL...'}
                  style={{
                    border: `1px solid ${CB_COLORS.neutral300}`,
                  }}
                />
              </OverlaySettingsVerticalSection>

              {organizationSettings?.copilot_experiences_enabled && (
                <React.Fragment>
                  <Divider style={{ margin: '8px 0px' }} />

                  <OverlaySettingsSection
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SubHeading>Suggest in Copilot</SubHeading>
                    <Switch
                      checked={dirty.copilot_suggest}
                      onChange={() =>
                        onChange({
                          ...dirtyRef.current,
                          copilot_suggest: !dirtyRef.current.copilot_suggest,
                        })
                      }
                    />
                  </OverlaySettingsSection>

                  {dirty.copilot_suggest && (
                    <>
                      <OverlaySettingsVerticalSection>
                        <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginRight: '4px' }}>CTA Label</div>
                        </SubHeading>
                        <Input
                          value={dirty.copilot_cta_label}
                          onChange={(e) =>
                            onChange({
                              ...dirtyRef.current,
                              copilot_cta_label: e.target.value,
                            })
                          }
                          placeholder={`Show ${dirty.title}`}
                          style={{
                            border: `1px solid ${CB_COLORS.neutral300}`,
                          }}
                        />
                      </OverlaySettingsVerticalSection>
                      <OverlaySettingsVerticalSection>
                        <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginRight: '4px' }}>Description</div>
                          <Tooltip content="Copilot will use this to make smart suggestions to users.">
                            <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                          </Tooltip>
                        </SubHeading>
                        <StyledTextArea
                          value={dirty.copilot_description}
                          onChange={(e) =>
                            onChange({
                              ...dirtyRef.current,
                              copilot_description: e.target.value,
                            })
                          }
                          rows={2}
                          placeholder="Describe the Questlist and the outcome once completed"
                          style={{
                            border: `1px solid ${CB_COLORS.neutral300}`,
                          }}
                        />
                      </OverlaySettingsVerticalSection>
                    </>
                  )}
                </React.Fragment>
              )}
            </OverlaySettingsContainer>
          }
        >
          <MenuIconContainer
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Settings03 />
          </MenuIconContainer>
        </DropdownStyled>
        <DetailTabs destroyInactiveTabPane={false} type="card">
          <DetailTabPane tab={'Details'} key="tab-details" style={{ padding: '16px' }}>
            <DetailTabPaneInner>
              <div>
                <div style={{ paddingBottom: 75, width: '100%' }}>
                  <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Collapse
                      defaultActiveKey={activeKey}
                      activeKey={activeKey}
                      expandIconPosition="end"
                      onChange={() => setActiveKey(activeKey !== '1' ? '1' : '0')}
                      expandIcon={() => null}
                      style={{
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
                        background: activeKey === '1' ? '#FFFFFF' : '#F2F2F2',
                        borderRadius: '8px',
                      }}
                    >
                      <Panel
                        key="1"
                        header={
                          <StyledHeader>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                              <Subheader2>Questlist</Subheader2>
                              {activeKey === '1' ? (
                                <React.Fragment>
                                  <CaretUp style={{ color: CB_COLORS.neutral600, height: '32px' }} />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      gap: '8px',
                                      marginLeft: 'auto',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Tooltip
                                      showIf={isStandaloneEditor}
                                      content={'Set and preview questlist location inside your app'}
                                    >
                                      <Dropdown
                                        trigger={['click']}
                                        disabled={isStandaloneEditor}
                                        placement="bottomLeft"
                                        overlay={
                                          <OverlaySettingsContainer style={{ width: '150px' }}>
                                            <OverlaySettingsOption
                                              onClick={(e: any) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onChange({
                                                  ...dirtyRef.current,
                                                  position: 'bottomRight',
                                                });
                                              }}
                                            >
                                              <OverlayCheckIconContainer visible={dirty.position === 'bottomRight'}>
                                                <Check width={13} />
                                              </OverlayCheckIconContainer>
                                              Bottom Right
                                            </OverlaySettingsOption>
                                            <OverlaySettingsOption
                                              onClick={(e: any) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onChange({
                                                  ...dirtyRef.current,
                                                  position: 'bottomLeft',
                                                });
                                              }}
                                            >
                                              <OverlayCheckIconContainer visible={dirty.position === 'bottomLeft'}>
                                                <Check width={13} />
                                              </OverlayCheckIconContainer>
                                              Bottom Left
                                            </OverlaySettingsOption>
                                          </OverlaySettingsContainer>
                                        }
                                      >
                                        <OverlayDisplayValue
                                          gap={'6px'}
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                          }}
                                        >
                                          <IconContainer>
                                            <Grid03 width={12} />
                                          </IconContainer>
                                          {dirty.position === 'bottomLeft' ? 'Bottom Left' : 'Bottom Right'}
                                          <IconContainer>
                                            <CaretDown />
                                          </IconContainer>
                                        </OverlayDisplayValue>
                                      </Dropdown>
                                    </Tooltip>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <CaretDown style={{ color: CB_COLORS.neutral600, height: '32px' }} />
                              )}
                            </div>
                            <Subheader3>
                              {activeKey !== '1' && dirty.title ? `"${dirty.title}"...` : 'What gets shown?'}
                            </Subheader3>
                          </StyledHeader>
                        }
                      >
                        <PaddingContainerSM>
                          <FormRow
                            title="Title:"
                            input={
                              <Input
                                value={dirty.title}
                                onChange={(e) =>
                                  onChange({
                                    ...dirtyRef.current,
                                    title: e.target.value,
                                  })
                                }
                              />
                            }
                          />
                          <FormRow
                            title="Description:"
                            subtitle="(optional)"
                            input={
                              <Input
                                value={dirty.description}
                                onChange={(e) =>
                                  onChange({
                                    ...dirtyRef.current,
                                    description: e.target.value,
                                  })
                                }
                              />
                            }
                          />
                        </PaddingContainerSM>
                      </Panel>
                    </Collapse>

                    <FormFactorStepDivider />

                    <PaddedCollapse
                      onChange={() => setActiveKey(activeKey !== '2' ? '2' : '0')}
                      activeKey={activeKey}
                      expandIconPosition="end"
                      expandIcon={() => null}
                      style={{
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
                        background: activeKey === '2' ? '#FFFFFF' : '#F2F2F2',
                        borderRadius: '8px',
                      }}
                    >
                      <BackgroundPanel
                        key="2"
                        header={
                          <StyledHeader>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                              <Subheader2>Items </Subheader2>
                              {activeKey === '2' ? (
                                <CaretUp style={{ color: CB_COLORS.neutral600, height: '32px' }} />
                              ) : (
                                <CaretDown style={{ color: CB_COLORS.neutral600, height: '32px' }} />
                              )}
                            </div>
                            <Subheader3>{dirty.items.length} questlist items</Subheader3>
                          </StyledHeader>
                        }
                      >
                        <SortableList
                          nodes={dirty.items.map((item, index) => {
                            return (
                              <Collapse
                                key={index}
                                bordered={false}
                                expandIcon={() => null}
                                expandIconPosition="end"
                                style={{
                                  borderRadius: '8px',
                                  background: '#FFFFFF',
                                }}
                                activeKey={`${activeItemKey}-panel`}
                                onChange={() => {
                                  setActiveItemKey((activeItemKey) => (activeItemKey === index ? undefined : index));

                                  Sender.previewChecklist(
                                    { ...dirty, _editorState: { timestamp: Date.now(), initial_active_item: index } },
                                    false,
                                  );
                                }}
                              >
                                <BorderedPanel
                                  header={
                                    <PanelHeader style={{ height: '40px' }}>
                                      <DragHandle />
                                      <Prompt>
                                        {index + 1}: {item.title || ''}
                                        {`${index}-panel` === `${activeItemKey}-panel` ? (
                                          <CaretUp style={{ color: CB_COLORS.neutral600 }} />
                                        ) : (
                                          <CaretDown style={{ color: CB_COLORS.neutral600 }} />
                                        )}
                                      </Prompt>

                                      {`${index}-panel` === `${activeItemKey}-panel` && (
                                        <Dropdown
                                          trigger={['click']}
                                          placement="bottomLeft"
                                          overlay={
                                            <OverlaySettingsContainer width="254px">
                                              <OverlaySettingsSection
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                Skippable
                                                <Switch
                                                  checked={item.skippable}
                                                  onChange={(checked) => onItemChange('skippable', checked, index)}
                                                />
                                              </OverlaySettingsSection>
                                              <OverlaySettingsSection
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                Celebrate on completion 🎉
                                                <Switch
                                                  checked={item.celebrate}
                                                  onChange={(checked) => onItemChange('celebrate', checked, index)}
                                                />
                                              </OverlaySettingsSection>
                                            </OverlaySettingsContainer>
                                          }
                                        >
                                          <MenuIconContainer
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                          >
                                            <Settings03 />
                                          </MenuIconContainer>
                                        </Dropdown>
                                      )}

                                      <Button
                                        icon={<Trash04 width={15} />}
                                        ghost
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          const deleted = dirtyRef.current.items[index];
                                          const newItems = [...dirtyRef.current.items];
                                          newItems.splice(index, 1);

                                          const newVal = {
                                            ...dirtyRef.current,
                                            items: newItems,
                                            _editorState: { initial_active_item: index },
                                          };

                                          reportEvent('questlist item deleted', {
                                            segment: true,
                                            highlight: true,
                                            slack: true,
                                            eventProps: {
                                              action: deleted.action.type,
                                              goal: deleted.goal.type,
                                            },
                                          });

                                          onChange(newVal);
                                        }}
                                      />
                                    </PanelHeader>
                                  }
                                  key={`${index}-panel`}
                                >
                                  <FormRow
                                    title="Title:"
                                    key={`${item.id}-title`}
                                    input={
                                      <Input
                                        value={item.title}
                                        onChange={(e) => {
                                          onItemChange('title', e.target.value, index);
                                        }}
                                      />
                                    }
                                  />
                                  <FormRow
                                    title="Description:"
                                    subtitle="(optional)"
                                    input={
                                      <Input
                                        value={item.description}
                                        onChange={(e) => {
                                          onItemChange('description', e.target.value, index);
                                        }}
                                      />
                                    }
                                  />
                                  <Divider />

                                  <ActionEditor
                                    showRouterWarning={
                                      item.action.type === 'link' && item.action.operation === 'router' && !hasRouter
                                    }
                                    action={item.action}
                                    onActionChange={(action) => {
                                      onItemChange('action', action, index);
                                    }}
                                    cta={item.cta}
                                    onCTAChange={(cta) => {
                                      onItemChange('cta', cta, index);
                                    }}
                                  />
                                  <Divider />
                                  <GoalEditor
                                    goal={item.goal}
                                    onChange={(goal) => {
                                      onItemChange('goal', goal, index);
                                    }}
                                  />
                                </BorderedPanel>
                              </Collapse>
                            );
                          })}
                          onSort={onSort}
                          useDragHandle
                        />
                        <AddButton onClick={addNewItem}>Add item</AddButton>
                      </BackgroundPanel>
                    </PaddedCollapse>
                  </Space>
                </div>
              </div>
            </DetailTabPaneInner>
          </DetailTabPane>
          <DetailTabPane tab="Targeting" key="tab-targeting" style={{ padding: '16px' }}>
            <DetailTabPaneInner>
              <Targeting dirty={dirty} onChange={onTargetingChange} onResetPreview={onResetPreview} />
            </DetailTabPaneInner>
          </DetailTabPane>
          <DetailTabPane
            tab={
              <DetailLink
                href={`http://app.commandbar.com/analytics/questlists/${props.initialChecklist.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Analytics&nbsp;&nbsp;
                <ArrowUpRight width={14} height={14} />
              </DetailLink>
            }
            key="tab-analytics"
            disabled={true}
          />
        </DetailTabs>
      </FlexColumn>
    </Container>
  );
};

export default ChecklistDetail;
