/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';
import { useHistory } from 'react-router';
import { CaretRightOutlined, EllipsisOutlined } from '@ant-design/icons';
import { ICommandCategoryType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

import SortableTable from '../components/SortableTable';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import {
  renderOptionsColumn,
  renderHotkeysColumn,
  renderThirdPartySourceColumn,
  renderStatusColumn,
  renderTextColumn,
} from './CommandTableColumns';
import { useSessionSettingsContext } from '../../store/SessionSettingsProvider';
import { StickyHeaderCollapsePanel, Collapse } from '../../shared_components';
import { useContextPartition } from '../context/contextSettings/useContextPartition';
import { ICommandRow } from './types';
import { useAppContext } from '../../Widget';
import { COMMANDS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';

const CommandGroup = (props: {
  category: ICommandCategoryType | null;
  commands: IEditorCommandTypeLite[];
  unhealthyCommands: IEditorCommandTypeLite[];
  searchText: string;
  emptyActions: React.ReactNode;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const { categorySettings, updateCategorySettings } = useSessionSettingsContext();
  const { records } = useContextPartition();
  const appState = useAppContext();
  const { dispatch } = appState;

  const categoryID = props.category?.id || 'uncategorized';
  const currentCategorySettings = categorySettings[categoryID];

  const onCommandSort = async (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    setIsLoading(true);
    await dispatch.commands.reorder(oldIndexOfMovedObj, newIndexOfMovedObj, props.category?.id || null);
    setIsLoading(false);
  };

  const onPaginationChange = (pageNumber: number, pageSize?: number) => {
    updateCategorySettings({
      [categoryID]: {
        ...currentCategorySettings,
        page: pageNumber,
        size: pageSize,
      },
    });
  };

  const onCollapseChange = (keys: string[] | string) => {
    if (keys.length > 0 && currentCategorySettings?.isCollapsed) {
      updateCategorySettings({
        [categoryID]: {
          ...currentCategorySettings,
          isCollapsed: false,
        },
      });
    } else if (keys.length === 0 && !currentCategorySettings?.isCollapsed) {
      updateCategorySettings({
        [categoryID]: {
          ...currentCategorySettings,
          isCollapsed: true,
        },
      });
    }
  };

  const commandRows = React.useMemo(
    () =>
      props.commands
        .sort(compareObjs)
        .map((command, index): ICommandRow | null => {
          if (props.searchText.length > 0 && !command.text.toUpperCase().includes(props.searchText.toUpperCase())) {
            return null;
          } else {
            return {
              id: command.id,
              key: command.id,
              text: command.text,
              is_live: command.is_live,
              sort_key: command.sort_key,
              index,
              options: <EllipsisOutlined />,
              object: command,
              third_party_source: command.third_party_source,
            };
          }
        })
        .filter((row: ICommandRow | null): row is ICommandRow => row !== null),
    [props.commands, props.searchText],
  );

  const columns = [
    {
      title: 'Command',
      dataIndex: 'text',
      key: 'text',
      sorter: (a: ICommandRow, b: ICommandRow) => a.text.localeCompare(b.text),
      onCell: () => ({ style: { cursor: 'pointer' } }),
      render: (text: string, record: ICommandRow) => renderTextColumn(text, record.object, records, props.category),
      width: '62%',
      ellipsis: true,
    },
    {
      title: 'Shortcuts',
      align: 'center' as const,
      render: renderHotkeysColumn,
      width: '18%',
    },
    {
      title: 'Third Party',
      dataIndex: 'third_party_source',
      key: 'third_party_source',
      align: 'center' as const,
      render: (third_party_source: string | null | undefined) => {
        return renderThirdPartySourceColumn(third_party_source);
      },
      width: '18%',
    },
    {
      title: 'Status',
      dataIndex: 'is_live',
      key: 'is_live',
      align: 'center' as const,
      sorter: (a: ICommandRow, b: ICommandRow) => (a.is_live === b.is_live ? 0 : a.is_live ? -1 : 1),
      onCell: () => ({ style: { cursor: 'pointer' } }),
      render: (is_live: boolean, record: ICommandRow) => {
        return renderStatusColumn(is_live, record, props.unhealthyCommands);
      },
      width: '70px',
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (text: string, record: ICommandRow) =>
        // TODO: can this render as a normal React component and therefore
        //  use the useAppContext hook? if so, no need to pass appState here
        //  and then this would be e.g. <OptionsColumn isMaxLiveCommandsExceeded=... />
        renderOptionsColumn({
          record,
          appState,
        }),
      width: '35px',
    },
  ];

  if (commandRows.length === 0 && props.searchText.length > 0) return null;

  return (
    <div>
      <Collapse
        bordered={false}
        onChange={onCollapseChange}
        defaultActiveKey={[]}
        activeKey={!currentCategorySettings?.isCollapsed ? ['solo'] : []}
        ghost={true}
        expandIcon={() => null}
      >
        <StickyHeaderCollapsePanel
          header={
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#F5F5F5',
                padding: '0px 16px',
                minHeight: 35,
                borderRadius: 4,
                marginBottom: 5,
              }}
            >
              <span style={{ display: 'flex', alignItems: 'center', opacity: 0.6, fontSize: 11 }}>
                {props.leftActions}
                <CaretRightOutlined
                  style={{ marginLeft: '4px', fontSize: '12px' }}
                  rotate={currentCategorySettings?.isCollapsed ? 0 : 90}
                />
              </span>
              <span>{props.rightActions}</span>
            </div>
          }
          key={'solo'}
          style={{ border: 'none', padding: '0 0 5px' }}
        >
          {props.commands.length === 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>{props.emptyActions}</div>
          ) : (
            <SortableTable
              onSort={onCommandSort}
              className="category-table"
              showHeader={false}
              columns={columns}
              pagination={{
                defaultPageSize: 5,
                pageSize: currentCategorySettings?.size,
                size: 'small',
                showSizeChanger: true,
                // hide if greater than default page size
                hideOnSinglePage: commandRows.length <= 5,
                pageSizeOptions: ['5', '20', '50', '200'],
                current: currentCategorySettings?.page,
                onChange: onPaginationChange,
              }}
              dataSource={commandRows}
              onRow={(record) => {
                return {
                  onClick: (_event: any) => {
                    dispatch.commands.setActiveCommandById(record.object.id);
                    const element = document.getElementById('category-group_wrapper')?.scrollTop || 0;
                    window.sessionStorage.setItem('commandListScrollPosition', element.toString());
                    history.push(`${COMMANDS_ROUTE}/${record.object.id}${history.location.search}`);
                  },
                };
              }}
              loading={isLoading}
              sortDirections={['ascend', 'descend']}
            />
          )}
        </StickyHeaderCollapsePanel>
      </Collapse>
    </div>
  );
};

export default CommandGroup;
