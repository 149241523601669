import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';

export const RecordActionContainer = styled.div`
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${CB_COLORS.neutral0};
  height: 100%;
`;

export const FormRowContainer = styled.div`
  margin-left: 36px;
`;

export const ConditionsContainer = styled.div`
  margin-left: 24px;
  margin-right: 16px;
`;

export const TabTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TabBadge = styled.span`
  height: 16px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #706e7850;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  margin-left: 6px;
`;

export const DirtyIndicator = styled.div`
  height: 4px;
  width: 4px;
  margin: 0 4px;
  border-radius: 2px;
  background-color: ${CB_COLORS.primary};
`;
