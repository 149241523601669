import React from 'react';
import styled from '@emotion/styled';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import semverLt from 'semver/functions/lt';
import semverValid from 'semver/functions/valid';
import { checkIfValidBrowser, InvalidBrowserWarning, OldFoobarVersionWarning } from '../../pre-auth/compatibility';
import { Button, DropdownMenu, message } from '../../shared_components';
import { useAuthInfo } from '../../store/AuthProvider';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import Sender from '../../management/Sender';
import { useAppContext } from '../../Widget';
import { CB_COLORS } from '@commandbar/design-system/components';
import { useCallAsyncFunction } from '@commandbar/internal/util/useCallAsyncFunction';

const LogoutButton = styled(Button)`
  color: rgba(0, 0, 0, 0.45);
  padding: 0 5px !important;
  transition: color ease 0.3s;

  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
`;

const LogoutHeader = () => {
  const isValidBrowser = React.useMemo(checkIfValidBrowser, []);
  const { organization } = useAppContext();
  const { handleLogout } = useAuthInfo();
  const { hasUnreleasedEdits, isStandaloneEditor } = useAppContext();

  const teams =
    useCallAsyncFunction<any>(() => axiosInstance.get('/organizations/').then((response) => response.data)).result ||
    [];

  const switchTeams = async (team: IOrganizationType) => {
    try {
      await axiosInstance.patch('/profiles/switch_organization/', { org_uuid: team.id });
      window.location.reload();
    } catch (err) {
      message.error('Something went wrong...');
    }
  };

  const { result: foobarVersionInfo } = useCallAsyncFunction<
    | {
        latestFoobarVersion: string;
        usesLatestFoobarVersion: boolean;
      }
    | undefined
  >(async () => {
    try {
      const {
        data: { foobarVersion },
      } = await Sender.getConfiguration();

      if (!foobarVersion) {
        return;
      }
      const res = await fetch(`https://registry.npmjs.org/${encodeURIComponent('@commandbar/foobar')}`);
      const json = await res.json();
      const latestFoobarVersion = json['dist-tags']?.latest as string;

      const usesLatestFoobarVersion =
        Boolean(semverValid(foobarVersion)) &&
        Boolean(semverValid(latestFoobarVersion)) &&
        semverLt(foobarVersion, latestFoobarVersion);

      return { latestFoobarVersion, usesLatestFoobarVersion };
    } catch (err) {}
  });

  return isStandaloneEditor ? null : (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
          alignItems: 'center',
          background: 'rgb(242, 242, 242)',
          zIndex: 1,
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: 12,
            color: '#14141480',
            letterSpacing: '4%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {organization ? `${organization.name}` : ''} {!isValidBrowser && <InvalidBrowserWarning />}
          {foobarVersionInfo && !foobarVersionInfo.usesLatestFoobarVersion && (
            <OldFoobarVersionWarning latestVersion={foobarVersionInfo?.latestFoobarVersion} />
          )}
        </div>
        <div style={{ display: 'flex', height: 30, justifyContent: 'right', alignItems: 'center' }}>
          {teams.length > 1 ? (
            <DropdownMenu
              keyName="switch-team-dropdown-actions"
              placement="bottomLeft"
              items={teams.map((team: IOrganizationType) => ({
                name: team.name,
                disabled: team.id === organization?.id,
                icon: <UserOutlined />,
                onClick: () => switchTeams(team),
              }))}
            >
              <Button>Switch team</Button>
            </DropdownMenu>
          ) : null}

          <LogoutButton ghost onClick={handleLogout}>
            <LogoutOutlined style={{ margin: '0 4' }} /> Sign out
          </LogoutButton>
        </div>
      </div>
      {hasUnreleasedEdits && organization?.releases_enabled && (
        <div
          style={{
            padding: '6px 24px',
            background: CB_COLORS.blue100,
          }}
        >
          You have unreleased changes. Visit the Releases tab to make them available.
        </div>
      )}
    </>
  );
};

export default LogoutHeader;
