import React from 'react';
import debounce from 'lodash/debounce';
import produce from 'immer';

import { useReportEvent } from '../../shared_components/useEventReporting';
import { useAppContext } from '../../Widget';

import type { IOrganizationSettingsType } from '@commandbar/internal/middleware/types';

const useChatSettings = () => {
  const { organization, dispatch } = useAppContext();
  const { reportEvent } = useReportEvent();

  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const promptModifier = React.useRef<IOrganizationSettingsType['chat_system_prompt_modifier']>('');
  const welcomeMessage = React.useRef<IOrganizationSettingsType['helphub_chat_welcome_message']>('');
  const fallbackMessage = React.useRef<IOrganizationSettingsType['helphub_chat_fallback_message']>('');
  const fallbackAction = React.useRef<IOrganizationSettingsType['helphub_chat_fallback_actions'][number] | null>(null);
  const suggestedSearches = React.useRef<IOrganizationSettingsType['helphub_manual_suggested_queries'] | null>(null);

  React.useEffect(() => {
    const savedAction = organization?.helphub_chat_fallback_actions?.[0];

    (async () => {
      promptModifier.current = organization?.chat_system_prompt_modifier ?? '';
      welcomeMessage.current = organization?.helphub_chat_welcome_message ?? '';
      fallbackMessage.current = organization?.helphub_chat_fallback_message ?? '';
      suggestedSearches.current = organization?.helphub_manual_suggested_queries ?? [];

      fallbackAction.current = {
        cta: savedAction?.cta ?? '',
        action: savedAction?.action ?? {
          type: 'link',
          value: '',
          operation: 'blank',
        },
      };

      forceUpdate();
    })();
  }, []);

  const createOrUpdatePromptModifierDebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;

        const newOrgSettings = {
          chat_system_prompt_modifier: promptModifier.current,
        } as IOrganizationSettingsType;

        await dispatch.organization.updateSetting(newOrgSettings);

        reportEvent('chat prompt modifier updated', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${promptModifier.current}`,
        });
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };

    return debounce(doSave, 1500);
  }, [reportEvent, dispatch.organization.updateSetting]);

  const createOrUpdateWelcomeMessageDebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;

        const newOrgSettings = {
          helphub_chat_welcome_message: welcomeMessage.current,
        } as IOrganizationSettingsType;

        await dispatch.organization.updateSetting(newOrgSettings);

        reportEvent('chat welcome message updated', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${welcomeMessage.current}`,
        });
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };

    return debounce(doSave, 1500);
  }, [reportEvent, dispatch.organization.updateSetting]);

  const createOrUpdateFallbackMessageDebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;

        const newOrgSettings = {
          helphub_chat_fallback_message: fallbackMessage.current,
        } as IOrganizationSettingsType;

        await dispatch.organization.updateSetting(newOrgSettings);

        reportEvent('chat fallback message updated', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${fallbackMessage.current}`,
        });
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };

    return debounce(doSave, 1200);
  }, [reportEvent, dispatch.organization.updateSetting]);

  const createOrUpdateSuggestedSearchesDebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (!suggestedSearches.current) return;

        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;

        const newOrgSettings = {
          helphub_manual_suggested_queries: suggestedSearches.current,
        } as IOrganizationSettingsType;

        await dispatch.organization.updateSetting(newOrgSettings);

        reportEvent('chat suggested searches updated', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${suggestedSearches.current}`,
        });
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };

    return debounce(doSave, 1200);
  }, [reportEvent, dispatch.organization.updateSetting]);

  const createOrUpdateFallbackActionDebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (!fallbackAction.current) return;

        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;

        const newOrgSettings = {
          helphub_chat_fallback_actions: [fallbackAction.current],
        } as IOrganizationSettingsType;

        await dispatch.organization.updateSetting(newOrgSettings);

        reportEvent('chat fallback action updated', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${fallbackAction.current.cta}`,
        });
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };

    return debounce(doSave, 1200);
  }, [reportEvent, dispatch.organization.updateSetting]);

  return {
    promptModifier: promptModifier.current,
    welcomeMessage: welcomeMessage.current,
    fallbackMessage: fallbackMessage.current,
    fallbackAction: fallbackAction.current,
    suggestedSearches: suggestedSearches.current,

    actions: {
      promptModifier: {
        update: (draft: IOrganizationSettingsType['chat_system_prompt_modifier']): void => {
          promptModifier.current = draft;
          forceUpdate();
          createOrUpdatePromptModifierDebounced();
        },
      },
      welcomeMessage: {
        update: (draft: IOrganizationSettingsType['helphub_chat_welcome_message']): void => {
          welcomeMessage.current = draft;
          forceUpdate();
          createOrUpdateWelcomeMessageDebounced();
        },
      },
      fallbackMessage: {
        update: (draft: IOrganizationSettingsType['helphub_chat_fallback_message']): void => {
          fallbackMessage.current = draft;
          forceUpdate();
          createOrUpdateFallbackMessageDebounced();
        },
      },
      fallbackAction: {
        update: (recipe: (draft: IOrganizationSettingsType['helphub_chat_fallback_actions'][number]) => void): void => {
          fallbackAction.current = produce(fallbackAction.current, recipe);
          forceUpdate();
          createOrUpdateFallbackActionDebounced();
        },
      },
      suggestedSearches: {
        update: (draft: IOrganizationSettingsType['helphub_manual_suggested_queries']): void => {
          suggestedSearches.current = draft;
          forceUpdate();
          createOrUpdateSuggestedSearchesDebounced();
        },
      },
    },
  };
};

export default useChatSettings;
