import React from 'react';
import { Typography } from '../../../../shared_components';
import { defaultOperator, IAvailabilityDependency, IAvailabilityRule, OperatorType } from './types';
import {
  getConditions,
  ICondition,
  IEditorAvailabilityRule,
  IEditorRule,
  IMultiValueCondition,
  isCompoundExpression,
  isMultiValueRule,
  isMultiValueRuleType,
} from '@commandbar/internal/middleware/helpers/rules';
import { IEditorCommandType, INudgeType, IChecklist } from '@commandbar/internal/middleware/types';
import { BrowserType, DeviceType, PlatformType } from '@commandbar/internal/util/operatingSystem';

export const numericOperatorTypes: OperatorType[] = ['isLessThan', 'isGreaterThan'];
export const dateOperatorTypes: OperatorType[] = ['isBefore', 'isAfter'];
export const trueFalseOperatorTypes: OperatorType[] = ['isTrue', 'isFalse'];
export const booleanOperatorTypes: OperatorType[] = [
  'isTrue',
  'isFalse',
  'isTruthy',
  'isFalsy',
  'isDefined',
  'isNotDefined',
];
export const heapOperatorTypes: OperatorType[] = ['isTrue', 'isFalse'];
export const elementOperatorTypes: OperatorType[] = [
  'classnameOnPage',
  'idOnPage',
  'selectorOnPage',
  'classnameNotOnPage',
  'idNotOnPage',
  'selectorNotOnPage',
];
export const listOperatorTypes: OperatorType[] = ['includes', 'doesNotInclude'];
export const equalityOperatorTypes: OperatorType[] = ['is', 'isNot'];

export const getListOfAvailabilityDependencies = (command: IEditorCommandType): IAvailabilityDependency[] => {
  const dependencies: IAvailabilityDependency[] = [];

  // Click commands
  if (['click', 'clickBySelector', 'clickByXpath'].includes(command.template.type)) {
    if (Array.isArray(command.template.value)) {
      for (const elem in command.template.value) {
        dependencies.push({
          type: 'element',
          field: elem,
          operator: 'exists on the page',
          message: (
            <span>
              Element {<Typography.Text code>{elem}</Typography.Text>} must be present on the DOM for the command to be
              available.
            </span>
          ),
        });
      }
    }
  }

  if (command.template.type === 'callback') {
    dependencies.push({
      type: 'callback',
      field: String(command.template.value),
      operator: 'is defined',
      message: (
        <span>
          Callback {<Typography.Text code>{command.template.value}</Typography.Text>} must be provided for the command
          to be available.
        </span>
      ),
    });
  }

  for (const argName in command.arguments) {
    const argConfig = command.arguments[argName];

    if (argConfig?.type === 'context') {
      dependencies.push({
        type: 'context',
        field: argConfig?.value,
        operator: 'is an array',
        message: (
          <span>
            Context value {<Typography.Text code>{argConfig?.value}</Typography.Text>} must be defined and be a valid
            array of records for this command to be available.
          </span>
        ),
      });
    }
  }
  return dependencies;
};

export const isConditionRuleValid = (rule: IEditorRule) => {
  if (rule.type === 'always') {
    return true;
  }

  if (rule.type === 'named_rule') {
    return typeof rule.rule_id === 'string' || rule.rule_id >= 0;
  }

  const isTypeValid =
    (rule.type === 'context' && rule.field) ||
    rule.type === 'url' ||
    rule.type === 'element' ||
    rule.type === 'executions' ||
    rule.type === 'shortcuts' ||
    rule.type === 'last_seen' ||
    rule.type === 'first_seen' ||
    rule.type === 'sessions' ||
    rule.type === 'opens' ||
    rule.type === 'deadends' ||
    rule.type === 'heap' ||
    rule.type === 'nudge_interaction' ||
    rule.type === 'questlist_interaction' ||
    rule.type === 'device_type' ||
    rule.type === 'browser' ||
    rule.type === 'os' ||
    rule.type === 'language';

  return isTypeValid && rule.operator && isValidRuleValue(rule);
};

export const isUrlRuleValid = (rule: IEditorRule) =>
  rule.type === 'url' && rule.value ? !rule.value.includes('https://') && !rule.value.startsWith('http://') : true;

export const isEveryConditionRuleValid = (rules: IEditorRule[]) =>
  rules.every(isConditionRuleValid) && rules.every(isUrlRuleValid);

export const isValidRuleValue = (rule: IEditorRule) => {
  if (rule.type === 'named_rule') return true;
  const isBooleanOperator = rule.operator && booleanOperatorTypes.includes(rule.operator);

  // When using boolean operators (ie. isTruthy, isFalsey, etc.), an explicit value is not used so we can return true
  if (isBooleanOperator) {
    return true;
  }

  if (isMultiValueRule(rule)) {
    return rule.values.length > 0;
  }

  const { operator, value } = rule;

  const isNumericOperator = operator && numericOperatorTypes.includes(operator);
  const isNumber = !isNaN(Number(value));

  // Numeric operators need numeric values
  if (isNumericOperator) {
    return isNumber;
  }

  return !!value;
};

export const camelCaseToRegular = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');

  return (result.charAt(0) + result.slice(1)).toLowerCase();
};

export const negativeOperators: { [key in IAvailabilityRule['operator']]: string } = {
  is: 'is not',
  isNot: 'is',
  isTrue: 'is false',
  isFalse: 'is true',
  isTruthy: 'is falsy',
  isFalsy: 'is truthy',
  startsWith: 'does not start with',
  endsWith: 'does not end with',
  includes: 'does not include',
  doesNotInclude: 'includes',
  matchesRegex: 'does not match regex',
  isGreaterThan: 'is less than',
  isLessThan: 'is greater than',
  isAfter: 'is before',
  isBefore: 'is after',
  isDefined: 'is not defined',
  isNotDefined: 'is defined',
  classnameOnPage: 'classname',
  idOnPage: 'id',
  selectorOnPage: 'selector',
  classnameNotOnPage: 'classname not',
  idNotOnPage: 'id not',
  selectorNotOnPage: 'selector not',
};

export const pastTenseOperators: Partial<Record<IAvailabilityRule['operator'], string>> = {
  is: 'was',
  isNot: 'wasNot',
};

export const negativePastTenseOperators: Partial<Record<IAvailabilityRule['operator'], string>> = {
  is: 'was not',
  isNot: 'was',
};

export const invertAvailabilityRuleOperator = (operator: IAvailabilityRule['operator'], isPastTense = false) =>
  isPastTense ? pastTenseOperators[operator] ?? negativeOperators[operator] : negativeOperators[operator];

export const trimQuotes = (str: string) => str.replace(/(^["']|["']$)/gm, '');

export const countAvailabilityConditionsAndDependencies = (command: IEditorCommandType) => {
  const { availability_expression } = command;
  const alwaysAvailable =
    (isCompoundExpression(availability_expression) && availability_expression.exprs.length === 0) ||
    (availability_expression.type === 'LITERAL' && availability_expression.value === true);

  const dependencies = getListOfAvailabilityDependencies(command);

  return {
    alwaysAvailable: dependencies.length <= 0 && alwaysAvailable,
    dependencies,
    count: dependencies.length + getConditions(availability_expression).length,
  };
};

export const countRecommendationConditions = (command: IEditorCommandType) => {
  const { recommend_expression, always_recommend } = command;
  const conditions = getConditions(recommend_expression);

  const neverRecommend =
    !always_recommend && recommend_expression.type === 'LITERAL' && recommend_expression.value === false;

  const alwaysRecommend =
    always_recommend ||
    (recommend_expression.type === 'LITERAL' && recommend_expression.value) ||
    (isCompoundExpression(recommend_expression) && recommend_expression.exprs.length === 0);

  return {
    neverRecommend,
    alwaysRecommend,
    count: conditions.length,
  };
};

export const mkNewRule = (type: IEditorAvailabilityRule['type']): IEditorAvailabilityRule => {
  if (type === 'named_rule') {
    return { type, rule_id: -1 };
  } else if (type === 'nudge_interaction') {
    return { type, operator: 'is', value: 'viewed', nudge_id: -1 };
  } else if (type === 'questlist_interaction') {
    return { type, operator: 'is', value: 'viewed', questlist_id: -1 };
  }
  const operator = defaultOperator(type);

  if (isMultiValueRuleType(type)) {
    return { type, values: [], operator: operator as IMultiValueCondition['operator'] };
  }

  return { type, operator };
};

export const getInteractionErrorReason = (rule: IEditorRule, nudges: INudgeType[], checklists: IChecklist[]) => {
  if (rule.type === 'nudge_interaction') {
    const nudge = nudges.find((n) => n.id === rule.nudge_id && rule.nudge_id);

    if (!nudge) {
      return 'The selected Nudge does no longer exists.';
    } else if (rule.value === 'dismissed' && !nudge.dismissible) {
      return 'The selected Nudge is not dismissable';
    } else if (!nudge.is_live) {
      return 'The selected Nudge is not currently live.';
    }
  } else if (rule.type === 'questlist_interaction') {
    const checklist = checklists.find((c) => c.id === rule.questlist_id && rule.questlist_id);

    if (!checklist) {
      return 'The selected Questlist no longer exists.';
    } else if (rule.value === 'dismissed' && !checklist.skippable) {
      return 'The selected Questlist is not dismissable';
    } else if (!checklist.is_live) {
      return 'The selected Questlist is not currently live.';
    }
  }

  return null;
};

export const getConditionTypeSelectOptions = (type: ICondition['type']) => {
  switch (type) {
    case 'browser': {
      const options: { label: string; value: BrowserType }[] = [
        {
          label: 'Arc',
          value: 'arc',
        },
        {
          label: 'Brave',
          value: 'brave',
        },
        {
          label: 'Chrome',
          value: 'chrome',
        },
        {
          label: 'Edge',
          value: 'edge',
        },
        {
          label: 'Firefox',
          value: 'firefox',
        },
        {
          label: 'Opera',
          value: 'opera',
        },
        {
          label: 'Safari',
          value: 'safari',
        },
        {
          label: '< IE 10',
          value: 'ie10',
        },
        {
          label: 'IE 11',
          value: 'ie11',
        },
      ];

      return options;
    }
    case 'os': {
      const options: { label: string; value: PlatformType }[] = [
        { label: 'Android', value: 'android' },
        { label: 'iOS', value: 'ios' },
        { label: 'Linux', value: 'linux' },
        { label: 'Mac OS', value: 'mac' },
        { label: 'Windows', value: 'windows' },
      ];
      return options;
    }
    case 'language':
      return languageCodeOptions;
    case 'device_type': {
      const options: { label: string; value: DeviceType }[] = [
        { label: 'Desktop', value: 'desktop' },
        { label: 'Mobile', value: 'mobile' },
      ];
      return options;
    }
    case 'nudge_interaction':
    case 'questlist_interaction':
      return [
        { label: 'Viewed', value: 'viewed' },
        { label: 'Completed', value: 'completed' },
        { label: 'Dismissed', value: 'dismissed' },
      ];
    default:
      return undefined;
  }
};

const languageCodeOptions = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Arabic (Algeria)', value: 'ar-dz' },
  { label: 'Arabic (Bahrain)', value: 'ar-bh' },
  { label: 'Arabic (Egypt)', value: 'ar-eg' },
  { label: 'Arabic (Iraq)', value: 'ar-iq' },
  { label: 'Arabic (Jordan)', value: 'ar-jo' },
  { label: 'Arabic (Kuwait)', value: 'ar-kw' },
  { label: 'Arabic (Lebanon)', value: 'ar-lb' },
  { label: 'Arabic (Libya)', value: 'ar-ly' },
  { label: 'Arabic (Morocco)', value: 'ar-ma' },
  { label: 'Arabic (Oman)', value: 'ar-om' },
  { label: 'Arabic (Qatar)', value: 'ar-qa' },
  { label: 'Arabic (Saudi Arabia)', value: 'ar-sa' },
  { label: 'Arabic (Syria)', value: 'ar-sy' },
  { label: 'Arabic (Tunisia)', value: 'ar-tn' },
  { label: 'Arabic (U.A.E.)', value: 'ar-ae' },
  { label: 'Arabic (Yemen)', value: 'ar-ye' },
  { label: 'Basque', value: 'eu' },
  { label: 'Belarusian', value: 'be' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Chinese (Hong Kong)', value: 'zh-hk' },
  { label: 'Chinese (PRC)', value: 'zh-cn' },
  { label: 'Chinese (Singapore)', value: 'zh-sg' },
  { label: 'Chinese (Taiwan)', value: 'zh-tw' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch (Belgium)', value: 'nl-be' },
  { label: 'Dutch (Standard)', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'English (Australia)', value: 'en-au' },
  { label: 'English (Belize)', value: 'en-bz' },
  { label: 'English (Canada)', value: 'en-ca' },
  { label: 'English (Ireland)', value: 'en-ie' },
  { label: 'English (Jamaica)', value: 'en-jm' },
  { label: 'English (New Zealand)', value: 'en-nz' },
  { label: 'English (South Africa)', value: 'en-za' },
  { label: 'English (Trinidad)', value: 'en-tt' },
  { label: 'English (United Kingdom)', value: 'en-gb' },
  { label: 'English (United States)', value: 'en-us' },
  { label: 'Estonian', value: 'et' },
  { label: 'Faeroese', value: 'fo' },
  { label: 'Farsi', value: 'fa' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French (Belgium)', value: 'fr-be' },
  { label: 'French (Canada)', value: 'fr-ca' },
  { label: 'French (Luxembourg)', value: 'fr-lu' },
  { label: 'French (Standard)', value: 'fr' },
  { label: 'French (Switzerland)', value: 'fr-ch' },
  { label: 'Gaelic (Scotland)', value: 'gd' },
  { label: 'German (Austria)', value: 'de-at' },
  { label: 'German (Liechtenstein)', value: 'de-li' },
  { label: 'German (Luxembourg)', value: 'de-lu' },
  { label: 'German (Standard)', value: 'de' },
  { label: 'German (Switzerland)', value: 'de-ch' },
  { label: 'Greek', value: 'el' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  { label: 'Italian (Standard)', value: 'it' },
  { label: 'Italian (Switzerland)', value: 'it-ch' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kurdish', value: 'ku' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Macedonian (FYROM)', value: 'mk' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Malaysian', value: 'ms' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Norwegian (Bokmål)', value: 'nb' },
  { label: 'Norwegian (Nynorsk)', value: 'nn' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese (Brazil)', value: 'pt-br' },
  { label: 'Portuguese (Portugal)', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Rhaeto-Romanic', value: 'rm' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Romanian (Republic of Moldova)', value: 'ro-md' },
  { label: 'Russian', value: 'ru' },
  { label: 'Russian (Republic of Moldova)', value: 'ru-md' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Sorbian', value: 'sb' },
  { label: 'Spanish', value: 'es' },
  { label: 'Spanish (Argentina)', value: 'es-ar' },
  { label: 'Spanish (Bolivia)', value: 'es-bo' },
  { label: 'Spanish (Chile)', value: 'es-cl' },
  { label: 'Spanish (Colombia)', value: 'es-co' },
  { label: 'Spanish (Costa Rica)', value: 'es-cr' },
  { label: 'Spanish (Dominican Republic)', value: 'es-do' },
  { label: 'Spanish (Ecuador)', value: 'es-ec' },
  { label: 'Spanish (El Salvador)', value: 'es-sv' },
  { label: 'Spanish (Guatemala)', value: 'es-gt' },
  { label: 'Spanish (Honduras)', value: 'es-hn' },
  { label: 'Spanish (Mexico)', value: 'es-mx' },
  { label: 'Spanish (Nicaragua)', value: 'es-ni' },
  { label: 'Spanish (Panama)', value: 'es-pa' },
  { label: 'Spanish (Paraguay)', value: 'es-py' },
  { label: 'Spanish (Peru)', value: 'es-pe' },
  { label: 'Spanish (Puerto Rico)', value: 'es-pr' },
  { label: 'Spanish (Spain)', value: 'es-es' },
  { label: 'Spanish (Uruguay)', value: 'es-uy' },
  { label: 'Spanish (Venezuela)', value: 'es-ve' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Swedish (Finland)', value: 'sv-fi' },
  { label: 'Thai', value: 'th' },
  { label: 'Tsonga', value: 'ts' },
  { label: 'Tswana', value: 'tn' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Ukrainian', value: 'ua' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Venda', value: 've' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yiddish', value: 'ji' },
  { label: 'Zulu', value: 'zu' },
];
