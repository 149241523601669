import * as t from 'io-ts';
import {
  createObject,
  decodeThrowing,
  decodeToPromise,
  deleteObject,
  GenericBatchRequest,
  listObject,
  updateObject,
} from './generics';
import { ActionV } from './helpers/actions';

import * as axiosInstance from './network';
import { IAdditionalResource } from './types';

const AdditionalResourceBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.string,
      widget: t.string,
      sort_key: t.number,
      icon: t.union([t.string, t.null]),
      icon_color: t.union([t.string, t.null]),
      label: t.string,
      action: ActionV,
      show_as_primary_cta: t.boolean,
    }),
    t.partial({}),
  ],
  'AdditionalResourceBase',
);

const AdditionalResourceAdditionalV = t.type({}, 'AdditionalResourceAdditional');
type IAdditionalResourceAdditional = t.TypeOf<typeof AdditionalResourceAdditionalV>;

export const AdditionalResourceV = t.intersection(
  [AdditionalResourceAdditionalV, AdditionalResourceBaseV],
  'AdditionalResource',
);

const defaults: IAdditionalResourceAdditional = {};

export const BatchAdditionalResourceResponseV = t.type({
  batch: t.array(AdditionalResourceV),
});

export const decode = (data: any) => {
  const additionalResource: IAdditionalResource = { ...defaults, ...data };

  return decodeThrowing(AdditionalResourceV, additionalResource);
};

export const list = listObject(AdditionalResourceV, 'additional_resources');
export const create = createObject(AdditionalResourceV, AdditionalResourceV, 'additional_resources');
export const del = deleteObject(AdditionalResourceV, 'additional_resources');
export const update = updateObject(AdditionalResourceV, AdditionalResourceV, 'additional_resources');

export const batch = async (request: t.TypeOf<typeof GenericBatchRequest>) => {
  const result = await axiosInstance.post('additional_resources/batch/', request);

  return await decodeToPromise(BatchAdditionalResourceResponseV, result.data);
};
