import Auth, { useAuthorizationCode } from '@commandbar/internal/client/authentication';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { IUserType } from '@commandbar/internal/middleware/types';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import React, { Dispatch } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Sender from '../management/Sender';
import * as Sentry from '@sentry/react';
import { useIdentifyUser } from '../shared_components/useEventReporting';
import useIsStandaloneEditor from '../hooks/useIsStandaloneEditor';
import initSegment from '../shared_components/segment';
import { H } from 'highlight.run';

interface IAuthInfo {
  user: IUserType | undefined;
  setUser: Dispatch<IUserType | undefined>;
  handleLogin: (_token: string) => void;
  handleLogout: () => void;
  isAuthenticating: boolean;
}

const AuthInfo = React.createContext<IAuthInfo>({
  user: undefined,
  setUser: (_user?: IUserType) => null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleLogout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleLogin: (_token: string) => {},
  isAuthenticating: false,
});

const insideIFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const AuthProvider = ({ children }: { children: any }) => {
  const [user, setUser] = React.useState<IUserType | undefined>(undefined);
  const [access, setAccess] = React.useState(LocalStorage.get('access', '') as string);
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);

  const { identifyUser } = useIdentifyUser();
  const isStandaloneEditor = useIsStandaloneEditor();

  const authorization = useAuthorizationCode();
  const history = useHistory();

  const inIFrame = React.useMemo(insideIFrame, []);
  const devMode = React.useMemo(() => {
    return !!LocalStorage.get('dev', '');
  }, []);

  React.useEffect(() => {
    if (!inIFrame) {
      if (!!authorization.error) {
        LocalStorage.remove('access');
        LocalStorage.remove('refresh');
      } else if (!!authorization.access && !!authorization.refresh) {
        LocalStorage.set('access', authorization.access);
        LocalStorage.set('refresh', authorization.refresh);
      }

      if (!!authorization.finalRedirect) {
        setTimeout(() => {
          window.open(authorization.finalRedirect, '_self');
        }, 500);
      } else if (!!authorization.error) {
        setTimeout(() => {
          if (!window.location.href.includes('localhost')) {
            alert("Something went wrong... We'll redirect you back to commandbar.com");
            window.open('https://app.commandbar.com/login?error=ErrorAuthenticatingEditor', '_self');
          }
        }, 500);
      }
    }
  }, [authorization.access, authorization.error]);

  React.useEffect(() => {
    async function handleLogin() {
      if (inIFrame || devMode) {
        if (!!access) {
          LocalStorage.set('access', access);
          await login();
        } else {
          setUser(undefined);
        }
        hideLoader();
      }
    }

    handleLogin();
  }, [access]);

  const requestToken = async () => {
    const data = (await Sender.requestToken()) as any;

    if (!!data && !!data?.data && !!data.data?.access) {
      const { access, refresh } = data.data;
      LocalStorage.set('access', access);
      LocalStorage.set('refresh', refresh);
      setAccess(access);
    }
  };

  React.useEffect(() => {
    requestToken();
  }, []);

  // Notify of changes to tokens
  React.useEffect(() => {
    const handle = (e: any) => {
      if (!e?.detail) {
        return;
      }
      Sender.synToken(LocalStorage.get('access', '') as string, LocalStorage.get('refresh', '') as string);
    };

    document.addEventListener('CB_EDITOR_SYNC', handle);
    return () => {
      document.removeEventListener('CB_EDITOR_SYNC', handle);
    };
  }, []);

  const query = useQuery();

  React.useEffect(() => {
    const apiURL = query.get('api') ?? `${process.env.REACT_APP_API_URL}`;
    axiosInstance.setBaseURL(apiURL);
  }, [user]);

  const hideLoader = () => {
    // 1s delay to avoid flashing
    setTimeout(() => setIsAuthenticating(false), 1000);
  };

  const handleLogout = async () => {
    const refresh = LocalStorage.get('refresh', '');
    try {
      await axiosInstance.post('/auth/logout/', { refresh });
    } catch (err) {}

    LocalStorage.remove('access');
    LocalStorage.remove('refresh');
    Sender.logout();
    setUser(undefined);
    setAccess('');
    history.push('/login');
    Sender.shareUserAuthStatus(false);
  };

  const handleLogin = async (_token: string) => {
    setAccess(_token);
  };

  const login = async () => {
    const { success, user: newUser } = await Auth.user();

    if (!success) {
      handleLogout();
      return;
    }

    setUser(newUser);
    Sentry.setUser({ user: newUser });
    Sender.synToken(LocalStorage.get('access', '') as string, LocalStorage.get('refresh', '') as string);
    Sender.shareUserAuthStatus(true);
  };

  React.useEffect(() => {
    if (user !== undefined && isStandaloneEditor !== undefined) {
      if (!user?.email.includes('@commandbar.com')) {
        initSegment();
        if (!!isStandaloneEditor) {
          H.init('memo99d2', { recordCrossOriginIframe: true });
        } else {
          H.init('xdn5lld0');
        }
        identifyUser(user, {
          segment: true,
          highlight: true,
        });
        H.getSessionURL().then((sessionUrl) => {
          Sentry.setContext('highlight', {
            url: sessionUrl,
          });
        });
      }
    }
  }, [user, isStandaloneEditor]);

  /********************************************************************************/

  return (
    <AuthInfo.Provider value={{ user, setUser, handleLogout, handleLogin, isAuthenticating }}>
      {children}
    </AuthInfo.Provider>
  );
};

export const useAuthInfo = (): IAuthInfo => React.useContext(AuthInfo);

export default AuthProvider;
