import { Trash01, Copy03, Pin01 } from '@commandbar/design-system/icons/react';
import React from 'react';
import { Button, DropdownMenu, message, Modal, Tag } from '../../shared_components';
import SkinDetail from './SkinDetail';
import SkinPreview from './SkinPreview';
import { PlusOutlined } from '@ant-design/icons';
import { Skin } from '@commandbar/internal/middleware/skin';
import { ISkinType } from '@commandbar/internal/middleware/types';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import Sender from '../../management/Sender';
import { useReportEvent } from '../../shared_components/useEventReporting';
import { useAppContext } from '../../Widget';
import { useHistory } from 'react-router';
import { widgetRoutes } from '@commandbar/internal/proxy-editor/editor_routes';

const SkinList = () => {
  const history = useHistory();
  const [skins, setSkins] = React.useState<ISkinType[]>([]);
  const [activeSkin, setActiveSkin] = React.useState<ISkinType | null>(null);

  const { reportEvent } = useReportEvent();

  const { unsavedChangesRef } = useAppContext();

  React.useEffect(() => {
    if (widgetRoutes.some((r: string) => history.location.pathname.includes(r))) {
      setActiveSkin(skins.filter((skin: ISkinType) => skin.default)[0] || null);
    }
  }, [skins]);

  React.useEffect(() => {
    fetchSkins();
  }, []);

  const fetchSkins = async () => {
    const _skins = await Skin.list();
    setSkins(_skins);
    return _skins;
  };

  const setActive = (skin: any) => {
    if (!!skin) {
      setActiveSkin(skin);
    } else {
      setActiveSkin(null);
    }
  };

  const makeDefault = async (slug: string) => {
    try {
      await axiosInstance.patch(`skins/${slug}/`, { default: true });

      fetchSkins();

      Sender.setTheme('reset');
      Sender.setTheme(slug);

      reportEvent('skin made default', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: slug,
      });

      return;
    } catch (err) {
      message.error(JSON.stringify(err));
      return;
    }
  };

  const createSkin = async () => {
    try {
      const skin = await Skin.create({});

      setActiveSkin(skin);
      previewSkin(skin?.slug);
      fetchSkins();
      reportEvent('skin created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: skin?.slug,
      });
    } catch (err) {
      message.error(JSON.stringify(err));
    }
  };

  const duplicateSkin = async (origin: ISkinType) => {
    const getUniqSlugAndId = (origin: ISkinType, existingSkins: ISkinType[]): { id: string; slug: string } => {
      const idTemplate = `copy-of-${origin.id}`;
      const slugTemplate = `copy-of-${origin.slug}`;

      if (existingSkins.findIndex(({ id, slug }: ISkinType) => id === idTemplate || slug === slugTemplate) !== -1) {
        for (let i = 1; i <= existingSkins.length; i++) {
          if (
            existingSkins.findIndex(
              ({ id, slug }: ISkinType) => id === `${idTemplate}-${i + 1}` || slug === `${slugTemplate}-${i + 1}`,
            ) === -1
          ) {
            return {
              id: `${idTemplate}-${i + 1}`,
              slug: `${slugTemplate}-${i + 1}`,
            };
          }
        }
      }

      return {
        id: idTemplate,
        slug: slugTemplate,
      };
    };

    try {
      const { id, slug } = getUniqSlugAndId(origin, skins);

      // We do this since for a new user, the default is {}
      // if "skin" is empty, the backend will pick some random colors for the new skin
      // When duplicating an existing skin, we don't want random colors.
      const skin_ = Object.keys(origin.skin).length === 0 ? { base: {} } : origin.skin;

      const skin = await Skin.create({
        id,
        slug,
        logo: origin.logo || '',
        name: `Copy of ${origin.name}`,
        skin: skin_,
      });

      setActiveSkin(skin);
      previewSkin(skin?.slug);
      await fetchSkins();

      reportEvent('skin duplicated', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: skin.slug,
      });
    } catch (err) {
      message.error(JSON.stringify(err));
    }
  };

  const deleteSkin = async (slug: string) => {
    try {
      await Skin.delete(slug);
      fetchSkins();
      reportEvent('skin deleted', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: slug,
      });
    } catch (err) {
      message.error(JSON.stringify(err));
    }
  };

  const updateActive = (skin: ISkinType) => {
    setActiveSkin(skin);
    fetchSkins();
  };

  const previewSkin = async (skin: string | Pick<ISkinType, 'logo' | 'skin'>, openBar = true) => {
    Sender.setTheme('reset');
    Sender.setTheme(skin);
    const { isOpen } = (await Sender.shareIsOpen()) as { isOpen?: boolean };
    if (!isOpen && openBar) {
      Sender.openBar();
    }
  };

  const getDefaultSlug = React.useCallback(() => {
    const defaultSkin = skins.find((skin: ISkinType) => {
      return !!skin.default;
    });

    return defaultSkin?.slug ?? '';
  }, [skins]);

  return (
    <div>
      <div>
        {skins.map((skin: any) => {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 0px' }}
              key={`skin-${skin.slug}`}
            >
              <SkinPreview
                skin={skin}
                onClick={() => {
                  setActive(skin);
                  previewSkin(skin?.slug);
                }}
              />
              <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                {skin.default && <Tag>Default skin</Tag>}
                <DropdownMenu
                  keyName="options"
                  items={[
                    {
                      name: 'Duplicate',
                      icon: <Copy03 width={14} height={14} />,
                      onClick: () => duplicateSkin(skin),
                    },
                    ...(skin.default
                      ? []
                      : [
                          {
                            name: 'Make default',
                            icon: <Pin01 width={14} height={14} />,
                            onClick: () => makeDefault(skin.slug),
                          },
                          {
                            name: 'Delete',
                            icon: <Trash01 width={14} height={14} />,
                            onClick: () =>
                              Modal.confirm({
                                title: 'Are you sure to delete this skin? This cannot be undone.',
                                async onOk() {
                                  await deleteSkin(skin.slug);
                                },
                              }),
                          },
                        ]),
                  ]}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Button icon={<PlusOutlined />} onClick={createSkin} style={{ marginTop: '16px' }}>
        Create a new skin
      </Button>
      {activeSkin ? (
        <SkinDetail
          skin={activeSkin}
          onClose={() => {
            const defaultSlug = getDefaultSlug();
            previewSkin(defaultSlug);
            setActive(null);
          }}
          onUpdate={updateActive}
          getDefaultSlug={getDefaultSlug}
          previewSkin={previewSkin}
          allSkins={skins}
          unsavedChangesRef={unsavedChangesRef}
        />
      ) : null}
    </div>
  );
};

export default SkinList;
