import styled from '@emotion/styled';
import { ReactComponent as Drag } from '../../img/drag.svg';
import { Button, TabPane, Typography } from '../../shared_components';
import { CB_COLORS } from '@commandbar/design-system/components';
import { FlatStyledTabs } from '../../shared_components/Tab';

export const BorderedContainer = styled.div`
  padding: 8px 12px;
  border: 1px solid ${CB_COLORS.neutral300};
  border-radius: 4px;
`;

export const AddButton = styled(Button)`
  &&& {
    display: flex;
    margin: 0 auto;
  }
`;

export const Prompt = styled(Typography.Text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  gap: 8px;
`;

export const DragIcon = styled(Drag)`
  margin-right: 16px;
  cursor: grab;
`;

export const PanelHeader = styled.div`
  height: 48px;
  display: flex;
  flex: 1;
  align-items: center;
  background: #ffffff;
  padding: 0 16px;
  gap: 8px;
`;

export const DetailTabs = styled(FlatStyledTabs)`
  display: flex;
  flex: 1;

  && .ant-tabs-nav-wrap {
    border-radius: 4px 4px 0 0;
  }

  && .ant-tabs-nav {
    padding: 8px 16px 0 16px;
  }

  && .ant-tabs-tab {
    padding: 8px 16px;
    border-bottom: 1px solid transparent !important;

    color: ${CB_COLORS.neutral600};
    font-size: 14px;
    line-height: 16px;
  }

  && .ant-tabs-tab-active {
    border-bottom: 1px solid black !important;
    font-weight: 500;
    color: black;
  }
`;

export const DetailLink = styled.a`
  cursor: pointer;
  color: ${CB_COLORS.neutral600};
  &:hover {
    color: ${CB_COLORS.neutral800};
  }
`;

export const DetailTabPane = styled(TabPane)`
  height: 100%;
  background: ${CB_COLORS.neutral0};
  border: 0.5px solid rgba(16, 16, 16, 0.1);
  border-radius: 0 0 8px 8px;
  padding: 16px;
`;
export const DetailTabPaneInner = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 16px;
`;

export const OverlaySettingsContainer = styled.div<{ width?: string }>`
  background-color: ${CB_COLORS.white};
  color: ${CB_COLORS.neutral800};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding: 8px 4px;
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '150px')};
`;

export const OverlaySettingsSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  color: ${CB_COLORS.neutral800};
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  gap: 8px;
`;

export const OverlaySettingsVerticalSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  color: ${CB_COLORS.neutral800};
  font-size: 14px;
  line-height: 16px;
  gap: 8px;
`;

export const OverlaySettingsOption = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  box-sizing: border-box;
  font-weight: 500;
  color: ${(props) => (props.disabled ? CB_COLORS.neutral500 : CB_COLORS.neutral800)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  transition: 0.3s all;

  &:hover {
    background-color: ${CB_COLORS.neutral100};
  }
`;

export const OverlayDisplayValue = styled.div<{ gap?: React.CSSProperties['gap']; disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: ${({ gap }) => gap};
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  background-color: ${CB_COLORS.white};
  border-radius: 4px;

  transition: 0.3s all;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  user-select: none;

  // only affects the arrow icon as it's fill is set to currentColor
  svg {
    color: ${CB_COLORS.neutral500};
  }

  & > svg {
    color: ${CB_COLORS.neutral1000};
  }

  ${({ disabled }) =>
    !disabled &&
    `
    &:hover {
      background-color: ${CB_COLORS.neutral0};

      svg {
        color: #000;
      }
    }
  `}
`;

export const MenuIconContainer = styled.div`
  padding: 8px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  transition: all 0.3s;

  color: ${CB_COLORS.neutral500};

  &:hover {
    background-color: ${CB_COLORS.neutral0};
    color: #000;
  }
`;

export const OverlayCheckIconContainer = styled.div<{ visible?: boolean }>`
  margin-right: 10px;
  display: flex;
  align-items: center;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;
