import { INudgeType } from '@commandbar/internal/middleware/types';

export const emptyNudge: INudgeType = {
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: '',
      content: [
        { type: 'markdown', meta: { value: '' } },
        { type: 'button', meta: { action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'modal',
      },
    },
  ],
  slug: '',
  template_source: 'none',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: false,
  dismissible: true,
  is_live: false,
  share_page_url: '',
  copilot_suggest: false,
  copilot_cta_label: '',
  copilot_description: '',
};

export const announcementNudge: INudgeType = {
  ...emptyNudge,
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Announcement',
      content: [
        { type: 'markdown', meta: { value: '' } },
        { type: 'button', meta: { action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'modal',
      },
    },
  ],
  slug: '',
  template_source: 'announcement',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: false,
  dismissible: true,
  is_live: false,
  share_page_url: '',
};

export const featureHighlightNudge: INudgeType = {
  ...emptyNudge,
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Feature highlight',
      content: [
        { type: 'markdown', meta: { value: '' } },
        { type: 'button', meta: { action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'pin',
        anchor: '',
        is_open_by_default: true,
        offset: {
          x: '0px',
          y: '0px',
        },
      },
    },
  ],
  slug: '',
  template_source: 'feature_highlight',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: false,
  dismissible: true,
  is_live: false,
  share_page_url: '',
};

export const upgradeNudge: INudgeType = {
  ...emptyNudge,
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Go Premium',
      content: [
        {
          type: 'markdown',
          meta: {
            value: 'Try Premium – all our best features, free for 14 days.',
          },
        },
        {
          type: 'button',
          meta: {
            label: 'Start your trial',
            action: { type: 'no_action' },
          },
        },
      ],
      is_live: false,
      form_factor: {
        type: 'popover',
        position: 'top-left',
      },
    },
  ],
  slug: '',
  template_source: 'upgrade_nudge',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: false,
  dismissible: true,
  is_live: false,
  share_page_url: '',
};

export const simpleNotification: INudgeType = {
  ...emptyNudge,
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Notification',
      content: [
        { type: 'markdown', meta: { value: '' } },
        { type: 'button', meta: { action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'popover',
        position: 'top-right',
      },
    },
  ],
  slug: '',
  template_source: 'simple_notification',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: false,
  dismissible: true,
  is_live: false,
  share_page_url: '',
};

export const productTour: INudgeType = {
  ...emptyNudge,
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Step 1',
      content: [
        { type: 'markdown', meta: { value: '' } },
        { type: 'button', meta: { label: 'Continue', action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'popover',
        position: 'top-left',
      },
    },
    {
      id: -2,
      title: 'Step 2',
      content: [
        { type: 'markdown', meta: { value: '' } },
        { type: 'button', meta: { label: 'Done', action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'pin',
        anchor: '',
        is_open_by_default: true,
        offset: {
          x: '0px',
          y: '0px',
        },
      },
    },
  ],
  slug: '',
  template_source: 'tour',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: true,
  dismissible: true,
  is_live: false,
  share_page_url: '',
};

export const feedback: INudgeType = {
  ...emptyNudge,
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Feedback',
      content: [
        { type: 'markdown', meta: { value: '' } },
        { type: 'survey_text', meta: { prompt: 'How can we make FeatureX twice as good?' } },
        { type: 'button', meta: { label: 'Share Feedback', action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'popover',
        position: 'center',
      },
    },
  ],
  slug: '',
  template_source: 'feedback',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: true,
  dismissible: true,
  is_live: false,
  share_page_url: '',
};

export const rating: INudgeType = {
  ...emptyNudge,
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'How are we doing?',
      content: [
        { type: 'markdown', meta: { value: '' } },
        {
          type: 'survey_rating',
          meta: {
            type: 'emojis',
            lower_label: 'Awful',
            upper_label: 'Amazing',
            options: 3,
            emojis: ['😡', '🙂', '🤩'],
          },
        },
        { type: 'button', meta: { label: 'Share Feedback', action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'popover',
        position: 'center',
      },
    },
  ],
  slug: '',
  template_source: 'rating',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: true,
  dismissible: true,
  is_live: false,
  share_page_url: '',
};

export const nps: INudgeType = {
  ...emptyNudge,
  id: -1 * new Date().getTime(),
  organization: '',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'How likely are you to recommend us to a friend or colleague?',
      content: [
        { type: 'markdown', meta: { value: '' } },
        {
          type: 'survey_rating',
          meta: {
            type: 'numbers',
            lower_label: 'Not likely at all',
            upper_label: 'Extremely likely',
            options: 10,
          },
        },
        { type: 'button', meta: { label: 'Submit', action: { type: 'no_action' } } },
      ],
      is_live: false,
      form_factor: {
        type: 'popover',
        position: 'center',
      },
    },
  ],
  slug: '',
  template_source: 'nps',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_conditions_pass' },
  frequency_limit: 'until_interaction',
  show_step_counter: true,
  dismissible: true,
  is_live: false,
  share_page_url: '',
};
