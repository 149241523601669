/*******************************************************************************/
/* Imports
/*******************************************************************************/
import * as t from 'io-ts';

import {
  BatchEditorCommandResponseV,
  BatchOperationV,
  CommandV,
  HelpSyncCommandV,
  EditorCommandV,
  EditorCommandLiteV,
  CommandLiteV,
} from './command';

import {
  ArgumentMapV,
  ArgumentTypeV,
  ConditionV,
  ConditionOperatorV,
  StepArgumentTypeV,
  ContextArgumentV,
  SetArgumentV,
  DynamicArgumentV,
} from './helpers/argument';
import { GenericBatchRequest } from './generics';
import { OptionGroupRenderAsV } from './helpers/optionGroup';

import { TemplateV, TemplateOptionsV, RequestTemplateV } from './helpers/commandTemplate';
import { ActionV, LabeledActionV, RequestV } from './helpers/actions';

import { CommandCategoryV } from './commandCategory';
import {
  OrganizationStatusV,
  KeyEventV,
  ResourceSettingsByContextKeyV,
  InternalSettingsV,
  OrganizationV,
} from './organization';
import { OrganizationSettingsV } from './organizationSettings';

import { UserV } from './user';
import { ContextV } from './context';
import { GuideV } from './guide';
import { HistoryEventV } from './historyEvent';
import { HelpDocSyncLogMessageV, HelpDocsSyncV } from './helpDocsSync';
import { HelpDocsIntegrationV } from './helpDocsIntegration';
import { EndUserV } from './helpers/endUser';
import { SkinV } from './skin';
import { ProfileV } from './profile';
import { PlaceholderV } from './placeholder';
import { EnvReleaseInfoV, ReleaseStepV, ReleaseV } from './releases';
import { EnvironmentV } from './environment';
import {
  NudgeContentBlockV,
  NudgeContentButtonBlockV,
  NudgeContentHelpDocBlockV,
  NudgeContentImageBlockV,
  NudgeContentMarkdownBlockV,
  NudgeContentVideoBlockV,
  NudgeContentSurveyTextBlockV,
  NudgeContentSurveyRatingBlockV,
  NudgeStepV,
  NudgeV,
  OldNudgeV,
} from './nudge';
import { TabV } from './tab';
import { RuleExpressionAndV, RuleExpressionOrV, RuleExpressionV } from './helpers/rules';
import { ChecklistItemV, ChecklistV } from './checklist';
import { DashboardFlagsV } from './dashboardFlags';
import { PushTriggerV } from './helpers/pushTrigger';
import { FrequencyLimitV } from './helpers/frequencyLimit';
import { AudienceV } from './helpers/audience';
import { AdditionalResourceV } from './additionalResource';
import { EditorRecommendationSetV, RecommendationSetV } from './recommendationSet';
import { UsageV } from './billing';
import {
  UserMessageV,
  AIMessageV,
  MessageV,
  AIAnswerPayloadV,
  AIAnswerV,
  ContinuationsPayloadV,
  ContinuationV,
  CreateAnswerFeedbackPayloadV,
  QuestionSuggestionsPayloadV,
  QuestionSuggestionsV,
  AIAnswerValue,
  ExperienceV,
} from './chat';
import { HelpDocHitV, SearchHelpDocsPayloadV, SearchHelpDocsResponseV } from './helpDocsSearch';

/*******************************************************************************/
/* SDK Types
/*******************************************************************************/

export type IInitOptions = {
  debug?: boolean;
  environment?: string;
  version?: string;
  config?: IConfigEndpointResponse;
};

/*******************************************************************************/
/* Model objects
/*******************************************************************************/

export type IContextType = t.TypeOf<typeof ContextV> & unknown;
export type IUserType = t.TypeOf<typeof UserV> & unknown;
export type ICommandType = t.TypeOf<typeof CommandV> & unknown;
export type IEditorCommandType = t.TypeOf<typeof EditorCommandV> & unknown;

export type IEditorCommandTypeLite = t.TypeOf<typeof EditorCommandLiteV> & unknown;
export type ICommandTypeLite = t.TypeOf<typeof CommandLiteV> & unknown;

export type IHelpSyncCommandType = t.TypeOf<typeof HelpSyncCommandV> & unknown;
export type { ICommandFromClientType } from './ICommandFromClientType';
export type ICommandCategoryType = t.TypeOf<typeof CommandCategoryV> & unknown;
export type IGuideType = t.TypeOf<typeof GuideV> & { preview?: boolean } & unknown;
export type IHistoryEventType = t.TypeOf<typeof HistoryEventV> & unknown;
export type IHelpDocsSyncType = t.TypeOf<typeof HelpDocsSyncV> & unknown;
export type IHelpDocsSyncLogMessageType = t.TypeOf<typeof HelpDocSyncLogMessageV> & unknown;
export type IHelpDocsIntegrationType = t.TypeOf<typeof HelpDocsIntegrationV> & unknown;
export type IOrganizationType = t.TypeOf<typeof OrganizationV> & unknown;
export type IOrganizationStatusType = t.TypeOf<typeof OrganizationStatusV> & unknown;
export type IInternalSettingsType = t.TypeOf<typeof InternalSettingsV> & unknown;
export type IDashboardFlagsType = t.TypeOf<typeof DashboardFlagsV> & unknown;
export type ISkinType = t.TypeOf<typeof SkinV> & unknown;
export type IProfileType = t.TypeOf<typeof ProfileV> & unknown;
export type IOrganizationSettingsType = t.TypeOf<typeof OrganizationSettingsV> & unknown;
export type IPlaceholderType = t.TypeOf<typeof PlaceholderV> & unknown;
export type ITabType = t.TypeOf<typeof TabV> & unknown;
export type IEnvironmentType = t.TypeOf<typeof EnvironmentV> & unknown;

export type IReleaseStep = t.TypeOf<typeof ReleaseStepV> & unknown;
export type IRelease = t.TypeOf<typeof ReleaseV> & unknown;
export type IEnvReleaseInfo = t.TypeOf<typeof EnvReleaseInfoV> & unknown;

/*******************************************************************************/
/* Billing
/*******************************************************************************/
export type IUsage = t.TypeOf<typeof UsageV> & unknown;

/*******************************************************************************/
/* API request/response types
/*******************************************************************************/

export type IBatchOperation = t.TypeOf<typeof BatchOperationV> & unknown;
export type IBatchEditorCommandRequst = t.TypeOf<typeof GenericBatchRequest> & unknown;
export type IBatchEditorCommandResponse = t.TypeOf<typeof BatchEditorCommandResponseV> & unknown;

/*******************************************************************************/
/* Helper objects
/*******************************************************************************/
export type IKeyEventCategory = t.TypeOf<typeof KeyEventV> & unknown;
export type IArgumentMap = t.TypeOf<typeof ArgumentMapV> & unknown;
export type IArgumentType = t.TypeOf<typeof ArgumentTypeV> & unknown;
export type IStepArgumentType = t.TypeOf<typeof StepArgumentTypeV> & unknown;
export type ITemplate = t.TypeOf<typeof TemplateV> & unknown;
export type RequestTemplateType = t.TypeOf<typeof RequestTemplateV> & unknown;
export type RequestType = t.TypeOf<typeof RequestV> & unknown;
export type ITemplateOptions = t.TypeOf<typeof TemplateOptionsV> & unknown;
export type IEndUserType = t.TypeOf<typeof EndUserV> & unknown;

export type IRuleExpression = t.TypeOf<typeof RuleExpressionV> & unknown;
export type IRuleExpressionAnd = t.TypeOf<typeof RuleExpressionAndV> & unknown;
export type IRuleExpressionOr = t.TypeOf<typeof RuleExpressionOrV> & unknown;

export type IConditionType = t.TypeOf<typeof ConditionV> & unknown;
export type IConditionOperatorType = t.TypeOf<typeof ConditionOperatorV> & unknown;

export type IContextArgumentType = t.TypeOf<typeof ContextArgumentV> & unknown;
export type ISetArgumentType = t.TypeOf<typeof SetArgumentV> & unknown;
export type IDynamicArgumentType = t.TypeOf<typeof DynamicArgumentV> & unknown;
export type OptionGroupRenderAsType = t.TypeOf<typeof OptionGroupRenderAsV> & unknown;

export type IOldNudgeType = t.TypeOf<typeof OldNudgeV> & unknown;
export type INudgeType = t.TypeOf<typeof NudgeV> & unknown;
export type IAudienceType = t.TypeOf<typeof AudienceV> & unknown;
export type IPushTrigger = t.TypeOf<typeof PushTriggerV> & unknown;
export type IFrequencyLimit = t.TypeOf<typeof FrequencyLimitV> & unknown;

export type INudgeStepType = t.TypeOf<typeof NudgeStepV> & unknown;
export type INudgeStepContentBlockType = t.TypeOf<typeof NudgeContentBlockV> & unknown;
export type INudgeStepContentMarkdownBlockType = t.TypeOf<typeof NudgeContentMarkdownBlockV> & unknown;
export type INudgeStepContentImageBlockType = t.TypeOf<typeof NudgeContentImageBlockV> & unknown;
export type INudgeStepContentVideoBlockType = t.TypeOf<typeof NudgeContentVideoBlockV> & unknown;
export type INudgeStepContentHelpDocBlockType = t.TypeOf<typeof NudgeContentHelpDocBlockV> & unknown;
export type INudgeStepContentSurveyTextBlockType = t.TypeOf<typeof NudgeContentSurveyTextBlockV> & unknown;
export type INudgeContentSurveyRatingBlockType = t.TypeOf<typeof NudgeContentSurveyRatingBlockV> & unknown;
export type INudgeStepContentButtonBlockType = t.TypeOf<typeof NudgeContentButtonBlockV> & unknown;
export type INudgeClientType = {
  nudge: INudgeType;
  passedConditionsInLastEvaluation: boolean;
  lastTriggeredTs?: number;
};

export type LabeledAction = t.TypeOf<typeof LabeledActionV> & unknown;
export type Action = t.TypeOf<typeof ActionV> & unknown;

export type IBillingProfile = {
  organization: string;
  price_cents: number;
  tier: string;
  discount_cents: number;
  name: string;
  invoice_description: string;
  customer_id: string | null;
  publishable_key: string;
  balance: number | null;
  currency: string | null;
  on_free_trial: boolean;
  free_trial_end_date: string | null;
  appsumo_invoice_item_id: string | null;
};

export type IChecklist = t.TypeOf<typeof ChecklistV> & {
  _editorState?: { timestamp?: number; initial_active_item?: number; expanded?: boolean };
} & unknown;
export type IChecklistItem = t.TypeOf<typeof ChecklistItemV> & unknown;

export type ChecklistInteractionState = {
  isSkipped?: boolean;
  isCompleted?: boolean;
  isExpanded?: boolean;
  isSeen?: boolean;
  items?: {
    [key: string]: {
      completedTS?: string;
    };
  };
};
export type ChecklistInteractions = {
  [id: number]: ChecklistInteractionState;
};

export type NudgeInteractionState = {
  currentStep?: number;
  nudgeSeen?: boolean;
  nudgeInteracted?: boolean;
  seenTs?: number[];
  nudgeDismissed?: boolean;
  nudgeCompleted?: boolean;
  nudgeDismissedTs?: number[];
  nudgeCompletedTs?: number[];
};
export type NudgeInteractions = {
  [id: number]: NudgeInteractionState;
};

export type IInstantAnswerType = t.TypeOf<typeof AIAnswerValue> & unknown;
export type IMessageType = t.TypeOf<typeof MessageV> & unknown;
export type IUserMessageType = t.TypeOf<typeof UserMessageV> & unknown;
export type IAIMessageType = t.TypeOf<typeof AIMessageV> & unknown;
export type IQuestionSuggestionsType = t.TypeOf<typeof QuestionSuggestionsV> & unknown;
export type IQuestionSuggestionsPayloadType = t.TypeOf<typeof QuestionSuggestionsPayloadV> & unknown;
export type IContinuationType = t.TypeOf<typeof ContinuationV> & unknown;
export type IExperienceType = t.TypeOf<typeof ExperienceV> & unknown;
export type IContinuationsPayloadType = t.TypeOf<typeof ContinuationsPayloadV> & unknown;
export type IAIAnswerType = t.TypeOf<typeof AIAnswerV> & unknown;
export type IAIAnswerPayloadType = t.TypeOf<typeof AIAnswerPayloadV> & unknown;
export type ICreateAnswerFeedbackPayloadType = t.TypeOf<typeof CreateAnswerFeedbackPayloadV> & unknown;
export type ISearchHelpDocsPayloadType = t.TypeOf<typeof SearchHelpDocsPayloadV> & unknown;
export type ISearchHelpDocsResponseType = t.TypeOf<typeof SearchHelpDocsResponseV> & unknown;
export type IHelpDocHitType = t.TypeOf<typeof HelpDocHitV> & unknown;

export interface ICommandInput {
  text: string;
  command: ICommandType;
  context: IUserContext;
}

export interface IArgValues {
  [arg: string]: string | number | undefined;
}

export type IHistoryType = Array<{
  activeCommand: ICommandType;
  options: ICommandType[];
}>;

export interface IUserContext {
  [variable: string]: any;
}

export interface IUserRemoteProperties {
  [variable: string]: any;
}

export interface IEndUserAnalytics {
  num_command_executions: number;
  num_shortcut_command_executions: number;
  num_sessions: number;
  num_opens: number;
  num_deadends: number;
  first_seen_at: string;
  last_seen_at: string;
}

export interface ICallbackMap {
  [variable: string]: (...args: any[]) => unknown;
}

export interface IResourceType extends IUserContext {
  _cbLinkedCommmands: Array<{ command: number; arg: string }>;
}

export type IAdditionalResource = t.TypeOf<typeof AdditionalResourceV> & unknown;
export type IRecommendationSet = t.TypeOf<typeof RecommendationSetV> & unknown;
export type IEditorRecommendationSet = t.TypeOf<typeof EditorRecommendationSetV> & unknown;

export type IConfigEndpointResponse = {
  commands: any[];
  categories: any[];
  organization: any;
  environments_with_versions?: any[];
  placeholders?: any[];
  nudges?: IOldNudgeType[];
  nudges_v2?: INudgeType[];
  tabs?: ITabType[];
  checklists?: IChecklist[];
  helphub_additional_resources?: IAdditionalResource[];
  helphub_recommendation_sets?: IRecommendationSet[];
};

export type IConfigType = {
  commands: ICommandType[];
  categories: ICommandCategoryType[];
  organization: IOrganizationType;
  environments?: IEnvironmentType[];
  placeholders: IPlaceholderType[];
  nudges: INudgeType[];
  tabs: ITabType[];
  checklists: IChecklist[];
  helphub_additional_resources: IAdditionalResource[];
  helphub_recommendation_sets: IRecommendationSet[];
};

// object search
export type { IResourceSettings } from './IResourceSettings';
export type IResourceSettingsByContextKey = t.TypeOf<typeof ResourceSettingsByContextKeyV> & unknown;

export type { DetailPreviewObjectType, DetailPreviewType, DataRowMetadata } from './detailPreview';

/*******************************************************************************/
/* Type guards
/*******************************************************************************/
export const isCommand = (command?: ICommandType | any): command is ICommandType => {
  if (!command) return false;
  if (!command.hasOwnProperty('text')) return false;
  if (!command.hasOwnProperty('id')) return false;
  if (!command.hasOwnProperty('organization')) return false;
  if (!command.hasOwnProperty('arguments')) return false;
  return true;
};

export const isResource = (option: any): option is IResourceType => {
  if (option && (option as IResourceType)._cbLinkedCommands) return true;
  return false;
};

export const isContextArgument = (argument: IArgumentType): argument is IContextArgumentType => {
  return argument.type === 'context';
};

export const isTimeArgument = (argument: IArgumentType): argument is IDynamicArgumentType => {
  return argument.type === 'provided' && argument.value === 'time';
};
