import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components';
import Z from '@commandbar/internal/client/Z';
import { evaluateRuleExpression } from '@commandbar/internal/client/RulesParser';
import { XClose, InfoCircle, Link01 } from '@commandbar/design-system/icons/react';
import { INudgeType, IChecklist } from '@commandbar/internal/middleware/types';
import { Tooltip, Input } from '../../../shared_components';
import { NoExtensionWarning } from './NoExtensionWarning';
import { DraftModeWarning } from './DraftModeWarning';
import { TargetingWarning } from './TargetingWarning';
import { useIsChromeExtensionInsalled } from '../../../hooks';
import Button2 from '../../../shared_components/Button2';
import { useReportEvent } from '../../../shared_components/useEventReporting';

const Container = styled.div`
  z-index: ${Z.Z_MODALS};

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
const ModalBackgroundMask = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.16);
`;

const Modal = styled.div<{ top: number }>`
  z-index: 1;
  position: relative;
  top: ${(props) => props.top}px;
  display: flex;
  flex-direction: column;

  width: 400px;
  height: fit-content;
  font-family: 'Inter';

  background: ${CB_COLORS.white};
  border: 1px solid rgba(10, 10, 15, 0.24);

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 8px;

  width: 100%;
  height: 52px;

  border-bottom: 1px solid ${CB_COLORS.neutral300};
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;

  color: ${CB_COLORS.neutral800};
`;

const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  gap: 6px;

  color: ${CB_COLORS.neutral800};

  div {
    display: flex;
    align-items: center;
  }
`;

// The shouldForwardProp field prevents React from complaining about an unknown prop by not passing it to Input
const StyledInput = styled(Input, { shouldForwardProp: (prop) => prop !== 'error' })<{ error?: boolean }>`
  &&& {
    border-color: ${(props) => (props.error ? '#C73A27' : CB_COLORS.neutral500)};
    background: ${(props) => (props.error ? 'rgba(199, 58, 39, 0.04)' : CB_COLORS.white)};
    box-shadow: ${(props) => (props.error ? '0px 0px 0px 3px rgba(199, 58, 39, 0.16)' : 'none')};
  }
`;

const ShareLinkBox = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;

  width: 100%;
  height: 40px;
  overflow-x: auto;

  border: 1px solid ${CB_COLORS.neutral100};
  border-radius: 4px;

  background: ${CB_COLORS.neutral100};
  cursor: default;
  white-space: nowrap;
  user-select: none;
`;

const StyledTooltip = styled(Tooltip)`
  background-color: ${CB_COLORS.black} !important;
  color: ${CB_COLORS.white} !important;
`;

/**
 * A valid url has the following format:
 * - an optional http or https protocol
 * - an optional subdomain
 * - a domain
 * - an optional port (for localhost url cases)
 * - an optional path
 * - an optional query string
 * - an optional hash
 */
const isValidUrl = (value: string) => {
  const urlRegex =
    /^(https?:\/\/)?((localhost)|(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?([-a-z\d_]*)?(#.*)?$/i;

  return urlRegex.test(value);
};

export type TShareLinkMeta =
  | { type: 'nudge'; details: INudgeType; save: (nudge: INudgeType) => void }
  | { type: 'questlist'; details: IChecklist; save: (nudge: IChecklist) => void };

interface IShareLinkModalProps {
  sharePageUrl: string;
  shareLink: string;
  onSharePageUrlChange: React.ChangeEventHandler<HTMLInputElement>;
  meta: TShareLinkMeta;
  onClose: () => void;
  onCopyLink: () => void;
  modalTopOffset: number;
}
export const ShareLinkModal = ({
  sharePageUrl,
  shareLink,
  onSharePageUrlChange,
  meta,
  onClose,
  onCopyLink,
  modalTopOffset,
}: IShareLinkModalProps) => {
  const { isChromeExtensionInstalled } = useIsChromeExtensionInsalled();
  const [isTargetingWarningActive, setIsTargetingWarningActive] = React.useState(false);
  const { reportEvent } = useReportEvent();

  React.useEffect(() => {
    if (sharePageUrl === '') {
      setIsTargetingWarningActive(false);
      return;
    }
    const isSharePageUrlValid = !!evaluateRuleExpression(
      meta.details.show_expression,
      {},
      new URL(sharePageUrl) as unknown as Location,
      {
        num_command_executions: 0,
        num_deadends: 0,
        num_opens: 0,
        num_sessions: 0,
        num_shortcut_command_executions: 0,
        first_seen_at: '',
        last_seen_at: '',
      },
      {},
      {},
      true,
      [],
    ).passed;

    setIsTargetingWarningActive(!isSharePageUrlValid);
  }, [sharePageUrl]);

  const saveSharePageUrl = () => {
    if (sharePageUrl === meta.details.share_page_url) return;
    switch (meta.type) {
      case 'questlist':
        meta.save({ ...meta.details, share_page_url: sharePageUrl });
        break;
      case 'nudge': {
        meta.save({ ...meta.details, share_page_url: sharePageUrl });
      }
    }
    reportEvent('share link created', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: `Type: ${meta.type} ID: ${meta.details.id}`,
    });
  };

  return (
    <Container>
      <ModalBackgroundMask onClick={onClose} />
      <Modal top={modalTopOffset}>
        <Header>
          <Title>Share</Title>
          <CloseIconContainer onClick={onClose}>
            <XClose width={16} color={CB_COLORS.neutral500} />
          </CloseIconContainer>
        </Header>
        <Body>
          <Label>
            Start Page URL{' '}
            <StyledTooltip
              content="The page in your app where this preview experience should start"
              placement="top"
              arrow={false}
            >
              <InfoCircle width={14} color={CB_COLORS.neutral500} style={{ cursor: 'pointer' }} />
            </StyledTooltip>
          </Label>
          <StyledInput value={sharePageUrl} onChange={onSharePageUrlChange} error={!isValidUrl(sharePageUrl)} />
          {isTargetingWarningActive && <TargetingWarning />}
          <Label>Share Link Preview</Label>
          <ShareLinkBox>{shareLink}</ShareLinkBox>
          {!isChromeExtensionInstalled && <NoExtensionWarning />}
          <Button2
            disabled={!isValidUrl(sharePageUrl)}
            onClick={async () => {
              if (!isValidUrl(sharePageUrl)) return;
              try {
                await onCopyLink();
                saveSharePageUrl();
              } catch {
                /**
                 * do nothing
                 */
              }
            }}
          >
            <Link01 width={13} color={CB_COLORS.white} /> Copy Link
          </Button2>
          {!meta.details.is_live && <DraftModeWarning />}
        </Body>
      </Modal>
    </Container>
  );
};
