/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { merge } from 'lodash';
import SideBarOverlay from '../components/SideBarOverlay';

import Sender from '../../management/Sender';
import BasicStyleEditor from './skinEditors/BasicStyleEditor';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import {
  Button,
  Divider,
  Input,
  Space,
  message,
  Modal,
  Skeleton,
  Alert,
  FormRow,
  Tooltip,
} from '../../shared_components';
import { useReportEvent } from '../../shared_components/useEventReporting';
import _ from 'lodash';
import { FormFactor } from '@commandbar/internal/client/CommandBarClientSDK';
import { ISkinType } from '@commandbar/internal/middleware/types';
import NoneStyleOverlay from './skinEditors/NoneStyleOverlay';
import ProStyleEditor from './skinEditors/ProStyleEditor';
import { Skin } from '@commandbar/internal/middleware/skin';
import { IPartialTheme } from '@commandbar/internal/client/theme';
import { AdvancedStylesButton, Header, WhiteButton } from './skinEditors/styled-components';
import { ChevronRight, Lock01, ArrowLeft } from '@commandbar/design-system/icons/react';
import { useHistory } from 'react-router';
import { DESIGN_ROUTE, widgetRoutes } from '@commandbar/internal/proxy-editor/editor_routes';

type TSkinUpdate = Pick<ISkinType, 'logo' | 'chat_avatar' | 'skin'>;

interface IProps {
  skin: ISkinType;
  getDefaultSlug: () => string;
  onClose: () => void;
  onUpdate: (skin: ISkinType) => void;
  previewSkin: (skin: string | TSkinUpdate, openBar: boolean) => void;
  allSkins: ISkinType[];
  unsavedChangesRef: React.MutableRefObject<'true' | 'false' | 'backModalShown'>;
}

const SkinDetail = (props: IProps) => {
  const history = useHistory();
  const [activeSkin, setActiveSkin] = React.useState<TSkinUpdate>(() => ({
    logo: props.skin.logo ?? '',
    chat_avatar: props.skin.chat_avatar ?? '',
    skin: _.clone(props.skin.skin),
  }));
  const [tier, setTier] = React.useState<string | undefined>(undefined);
  const [showAdvancedStyles, setShowAdvancedStyles] = React.useState<boolean>(
    widgetRoutes.some((r) => history.location.pathname.includes(r)) || false,
  );

  const [formFactor, setFormFactor] = React.useState<FormFactor | undefined>(undefined);
  const { reportEvent } = useReportEvent();
  const [name, setName] = React.useState<string>(props.skin.name);
  const [slug, setSlug] = React.useState<string>(props.skin.slug);

  const getTier = async () => {
    return OrganizationSettings.read().then((settings) => setTier(settings.skins_field_set));
  };
  const getFormFactor = async () => {
    const timeout = new Promise((resolve) => setTimeout(() => resolve(null), 500 /*ms*/));
    const instanceAttributes = await Promise.race([timeout, await Sender.instanceAttributes()]);
    setFormFactor(instanceAttributes?.formFactor || { type: 'modal' });
  };

  React.useEffect(() => {
    getTier();
    getFormFactor();
  }, []);

  const hasUnsavedChanges = !_.isEqual(activeSkin, {
    skin: props.skin?.skin,
    logo: props.skin.logo ?? '',
    chat_avatar: props.skin.chat_avatar ?? '',
  });

  React.useEffect(() => {
    if (hasUnsavedChanges) {
      props.unsavedChangesRef.current = 'true';
    }
  }, [hasUnsavedChanges]);

  if (formFactor === undefined) return null;

  const isBasicTier = tier === 'basic';

  const updateSkinMeta: () => Promise<string> = async () => {
    let currentSkinSlug = props.skin.slug;

    if (props.skin.name !== name || props.skin.slug !== slug) {
      if (props.allSkins.find((skin) => skin.slug === slug && skin.id !== props.skin.id)) {
        // eslint-disable-next-line no-throw-literal
        throw `A skin with the slug ${slug} already exists.`;
      }
      const skin = await Skin.updateMetadata(props.skin.slug, { name, slug });
      props.onUpdate(skin);
      currentSkinSlug = slug;
    }

    return currentSkinSlug;
  };

  const hasChanged = () => {
    const newSkin = { skin: activeSkin.skin, name, slug, logo: activeSkin.logo, chat_avatar: activeSkin.chat_avatar };
    const oldSkin = {
      skin: props.skin.skin,
      logo: props.skin.logo ?? '',
      chat_avatar: props.skin.chat_avatar ?? '',
      slug: props.skin.slug,
      name: props.skin.name,
    };
    return !_.isEqual(newSkin, oldSkin);
  };

  const handleSave = async () => {
    const skinUpdate = activeSkin;

    try {
      const slug = await updateSkinMeta();
      const skin = await Skin.update({ ...skinUpdate, slug });
      message.success('Updated!');

      reportEvent('skin edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: slug,
      });

      props.onUpdate(skin);
      props.unsavedChangesRef.current = 'false';
    } catch (err) {
      message.error(JSON.stringify(err).replace(/"/g, ''));
      return;
    }
  };

  const updateSkinTheme = (theme: IPartialTheme, applyPreview = true, openBar = false) => {
    const updatedTheme = merge({}, activeSkin.skin, theme);
    try {
      const updatedSkin = { ...activeSkin, skin: updatedTheme };
      setActiveSkin(updatedSkin);
      if (applyPreview) {
        props.previewSkin(updatedSkin, openBar);
      }
      return updatedSkin;
    } catch (err) {
      message.error(JSON.stringify(err));
      return;
    }
  };

  const resetTheme = (theme: IPartialTheme) => {
    try {
      const updatedSkin = { ...activeSkin, skin: theme };
      setActiveSkin(updatedSkin);
      props.previewSkin(updatedSkin, false);
      return updatedSkin;
    } catch (err) {
      message.error(JSON.stringify(err));
      return;
    }
  };

  const updateLogo = async (svgString: string) => {
    setActiveSkin({ ...activeSkin, logo: svgString });
  };

  const updateChatAvatar = async (svgString: string) => {
    setActiveSkin({ ...activeSkin, chat_avatar: svgString });
  };

  if (showAdvancedStyles && tier === 'pro') {
    return (
      <SideBarOverlay
        title="Edit Skin / Advanced styles"
        open={!!props.skin}
        onClose={() => setShowAdvancedStyles(false)}
        leftAction={
          <WhiteButton
            onClick={() => {
              setShowAdvancedStyles(false);
            }}
          >
            <ArrowLeft width={24} height={24} />
          </WhiteButton>
        }
        rightAction={
          <Space>
            <Button type="primary" onClick={handleSave} disabled={!!props.skin.frozen || !hasChanged()}>
              Save
            </Button>
          </Space>
        }
      >
        <div style={{ marginTop: 16 }}>
          <ProStyleEditor
            theme={activeSkin.skin}
            formFactor={formFactor}
            updateTheme={updateSkinTheme}
            isBasicTier={isBasicTier}
          />
        </div>
      </SideBarOverlay>
    );
  }

  return (
    <SideBarOverlay
      open={!!props.skin}
      leftAction={
        <Button
          onClick={() => {
            if (!hasUnsavedChanges) {
              props.onClose();
              return;
            }

            props.unsavedChangesRef.current = 'backModalShown';
            Modal.confirm({
              title: 'Warning: Looks like you have unsaved changes. All changes will be lost.',
              onOk: () => {
                props.unsavedChangesRef.current = 'false';
                props.onClose();
              },
              onCancel: () => {
                props.unsavedChangesRef.current = 'true';
              },
              cancelText: 'Cancel',
            });
          }}
        >
          {hasUnsavedChanges ? 'Discard Changes' : 'Done'}
        </Button>
      }
      onClose={() => {
        if (!hasUnsavedChanges) {
          props.onClose();
          return;
        }

        props.unsavedChangesRef.current = 'backModalShown';
        Modal.confirm({
          title: 'Warning: Looks like you have unsaved changes. All changes will be lost.',
          onOk: () => {
            props.onClose();
            props.unsavedChangesRef.current = 'false';
          },
          onCancel: () => {
            props.unsavedChangesRef.current = 'true';
          },
          cancelText: 'Cancel',
        });
      }}
      title={'Edit skin'}
      rightAction={
        <Space>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={tier === 'none' || !!props.skin.frozen || !hasChanged()}
          >
            Save
          </Button>
        </Space>
      }
    >
      <div style={{ padding: 24 }}>
        {props.skin.frozen && <SkinFrozenAlert />}
        <Skeleton active loading={tier === undefined} paragraph={{ rows: 7 }}>
          <div style={{ position: 'relative' }}>
            <Header>Details</Header>
            <div style={{ padding: '0px 8px' }}>
              <FormRow title="Name:" input={<Input value={name} onChange={(e) => setName(e.target.value)} />} />
              <FormRow title="Slug:" input={<Input value={slug} onChange={(e) => setSlug(e.target.value)} />} />
              <FormRow title="Form factor:" input={<pre>{formFactor.type}</pre>} />
            </div>

            {formFactor.type !== 'inline' && <div></div>}
            <div>
              <Divider />
              <BasicStyleEditor
                theme={activeSkin.skin}
                updateTheme={updateSkinTheme}
                resetTheme={resetTheme}
                formFactor={formFactor}
                isBasicTier={isBasicTier}
                updateLogo={updateLogo}
                updateChatAvatar={updateChatAvatar}
                logo={activeSkin.logo}
                chatAvatar={activeSkin.chat_avatar}
              />
            </div>

            {tier === 'none' && <NoneStyleOverlay />}
            <Tooltip
              showIf={tier !== 'pro'}
              content={
                <span>
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="https://app.commandbar.com/billing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Upgrade your plan
                  </a>{' '}
                  for access.
                </span>
              }
              interactive
            >
              <AdvancedStylesButton
                onClick={() => {
                  if (tier === 'pro') {
                    history.push(DESIGN_ROUTE);
                    setShowAdvancedStyles(true);
                  }
                }}
                disabled={tier !== 'pro'}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {tier !== 'pro' && <Lock01 width={16} height={16} style={{ marginRight: '8px' }} />}
                  <span>Advanced styles</span>
                </div>
                <ChevronRight width={16} height={16} />
              </AdvancedStylesButton>
            </Tooltip>
          </div>
        </Skeleton>
      </div>
    </SideBarOverlay>
  );
};

const SkinFrozenAlert = () => {
  return (
    <Alert
      type="warning"
      style={{ marginBottom: 16, marginTop: -16 }}
      message={
        <span>
          Based on the plan you're on, this skin can't be saved or used in production.
          <br /> To edit this skin,
          <a
            href="https://app.commandbar.com/billing"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1890ff', marginLeft: 2 }}
          >
            upgrade your plan
          </a>
          .
        </span>
      }
    />
  );
};

export default SkinDetail;
