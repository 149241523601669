import React, { ReactElement, useState } from 'react';
import {
  AuditOutlined,
  BlockOutlined,
  CalendarOutlined,
  DeleteOutlined,
  DotChartOutlined,
  LinkOutlined,
  PieChartOutlined,
  ScanOutlined,
  LayoutOutlined,
  TabletOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  booleanOperatorTypes,
  camelCaseToRegular,
  dateOperatorTypes,
  elementOperatorTypes,
  equalityOperatorTypes,
  getConditionTypeSelectOptions,
  getInteractionErrorReason,
  heapOperatorTypes,
  isConditionRuleValid,
  isUrlRuleValid,
  isValidRuleValue,
  listOperatorTypes,
  mkNewRule,
  numericOperatorTypes,
  pastTenseOperators,
} from '../helpers';
import { OperatorType, operatorTypes } from '../types';
import {
  Button,
  Input,
  Alert,
  Col,
  Modal,
  Row,
  Typography,
  Select,
  InputNumber,
  Tooltip,
} from '../../../../../shared_components';
import { RulePrefix } from './styled';
import { IEditorRule, isMultiValueRule, isInteractionCondition } from '@commandbar/internal/middleware/helpers/rules';
import { useAppContext } from '../../../../../Widget';
import { Description, TextContainer } from '../../ActionTypeSelector/components/styled';
import Sender from '../../../../../management/Sender';
import { builtInRules } from '../../../RulesTab/builtInRulesDefintions';
import { ClickRecorder } from '../../ActionDetailPanel';
import { ReactComponent as ElementNotFoundSvg } from '../../../../../img/element_not_found.svg';
import { ConditionRuleContextAutoComplete } from './ConditionRuleRecordsAutoComplete';
import { useParams } from 'react-router';
import styled from '@emotion/styled';

const ElementNotFoundIcon = styled(ElementNotFoundSvg)`
  width: 16px;
  color: #a36a00;
`;

export type ConditionTypeCategory = 'Activity' | 'Custom' | 'Page' | 'Integrations' | 'Interactions' | 'Default';

type IProps<RuleType extends IEditorRule> = {
  rule: RuleType;
  disabled?: boolean;
  booleanOperator?: 'AND' | 'OR';
  omitNamedRuleType?: boolean;
  containerStyle?: React.CSSProperties;
  categories?: ConditionTypeCategory[];

  /* event handlers */
  onChange?: (newRule: RuleType) => void;
  onChangeBooleanOperator?: (newOperator: 'AND' | 'OR') => void;
  onDelete?: () => void;
  onValid?: (isValid: boolean) => void;
};

interface ConditionSelectOption {
  label: string;
  value: string;
  description: string;
  icon: any;
  disabled: boolean;
  disabledDescription?: string;
  multiple?: boolean;
}
interface ConditionSelect {
  category: string;
  options: ConditionSelectOption[];
}

export default function ConditionRuleComponent<RuleType extends IEditorRule>({
  rule,
  disabled = false,
  booleanOperator,
  containerStyle,

  omitNamedRuleType = false,

  /* event handlers */
  onChange = () => {
    return;
  },
  onChangeBooleanOperator,
  onDelete,
  categories,
}: IProps<RuleType>) {
  const params = useParams<{ nudgeId?: string; checklistId?: string }>();
  const { isStandaloneEditor, organization, rules, organizationSettings, nudges, checklists } = useAppContext();

  const [touched, setTouched] = useState(false);

  const { type, field, operator, value } = {
    ...{ field: undefined, operator: undefined, value: undefined },
    ...rule,
  };

  const unavailableOperatorTypes: { [type in IEditorRule['type']]: OperatorType[] } = {
    url: [...numericOperatorTypes, ...booleanOperatorTypes, ...elementOperatorTypes, ...dateOperatorTypes],
    context: [...elementOperatorTypes],
    always: [],
    element: operatorTypes.filter((o) => !elementOperatorTypes.includes(o)),
    named_rule: [],
    executions: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
    shortcuts: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
    last_seen: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
    first_seen: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
    sessions: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
    opens: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
    deadends: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
    heap: operatorTypes.filter((o) => !heapOperatorTypes.includes(o)),
    nudge_interaction: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
    questlist_interaction: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
    browser: operatorTypes.filter((o) => !listOperatorTypes.includes(o)),
    language: operatorTypes.filter((o) => !listOperatorTypes.includes(o)),
    os: operatorTypes.filter((o) => !listOperatorTypes.includes(o)),
    device_type: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  };

  const availableOperatorTypes = operatorTypes.filter(
    (operatorType) => !unavailableOperatorTypes[type].includes(operatorType),
  );

  const isInvalidRuleValue = !isValidRuleValue(rule);
  const isBooleanOperator = !!operator && booleanOperatorTypes.includes(operator);
  const isUndefinedOperator = operator === 'isDefined' || operator === 'isNotDefined';
  const isNumericOperator = !!operator && numericOperatorTypes.includes(operator);

  const isInvalidRule = !isConditionRuleValid(rule);
  const isInvalidUrlRule = !isUrlRuleValid(rule);
  const [isAnalyticsDisabled, setIsAnalyticsDisabled] = React.useState(false);

  React.useEffect(() => {
    Sender.isUserVerified().then((isEndUserVerified) => {
      setIsAnalyticsDisabled(
        !!organizationSettings?.silent_mode ||
          (!isStandaloneEditor && !isEndUserVerified && !!organization?.force_end_user_identity_verification),
      );
    });
  }, [organizationSettings]);

  const onValueChange = (newValue: typeof value) => {
    onChange({
      ...rule,
      value: newValue,
    });
  };

  const onFieldChange = (newField: typeof field) => {
    onChange({
      ...rule,
      field: newField,
    });
  };

  const onOperatorChange = (newOperator: typeof operator) => {
    onChange({
      ...rule,
      operator: newOperator,
    });
  };

  const onTypeChange = (newType: typeof type) => {
    let newRule: RuleType;
    if (newType === 'always') {
      newRule = { ...rule, type: newType };
    } else if (newType === 'named_rule') {
      newRule = { ...rule, type: newType, rule_id: -1, operator: 'is' };
    } else {
      newRule = mkNewRule(newType) as RuleType;
    }
    onChange({
      ...newRule,
    });
  };

  const endUserAnalyticsDisabledReason =
    'Requires a valid HMAC or turning off identity verification, and disabling Silent mode.';

  const rulesWithoutAudiences = rules.filter((r) => !r.is_audience);

  const rulesToSelect = isAnalyticsDisabled ? rulesWithoutAudiences : rulesWithoutAudiences.concat(builtInRules);

  const conditionDropdown: ConditionSelect[] = (() => {
    const activity = {
      category: 'Activity',
      options: [
        {
          label: 'First Seen',
          value: 'first_seen',
          description: 'When a user was first seen in the app in relative days.',
          icon: <CalendarOutlined style={{ marginRight: '5px' }} />,
          disabled: isAnalyticsDisabled,
          disabledDescription: endUserAnalyticsDisabledReason,
        },
        {
          label: 'Last Seen',
          value: 'last_seen',
          description: 'When a user was last seen in the app in relative days.',
          icon: <CalendarOutlined style={{ marginRight: '5px' }} />,
          disabled: isAnalyticsDisabled,
          disabledDescription: endUserAnalyticsDisabledReason,
        },
        {
          label: 'Shortcuts Executed',
          value: 'shortcuts',
          description: 'Number of times a user has used keyboard shortcuts.',
          icon: <DotChartOutlined style={{ marginRight: '5px' }} />,
          disabled: isAnalyticsDisabled,
          disabledDescription: endUserAnalyticsDisabledReason,
        },
        {
          label: 'Sessions',
          value: 'sessions',
          description: 'Number of sessions logged for a user.',
          icon: <DotChartOutlined style={{ marginRight: '5px' }} />,
          disabled: isAnalyticsDisabled,
          disabledDescription: endUserAnalyticsDisabledReason,
        },
      ],
    };

    const commandBarUsage = {
      category: 'CommandBar Usage',
      options: [
        {
          label: 'Opens',
          value: 'opens',
          description: 'Number of CommandBar searches performed by a user.',
          icon: <DotChartOutlined style={{ marginRight: '5px' }} />,
          disabled: isAnalyticsDisabled,
          disabledDescription: endUserAnalyticsDisabledReason,
        },
        {
          label: 'Deadends',
          value: 'deadends',
          description: 'Number of deadends generated by a user.',
          icon: <DotChartOutlined style={{ marginRight: '5px' }} />,
          disabled: isAnalyticsDisabled,
          disabledDescription: endUserAnalyticsDisabledReason,
        },
        {
          label: 'Commands Executed',
          value: 'executions',
          description: 'How often a user has executed a command.',
          icon: <DotChartOutlined style={{ marginRight: '5px' }} />,
          disabled: isAnalyticsDisabled,
          disabledDescription: endUserAnalyticsDisabledReason,
        },
      ],
    };

    const integrations = {
      category: 'Integrations',
      options: [
        ...(!!organization?.integrations?.heap
          ? [
              {
                label: 'Heap Segment',
                value: 'heap',
                description: 'Heap Segment a user may or may not be part of',
                icon: <PieChartOutlined style={{ marginRight: '5px' }} />,
                disabled: false,
              },
            ]
          : []),
      ],
    };

    const defaultCategory = {
      category: 'Default',
      options: [
        {
          label: 'Browser',
          value: 'browser',
          description: 'The browser a user is currently using',
          icon: <LayoutOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Language',
          value: 'language',
          description: 'Language the user has set in their browser',
          icon: <UserOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Operating System',
          value: 'os',
          description: "Operating System of the user's computer",
          icon: <SettingOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Device Type',
          value: 'device_type',
          description: 'Whether a user is on a mobile or desktop device',
          icon: <TabletOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
      ],
    };

    const custom = {
      category: 'Custom',
      options: [
        ...(omitNamedRuleType
          ? []
          : [
              {
                label: 'Rule',
                value: 'named_rule',
                description: '',
                icon: <AuditOutlined style={{ marginRight: '5px' }} />,
                disabled: false,
              },
            ]),
        {
          label: 'Metadata',
          value: 'context',
          description: '',
          icon: <ScanOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
      ],
    };

    const page = {
      category: 'Page',
      options: [
        {
          label: 'Current URL Path',
          value: 'url',
          description: '',
          icon: <LinkOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'DOM Element',
          value: 'element',
          description: '',
          icon: <BlockOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
      ],
    };

    const interactions = {
      category: 'Interactions',
      options: [
        {
          label: 'Nudges',
          value: 'nudge_interaction',
          description: '',
          icon: <BlockOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Questlists',
          value: 'questlist_interaction',
          description: '',
          icon: <BlockOutlined style={{ marginRight: '5px' }} />,
          disabled: false,
        },
      ],
    };

    return [
      defaultCategory,
      page,
      { ...activity, options: [...activity.options, ...commandBarUsage.options] },
      custom,
      integrations,
      interactions,
    ].filter((c) => c.options.length && (!categories || categories?.includes(c.category as ConditionTypeCategory)));
  })();

  const renderTypeSection = () => {
    return (
      <>
        {booleanOperator ? (
          <Col style={{ overflow: 'hidden' }} flex="0 0 auto">
            {onChangeBooleanOperator ? (
              <Select disabled={disabled} value={booleanOperator} onChange={onChangeBooleanOperator} size="small">
                <Select.Option value={'AND'}>AND</Select.Option>
                <Select.Option value={'OR'}>OR</Select.Option>
              </Select>
            ) : (
              <RulePrefix>{booleanOperator}</RulePrefix>
            )}
          </Col>
        ) : null}
        <Col flex={['url', 'element', 'context'].includes(type) ? '1 0 auto' : '1 3 auto'}>
          <Select
            showSearch
            filterOption={(input, option) => {
              if (!!option?.children) {
                const opt = option.props.children as unknown as ReactElement;
                const div = opt.props.children as unknown as ReactElement[];
                const label = div[0].props.children[1] as string;
                const description = div[1].props.children as string;
                return (
                  label.toLowerCase().includes(input.toLowerCase()) ||
                  description.toLowerCase().includes(input.toLowerCase())
                );
              }
              return false;
            }}
            disabled={disabled}
            value={type}
            onChange={onTypeChange}
            dropdownClassName="commandbar-select-dropdown"
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            size="small"
          >
            {conditionDropdown.map(({ category, options }, idxI: number) => (
              <Select.OptGroup label={category} key={category}>
                {options.map((option: ConditionSelectOption, idxJ: number) => {
                  return (
                    <Select.Option value={option.value} key={`condition-${idxI}-${idxJ}`} disabled={option.disabled}>
                      <TextContainer>
                        <div style={{ display: 'inline-block' }}>
                          {option.icon}
                          {option.label}
                        </div>
                        {option.disabled ? (
                          <Description>{option.disabledDescription}</Description>
                        ) : (
                          <Description>{option.description}</Description>
                        )}
                      </TextContainer>
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            ))}
          </Select>
        </Col>
      </>
    );
  };

  const renderFieldSection = () => {
    if (type === 'context') {
      return (
        <Col flex="3 1 auto" style={{ overflow: 'hidden' }}>
          <ConditionRuleContextAutoComplete
            value={field}
            onChange={onFieldChange}
            onBlur={() => setTouched(true)}
            disabled={disabled}
          />
        </Col>
      );
    }
    if (type === 'heap') {
      const heapSegment =
        !!field && !organization?.integrations?.heap?.segments?.hasOwnProperty(field) ? 'Not Available' : field;

      return (
        <Col flex="1 1 auto" style={{ overflow: 'hidden' }}>
          <Select
            value={heapSegment}
            onChange={onFieldChange}
            dropdownClassName="commandbar-select-dropdown"
            dropdownMatchSelectWidth={false}
            style={{ maxWidth: '100%', width: '100%' }}
            size="small"
            placeholder="Select a Heap Segment"
          >
            {!organization?.integrations?.heap?.segments
              ? null
              : Object.entries(organization?.integrations?.heap?.segments).map(([key, value]) => {
                  return (
                    <Select.Option value={key} key={key}>
                      {value}
                    </Select.Option>
                  );
                })}
          </Select>
        </Col>
      );
    }

    return null;
  };

  const renderInteractionSection = () => {
    if (isInteractionCondition(rule)) {
      const getInteractionDisplayOptions = () => {
        if (rule.type === 'nudge_interaction') {
          const nudge = nudges.find((nudge) => nudge.id === rule.nudge_id);

          return {
            // If the nudge no longer exists then we want to show a blank value and not the ID of the deleted nudge
            value: nudge?.id ? Number(nudge.id) : undefined,
            options: nudges
              // Don't show the nudge we're currently editing
              .filter((nudge) => Number(params.nudgeId) !== nudge.id)
              .map((nudge) => ({
                label: !!nudge.slug ? nudge.slug : `ID: ${nudge.id}`,
                value: Number(nudge.id),
              })),
            onInteractionChange: (nudge_id: number) => {
              onChange({ ...rule, nudge_id });
            },
          };
        }

        const checklist = checklists.find((checklist) => checklist.id === Number(rule.questlist_id));
        return {
          // If the questlist no longer exists then we want to show a blank value and not the ID of the deleted questlist
          value: checklist?.id,
          options: checklists
            // Don't show the questlist we're currently editing
            .filter((checklist) => checklist.id !== Number(params.checklistId))
            .map((checklist) => ({
              label: checklist.title,
              value: checklist.id,
            })),
          onInteractionChange: (questlist_id: number) => {
            onChange({ ...rule, questlist_id });
          },
        };
      };

      const { options, value, onInteractionChange } = getInteractionDisplayOptions();
      return (
        <Col flex="1 0 auto" style={{ overflow: 'hidden' }}>
          <Select
            disabled={disabled}
            value={value}
            options={options}
            onChange={onInteractionChange}
            dropdownClassName="commandbar-select-dropdown"
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            size="small"
          />
        </Col>
      );
    }

    return null;
  };

  const renderOperatorTypeSection = () => {
    if (type === 'named_rule') return null;
    return (
      <Col flex="1 0 auto" style={{ overflow: 'hidden' }}>
        <Select
          disabled={disabled}
          value={operator}
          onChange={onOperatorChange}
          dropdownClassName="commandbar-select-dropdown"
          style={{ maxWidth: '100%', width: '100%' }}
          dropdownMatchSelectWidth={false}
          size="small"
        >
          {availableOperatorTypes.map((type) => {
            const isPastTense = isInteractionCondition(rule);
            const operator = isPastTense ? pastTenseOperators[type] ?? type : type;
            const displayValue = camelCaseToRegular(operator);

            return (
              <Select.Option value={type} key={type}>
                {displayValue}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
    );
  };

  const renderValueSection = () => {
    if (rule.type === 'named_rule') {
      const onRuleIdChange = (newRuleId: number | string) => {
        onChange({
          ...rule,
          rule_id: newRuleId,
          value: undefined,
          type: typeof newRuleId === 'number' ? 'named_rule' : 'named_rule',
        });
      };

      return (
        <Col flex="1 3 auto">
          <Select
            disabled={disabled}
            value={rule.rule_id === -1 ? null : rule.rule_id}
            options={rulesToSelect.map((rule) => ({ value: rule.id, label: rule.name }))}
            onChange={onRuleIdChange}
            dropdownClassName="commandbar-select-dropdown"
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            size="small"
          />
        </Col>
      );
    }

    if (isMultiValueRule(rule)) {
      const onMultiValueRuleChange = (newValues: string[]) => {
        onChange({
          ...rule,
          values: newValues,
        });
      };

      return (
        <Col flex="1 3 auto">
          <Select
            disabled={disabled}
            value={rule.values}
            options={getConditionTypeSelectOptions(rule.type)}
            onChange={onMultiValueRuleChange}
            dropdownClassName="commandbar-select-dropdown"
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            size="small"
            filterOption={(input, option) => {
              return (
                !!option?.value?.toLowerCase().includes(input.toLowerCase()) ||
                !!option?.label?.toLowerCase().includes(input.toLowerCase())
              );
            }}
            mode="multiple"
          />
        </Col>
      );
    }

    if (rule.type === 'device_type') {
      return (
        <Col flex="1 3 auto">
          <Select
            disabled={disabled}
            value={rule.value}
            options={getConditionTypeSelectOptions(rule.type)}
            onChange={(value) => onValueChange(value)}
            dropdownClassName="commandbar-select-dropdown"
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
          />
        </Col>
      );
    }

    if (isInteractionCondition(rule)) {
      return (
        <Col flex="1 3 auto">
          <Select
            disabled={disabled}
            value={rule.value}
            options={getConditionTypeSelectOptions(rule.type)}
            style={{ width: '100%' }}
            onBlur={() => setTouched(true)}
            onChange={(e) => onValueChange(String(e))}
            size="small"
          />
        </Col>
      );
    }

    if (isNumericOperator) {
      return (
        <>
          <Col flex="1 3 auto">
            <InputNumber
              disabled={disabled}
              value={value as string | number | null | undefined}
              placeholder="number"
              style={{ width: '100%' }}
              onBlur={() => setTouched(true)}
              onChange={(e) => onValueChange(String(e))}
              min={0}
              size="small"
            />
          </Col>
          {(rule.type === 'last_seen' || rule.type === 'first_seen') && <Col>days&nbsp;ago</Col>}
        </>
      );
    }

    if (isBooleanOperator || isUndefinedOperator) {
      return null;
    }

    return (
      <Col flex="1 3 auto">
        <Input
          disabled={disabled}
          value={value ?? ''}
          placeholder="value"
          style={{ width: '100%' }}
          onBlur={() => setTouched(true)}
          onChange={(e) => onValueChange(e.target.value.trim())}
          size="small"
          suffix={
            !disabled && (operator === 'selectorOnPage' || operator === 'selectorNotOnPage') ? (
              <ClickRecorder onValueChange={(e) => onValueChange(e[0].trim())} value={value ?? ''} singleStep />
            ) : null
          }
        />
      </Col>
    );
  };

  const renderErrorMessage = () => {
    if (isInvalidRuleValue && value && field) {
      return (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={
            <span>
              Unable to apply operator <Typography.Text code>{camelCaseToRegular(operator ?? '')}</Typography.Text> to{' '}
              <Typography.Text code>{value}</Typography.Text>
            </span>
          }
          type="error"
        />
      );
    }

    if ((isInvalidRule || isInvalidUrlRule) && touched) {
      return (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={
            <span>{`Invalid rule. ${
              isInvalidUrlRule ? 'Please use a relative url (e.g., /home)' : 'Please fill all required fields.'
            }`}</span>
          }
          type="error"
        />
      );
    }
  };

  const renderInteractionError = () => {
    const interactionError = getInteractionErrorReason(rule, nudges, checklists);

    if (!interactionError) return null;

    return (
      <Tooltip showIf={!!interactionError} placement="bottom" content={interactionError}>
        <Row align="middle">
          <ElementNotFoundIcon />
        </Row>
      </Tooltip>
    );
  };

  return (
    <div style={{ marginTop: 8, marginBottom: 8, ...containerStyle }}>
      <Row gutter={4} align="middle" style={{ opacity: disabled ? 0.6 : 1 }} wrap={false}>
        <Col flex="auto">
          <Row gutter={4} align="middle" wrap={false}>
            {renderTypeSection()}
            {renderInteractionSection()}
            {renderFieldSection()}
            {renderOperatorTypeSection()}
            {renderValueSection()}
          </Row>
        </Col>
        {!disabled && onDelete && (
          <Col>
            <Button
              type="text"
              ghost
              onClick={() => {
                Modal.confirm({
                  title: 'Delete this condition?',
                  onOk: () => {
                    onDelete();
                  },
                });
              }}
              size="small"
            >
              <DeleteOutlined />
            </Button>
          </Col>
        )}
        {renderInteractionError()}
      </Row>
      {renderErrorMessage()}
    </div>
  );
}
