import { debounce, partition } from 'lodash';
import React from 'react';

import { RecommendationSet } from '@commandbar/internal/middleware/recommendationSet';
import { IEditorRecommendationSet } from '@commandbar/internal/middleware/types';

import Sender from '../../management/Sender';
import { useReportEvent } from '../../shared_components/useEventReporting';
import { useAppContext } from '../../Widget';

const useRecommendationSets = () => {
  const { organization, commands } = useAppContext();
  const { reportEvent } = useReportEvent();

  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const DEFAULT_RECOMMENDATION_SET: IEditorRecommendationSet = React.useMemo(
    () => ({
      id: -1,
      widget: 'helphub',
      name: '',
      default: true,
      show_expression: { type: 'LITERAL', value: true },
      audience: { type: 'all_users' },
      command_ids: commands
        .filter((c) => c.template.type === 'helpdoc')
        .slice(0, 5)
        .map((c) => c.id),
    }),
    [commands],
  );

  const defaultRecommendationSet = React.useRef<IEditorRecommendationSet | null>(null);
  const recommendationSets = React.useRef<IEditorRecommendationSet[] | null>(null);

  // populate supportCTA and additionalResources from backend API
  React.useEffect(() => {
    (async () => {
      // partition on show_as_primary_cta using lodash partition
      const [defaultRecommendationSets_, recommendationSets_] = partition(
        await RecommendationSet.list(),
        (r) => r.default,
      );

      if (defaultRecommendationSets_[0]) {
        defaultRecommendationSet.current = defaultRecommendationSets_[0];
      } else {
        // if no Support CTA in DB, use default values
        defaultRecommendationSet.current = DEFAULT_RECOMMENDATION_SET;
      }
      recommendationSets.current = recommendationSets_;

      forceUpdate();
    })();
  }, [organization]);

  const createOrUpdateDefaultRecommendationSetDebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (!defaultRecommendationSet.current) return;

        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;
        if (defaultRecommendationSet.current.id < 0) {
          // create
          defaultRecommendationSet.current = await RecommendationSet.create(defaultRecommendationSet.current);
          const payloadMessage = `Default (ID: ${defaultRecommendationSet.current.id})`;
          reportEvent('recommendation set created', {
            segment: true,
            highlight: true,
            slack: true,
            payloadMessage: payloadMessage,
          });
          forceUpdate();
        } else {
          await RecommendationSet.update(defaultRecommendationSet.current);
          const payloadMessage = `Default (ID: ${defaultRecommendationSet.current.id})`;
          reportEvent('recommendation set edited', {
            segment: true,
            highlight: true,
            slack: true,
            payloadMessage: payloadMessage,
          });
          forceUpdate();
        }

        Sender.reload(['reloadOrganization']);
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };
    return debounce(doSave, 500);
  }, [reportEvent]);

  const updateDefaultRecommendationSet = React.useCallback(
    (s: IEditorRecommendationSet) => {
      defaultRecommendationSet.current = s;
      forceUpdate();
      createOrUpdateDefaultRecommendationSetDebounced();
    },
    [createOrUpdateDefaultRecommendationSetDebounced],
  );

  const updateDebounced = React.useMemo(() => {
    const state: Record<number, (s: IEditorRecommendationSet) => void> = {};
    return (s: IEditorRecommendationSet) => {
      const func =
        state[s.id] ??
        debounce(
          (s: IEditorRecommendationSet) =>
            (async () => {
              await RecommendationSet.update(s);

              Sender.reload(['reloadOrganization']);

              const payloadMessage = `${s.default ? 'Default' : s.name} (ID: ${s.id})`;

              reportEvent('recommendation set edited', {
                segment: true,
                highlight: true,
                slack: true,
                payloadMessage: payloadMessage,
              });
            })(),
          500,
        );

      state[s.id] = func;

      return func(s);
    };
  }, [reportEvent]);

  const update = React.useCallback(
    (updatedRecommendationSet: IEditorRecommendationSet) => {
      if (!recommendationSets.current) return;

      recommendationSets.current = recommendationSets.current.map((s) =>
        s.id === updatedRecommendationSet.id ? updatedRecommendationSet : s,
      );
      forceUpdate();
      updateDebounced(updatedRecommendationSet);
    },
    [updateDebounced],
  );

  const del = React.useCallback(
    async (id: number) => {
      if (!recommendationSets.current) return;

      const deleted = recommendationSets.current.find((s) => s.id === id);
      if (!deleted) return;
      if (deleted.default) return;

      recommendationSets.current = recommendationSets.current.filter((s) => s.id !== id);
      forceUpdate();

      await RecommendationSet.delete(id);
      Sender.reload(['reloadOrganization']);

      const payloadMessage = `${deleted.name} (ID: ${id})`;

      reportEvent('recommendation set deleted', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
      });
    },
    [reportEvent],
  );

  const addNew = React.useCallback(async (): Promise<IEditorRecommendationSet> => {
    if (!recommendationSets.current) throw new Error('recommendationSets.current does not exist');

    const newItem = await RecommendationSet.create({
      ...DEFAULT_RECOMMENDATION_SET,
      default: false,
      command_ids: [],
      name: 'Recommendation Set',
    });
    Sender.reload(['reloadOrganization']);

    recommendationSets.current = [...recommendationSets.current, newItem];
    forceUpdate();

    reportEvent('recommendation set created', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: `${newItem.name} (ID: ${newItem.id})`,
    });

    return newItem;
  }, [reportEvent]);

  return {
    defaultRecommendationSet: defaultRecommendationSet.current,
    recommendationSets: recommendationSets.current,
    actions: {
      recommendationSets: {
        update,
        del,
        addNew,
      },
      defaultRecommendationSet: {
        update: updateDefaultRecommendationSet,
      },
    },
  };
};

export default useRecommendationSets;
