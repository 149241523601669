import { Input, Switch } from '@commandbar/design-system/components';
import { Cube02, InfoCircle } from '@commandbar/design-system/icons/react';
import { IOrganizationSettingsType, IOrganizationType } from '@commandbar/internal/middleware/types';
import styled from '@emotion/styled';
import React from 'react';
import { Select, Form } from '../../../shared_components';

export const AISettings = ({
  organization,
  updateSetting,
}: {
  organization: IOrganizationType;
  updateSetting: (value: Partial<IOrganizationSettingsType>) => void;
}) => {
  const disableSection = !organization?.helphub_ai_enabled;
  return (
    <SettingBox style={{ width: '100%' }}>
      <TitleSection>
        <Title>
          <Cube02 color="#3F3995" height="16" />
          Enable AI Chat
        </Title>
        <Switch
          checked={!!organization?.helphub_ai_enabled}
          onChange={(value) => {
            updateSetting({
              helphub_ai_enabled: value,
              helphub_suggested_queries_enabled: value,
              helphub_continuations_enabled: value,
            });
          }}
        />
      </TitleSection>

      <Content disabled={disableSection}>
        <Form layout="horizontal">
          <InlineRow
            colon={false}
            label="Chat Only Mode"
            name="helphub_chat_only_mode"
            tooltip={{
              icon: <InfoCircle height={16} />,
              title:
                'AI chat will be the only way to interact with HelpHub. Users will not have the option to search for help docs.',
            }}
          >
            <Switch
              disabled={disableSection}
              checked={!!organization?.helphub_chat_only_mode}
              onChange={(value) => {
                updateSetting({ helphub_chat_only_mode: value });
              }}
            />
          </InlineRow>
          <InlineRow
            colon={false}
            name="helphub_suggested_queries_enabled"
            label="Suggested Searches"
            tooltip={{
              icon: <InfoCircle height={16} />,
              title:
                'Shows suggested searches or chat prompts. AI will provide smart suggestions if no suggestions are specified in HelpHub > Settings > Chat.',
            }}
          >
            <Switch
              disabled={disableSection}
              checked={!!organization?.helphub_suggested_queries_enabled}
              onChange={(value) => {
                updateSetting({ helphub_suggested_queries_enabled: value });
              }}
            />
          </InlineRow>
          <InlineRow
            colon={false}
            label="Suggest Follow-up Questions"
            name="helphub_continuations_enabled"
            tooltip={{
              icon: <InfoCircle height={16} />,
              title: 'AI will suggest relevant follow-on questions after chat responses',
            }}
          >
            <Switch
              disabled={disableSection}
              checked={!!organization?.helphub_continuations_enabled}
              onChange={(value) => {
                updateSetting({ helphub_continuations_enabled: value });
              }}
            />
          </InlineRow>
          <InlineRow
            colon={false}
            label="Suggest Commands, Nudges, and Questlists in Chat"
            name="copilot_experiences_enabled"
            tooltip={{
              icon: <InfoCircle height={16} />,
              title: 'Coming soon',
            }}
          >
            <Switch
              disabled={true}
              checked={!!organization?.copilot_experiences_enabled}
              onChange={(value) => {
                updateSetting({ copilot_experiences_enabled: value });
              }}
            />
          </InlineRow>
        </Form>

        <Form layout="vertical">
          <Form.Item
            label="OpenAI API key"
            tooltip={{
              icon: <InfoCircle height={16} />,
              title: "Contact us if you'd like to provide your Open AI key",
            }}
          >
            <Input.Password value="myplaceholderpasswordforopenaikey" iconRender={() => <></>} disabled />
          </Form.Item>
          <Form.Item
            label="OpenAI Model"
            tooltip={{
              icon: <InfoCircle height={16} />,
              title: "Contact us if you'd like to use a different model",
            }}
          >
            <Select
              defaultValue="1"
              disabled
              options={[
                {
                  value: '1',
                  label: 'GPT3.5-Turbo',
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Content>
    </SettingBox>
  );
};

const SettingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #3f3995;
  border-radius: 8px;
`;

const InlineRow = styled(Form.Item)`
  margin: 0 24px 8px 0;
  .ant-row {
    display: flex;
    justify-content: space-between;
    flex-flow: initial;
  }

  .ant-form-horizontal .ant-form-item-control {
    flex: 0;
  }
  .ant-form-item-control {
    flex-grow: 0;
  }
`;

const TitleSection = styled.div`
  padding: 8px 16px;
  background: #e8e6ff;
  width: 100%;
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const Content = styled.div<{ disabled: boolean }>`
  padding: 16px 16px 0;
  width: 100%;

  ${({ disabled }) => disabled && 'opacity: 0.25;'}
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
`;

const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #3f3995;
  display: flex;
  gap: 8px;
  align-items: center;
`;
