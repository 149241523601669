/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import { WarningOutlined } from '@ant-design/icons';

import * as Organization from '@commandbar/internal/middleware/organization';
import { IContextType, IUserContext } from '@commandbar/internal/middleware/types';

import Sender from '../management/Sender';
import { EditorRouter } from './Router';
import SessionSettingsProvider from '../store/SessionSettingsProvider';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { useAppContext } from '../Widget';
import { Modal, Skeleton } from '../shared_components';
import { LOCAL_SETTINGS } from '../shared_components/LocalSettings';
import { COMMANDS_ROUTE, RECORDS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';

const Editor = () => {
  const { organization, commands, categories, activeCommand, loading, dispatch } = useAppContext();

  const hasData = organization && commands.length > 0 && categories.length > 0;
  const isLoading = loading || !organization;

  const setCommandBarContext = (context: IUserContext | string | any[], meta?: any) => {
    Sender.setContext(context, meta);
  };

  const checkClientOrgMismatch = () => {
    Sender.getOrganizationID().then((data: any) => {
      if (organization && organization.id !== data?.data) {
        Modal.confirm({
          icon: <WarningOutlined />,
          title: `
            Your CommandBar account doesn't match the CommandBar loaded on this page. Open your app and try 
            logging into the Editor from there to edit your CommandBar.
          `,
        });
      }
    });
  };

  React.useEffect(() => {
    if (!organization) {
      return;
    }

    const setContext = (loadedContexts: IContextType[]) => {
      const cachedContext = LocalStorage.get('customcontext', '') as string;

      if (!cachedContext) {
        return;
      }

      const contextToSet = loadedContexts.find((item: IContextType) => {
        return item.id === JSON.parse(cachedContext).id;
      });

      if (!contextToSet || !contextToSet.value) {
        return;
      }

      setCommandBarContext(contextToSet.value, {
        useCustom: true,
        customID: cachedContext,
      });
    };

    const loadContexts = async (id: string) => {
      const c = await Organization.listContexts(id);
      setContext(c);
    };

    loadContexts(organization.id.toString());

    checkClientOrgMismatch();
  }, [organization]);

  // get active command from URL or LocalStorage if specified and set it as the active command
  // only want to do this once
  const alreadySetActiveCommand = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (loading) return;
    if (activeCommand) return;
    if (alreadySetActiveCommand.current) return;

    (async () => {
      alreadySetActiveCommand.current = true;
      const result = await Sender.getHostUrl();

      // Parse host url to determine if any command should be opened on load
      // Syntax: url?editor_path=commands/<id>
      const editorPathParam = new URL(result.url).searchParams.get('editor_path');

      let activeCommandId: number;

      if (editorPathParam && (editorPathParam.includes(COMMANDS_ROUTE) || editorPathParam.includes(RECORDS_ROUTE))) {
        activeCommandId = parseInt(editorPathParam.slice(editorPathParam.indexOf('/') + 1));
      } else {
        activeCommandId = LOCAL_SETTINGS.activeCommand.getter(true);
      }

      const activeCommand = commands.find(({ id }) => id === activeCommandId);
      if (activeCommand) dispatch.commands.setActiveCommandById(activeCommand.id);
    })();
  }, [commands, loading]);

  if (isLoading && !hasData) {
    return (
      <div style={{ padding: 24 }}>
        <Skeleton active={true} paragraph={{ rows: 7 }} />
      </div>
    );
  }

  return (
    <SessionSettingsProvider categories={categories}>
      <div id="commandbar-editor-main" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <EditorRouter />
      </div>
    </SessionSettingsProvider>
  );
};

export default Editor;
